import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

class ConnectAs extends React.Component {
  constructor(props) {
    super(props);
    localStorage.setItem('sessionId', this.props.match.params.sessionId);
    this.props.history.push('/search');
  }

  render() {
    return null;
  }
}

ConnectAs.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default withRouter(ConnectAs);
