/* eslint-disable no-underscore-dangle */
import React, { Component } from 'react';
import {
  Modal, Input, Button, Icon, message, Form,
} from 'antd';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { createCampaign } from '../services/campaigns';
import {
  parseSearch,
  stringifySearch,
} from '../services/utils';
import '../styles/createCampaign.less';


class CreateCampaign extends Component {
  state={ loading: false }

  closeModal = () => {
    const currentSearch = parseSearch(this.props.location.search);
    const newSearch = { ...currentSearch, create: false };
    this.props.history.push({
      pathname: this.props.location.pathname,
      search: stringifySearch(newSearch),
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.create(values.name);
      }
    });
  };

  create = (name) => {
    this.setState({ loading: true });
    createCampaign(name)
      .then((camp) => {
        message.success('Campaign successfuly created');
        this.props.form.resetFields();
        this.closeModal();
        this.props.refreshCampaigns().then(() => this.props.history.push(`/campaigns/${camp.data._doc._id}?stage=ALL`));
      }).catch((e) => {
        console.error(e);
        message.error('Couldn\'t create the campaign, please try again.');
      })
      .finally(() => { this.setState({ loading: false }); });
  }

  hasErrors = fieldsError => Object.keys(fieldsError).some(field => fieldsError[field]);

  render() {
    const { isVisible } = this.props;
    const { getFieldDecorator, getFieldsError } = this.props.form;
    const { loading } = this.state;
    return (
      <Modal
        closable
        centered
        title="Create a campaign"
        visible={isVisible}
        onCancel={this.closeModal}
        className="Create_campaign_modal"
        footer={null}
      >
        <Form onSubmit={this.handleSubmit}>
          <Form.Item>
            {getFieldDecorator('name', {
              rules: [{ required: true, message: 'Please input the name of the campaign' }],
            })(
              <Input
                placeholder="Name the campaign"
                size="large"
              />,
            )}
          </Form.Item>

          <Form.Item>
            <Button
              disabled={this.hasErrors(getFieldsError())}
              key="submit"
              htmlType="submit"
              type="primary"
              loading={loading}
              className="Create_list_action"
              size="large"
            >
              <Icon type="plus" />
              {' '}
Create
            </Button>
          </Form.Item>
        </Form>


      </Modal>
    );
  }
}

CreateCampaign.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  isVisible: PropTypes.bool,
  form: PropTypes.object,
  refreshCampaigns: PropTypes.func,
};

CreateCampaign.defaultProps = {
  history: {},
  location: {},
  isVisible: false,
  form: {},
  refreshCampaigns: () => {},
};

export default Form.create({ name: 'campaign_create' })(withRouter(CreateCampaign));
