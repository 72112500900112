import React, { Component } from 'react';
import {
  Modal, Input, Button, Icon, message, Form,
} from 'antd';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { updateInfluencerNote } from '../services/user';
import '../styles/createCampaign.less';


class EditNote extends Component {
  state={ loading: false }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.update(values.note);
      }
    });
  };

  update = (note) => {
    this.setState({ loading: true });
    updateInfluencerNote(this.props.account._id, note)
      .then(() => {
        message.success('Note successfuly updated');
        this.props.callbackSuccess(note, this.props.account._id);
        this.props.onClose();
      }).catch((e) => {
        console.error(e);
        message.error('Couldn\'t update the note, please try again.');
      })
      .finally(() => { this.setState({ loading: false }); });
  }

  hasErrors = fieldsError => Object.keys(fieldsError).some(field => fieldsError[field]);

  render() {
    const { isVisible, account } = this.props;
    const { getFieldDecorator, getFieldsError } = this.props.form;
    const { loading } = this.state;
    return (
      <Modal
        closable
        centered
        title={!account ? 'Influencer note' : `Note for @${account.username}`}
        visible={isVisible}
        onCancel={this.props.onClose}
        className="Create_campaign_modal"
        footer={null}
      >
        {account && (
        <Form onSubmit={this.handleSubmit}>
          <Form.Item>
            {getFieldDecorator('note', {
              initialValue: account.notes,
            })(
              <Input.TextArea
                rows={4}
                placeholder="Add a note on the influencer"
                size="large"
              />,
            )}
          </Form.Item>

          <Form.Item>
            <Button
              disabled={this.hasErrors(getFieldsError())}
              key="submit"
              htmlType="submit"
              type="primary"
              loading={loading}
              className="Create_list_action"
              size="large"
            >
              <Icon type="save" />
              {' '}
Save note
            </Button>
          </Form.Item>
        </Form>
        )}


      </Modal>
    );
  }
}

EditNote.propTypes = {
  isVisible: PropTypes.bool,
  form: PropTypes.object,
  account: PropTypes.object,
  onClose: PropTypes.func,
  callbackSuccess: PropTypes.func,
};

EditNote.defaultProps = {
  isVisible: false,
  form: {},
  account: {},
  onClose: () => {},
  callbackSuccess: () => {},
};

export default Form.create({ name: 'edit_note' })(withRouter(EditNote));
