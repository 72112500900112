/* eslint-disable react/no-unescaped-entities */
import React, { Component } from 'react';
import {
  Form, Input, Icon, message, Button, Checkbox,
} from 'antd';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import {
  parseSearch, isValidEmail,
} from '../services/utils';
import { isConnected, register } from '../services/user';
import '../styles/login.less';

class Register extends Component {
  state = { loading: false };

  componentDidMount() {
    if (isConnected()) {
      this.props.history.push('/search');
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({ loading: true });
        const { ref } = parseSearch(this.props.location.search);
        register(
          {
            email: values.email.toLowerCase(),
            password: values.password,
            firstName: values.firstname || undefined,
            lastName: values.lastname || undefined,
            companyName: values.company || undefined,
            source: ref || undefined,
          },
        )
          .then(() => {
            this.props.history.push('/register/?validate=true');
          })
          .catch((error) => {
            if (error && error.response && error.response.status === 409) {
              message.error('An account with the same email already exist.');
              ReactGA.event({
                category: 'Register_error',
                action: 'Same_email_already_exist',
              });
            } else if (error && error.response && error.response.status === 403) {
              message.error('You can\'t register with a disposable email');
              ReactGA.event({
                category: 'Register_error',
                action: 'Disposable_email',
              });
            } else {
              message.error('Register error ! Verify your email and password');
            }
          })
          .finally(() => this.setState({ loading: false }));
      }
    });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue('password')) {
      callback('The 2 passwords are different.');
    } else {
      callback();
    }
  };

  verifyEmail = (rule, value, callback) => {
    if (value && !isValidEmail(value)) {
      callback('Please register with your business email');
    } else {
      callback();
    }
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { loading } = this.state;
    const { validate } = parseSearch(this.props.location.search);
    const isValidationMode = validate === 'true';
    return (
      <div className="Login_global_global">
        <div className="Login_global_wrapper">
          <div className="Register_form_double">
            <div className="Register_form_one">
              <div className="Register_quote_wrapper">
                <div style={{ position: 'relative' }}>
                  <div className="Register_quote_icon Register_quote_icon_top">
                    <Icon type="double-left" />
                  </div>
                  <div className="Register_quote">
                Having a platform like Tokfluence,
                it’s actually helping us work more efficiently
                and also deliver better results for our clients.
                  </div>
                  <div style={{ textAlign: 'right' }} className="Register_quote_icon Register_quote_icon_bottom">
                    <Icon type="double-right" />
                  </div>
                </div>

                <div className="Register_quote_credits title">Inigo Rivero</div>
                <div className="Register_quote_credits_company">House of Marketers</div>
                <div className="Register_quote_credits_company">ex Strategy and Partnership Manager at TikTok</div>
              </div>
            </div>
            <div className="Register_form_one">
              <div className="Login_form_logo_wrapper">
                <img
                  src="img/logo.png"
                  className="Login_form_logo"
                  alt="Logo tokfluence"
                />
              </div>
              {isValidationMode
                ? (
                  <div className="center">
                    <h2>Checkout your mailbox </h2>

            We've just sent you an
             email. Please check your email and follow the link to verify your Tokfluence account.
                    <br />
                    <br />
                  </div>
                )
                : (
                  <>
                    <Form
                      onSubmit={this.handleSubmit}
                      className="login-form"
                    >
                      <Form.Item
                        label="Firstname"
                        style={{ display: 'inline-block', width: '50%' }}
                      >
                        {getFieldDecorator('firstname', {
                          rules: [{ required: true, message: 'Please enter your firstname' }],
                        })(
                          <Input
                            prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                            placeholder="Firstname"
                            type="text"
                          />,
                        )}
                      </Form.Item>
                      <Form.Item
                        label="Lastname"
                        style={{ display: 'inline-block', width: '50%', paddingLeft: '6px' }}
                      >
                        {getFieldDecorator('lastname', {
                          rules: [{ required: true, message: 'Please enter your lastname' }],
                        })(
                          <Input
                            prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                            placeholder="Lastname"
                            type="text"
                          />,
                        )}
                      </Form.Item>
                      <Form.Item
                        label="Company name"
                      >
                        {getFieldDecorator('company', {
                          rules: [{ required: true, message: 'Please enter your company name' }],
                        })(
                          <Input
                            prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                            placeholder="Enter your company"
                            type="text"
                          />,
                        )}
                      </Form.Item>
                      <Form.Item
                        label="Business Email"
                      >
                        {getFieldDecorator('email', {
                          validateTrigger: 'onBlur',
                          rules: [{
                            required: true,
                            message: 'Please enter your email',
                          },

                          {
                            validator: this.verifyEmail,
                          }],

                        })(
                          <Input
                            prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
                            placeholder="Enter your Business Email"
                            type="email"
                          />,
                        )}
                      </Form.Item>
                      <Form.Item
                        label="Password"
                      >
                        {getFieldDecorator('password', {
                          rules: [{ required: true, message: 'Please enter your password' }],
                        })(
                          <Input
                            prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                            type="password"
                            placeholder="Password - 6 character min."
                          />,
                        )}
                      </Form.Item>
                      <Form.Item>
                        {getFieldDecorator('confirm', {
                          rules: [
                            {
                              required: true,
                              message: 'Confirm your password',
                            },
                            {
                              validator: this.compareToFirstPassword,
                            },
                          ],
                        })(
                          <Input
                            prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                            type="password"
                            placeholder="Password (confirmation)"
                          />,
                        )}
                      </Form.Item>
                      <Form.Item>
                        {getFieldDecorator('agreement', {
                          valuePropName: 'checked',
                          rules: [
                            { required: true, message: 'You must accept the terms of use.' },
                          ],
                        })(
                          <Checkbox>
                            {'I accept the '}
                            <a
                              href="https://tokfluence.com/terms-conditions"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {'terms and conditions'}
                            </a>
                  .
                          </Checkbox>,
                        )}
                      </Form.Item>

                      <Form.Item>
                        <Button
                          loading={loading}
                          type="primary"
                          htmlType="submit"
                          className="login-form-button"
                        >
                Create my account
                        </Button>

                      </Form.Item>
                    </Form>
                    <div>
                      <a className="Login_register_link" href="/#/login">
              I already have an account
                      </a>
                    </div>
                  </>
                )}

            </div>
          </div>

        </div>
      </div>
    );
  }
}

Register.propTypes = {
  form: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  // getProfile: PropTypes.func,
};

Register.defaultProps = {
  form: null,
  history: {},
  location: {},
  // getProfile: () => {},
};

export default Form.create({ name: 'login' })(withRouter(Register));
