import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Modal, Input, Button, message, Result, Icon,
} from 'antd';
import { withRouter } from 'react-router-dom';
import { sendFeedback } from '../services/user';
import { GOALS } from '../services/constants';
import '../styles/feedbackModal.less';

class FeedbackModal extends Component {
  state={
    input: null,
    loading: false,
    finished: false,
    textMode: false,
    selected: [],
  }

  submit = () => {
    this.setState({ loading: true });
    const { input, textMode, selected } = this.state;
    if (textMode) {
      if (input && input.length > 5) {
        sendFeedback(input).then(() => {
          // message.success('Thanks for your answer and Welcome at Tokfluence!');
          this.setState({ loading: false, finished: true });
        })
          .catch(() => {
            message.error('Error while saving your answer, please try again.');
            this.setState({ loading: false });
          });
      } else {
        message.error('Please answer the quetion before we can continue.');
        this.setState({ loading: false });
      }
    } else if (selected && selected.length > 0) {
      const reason = selected.join(', ');
      sendFeedback(reason).then(() => {
        // message.success('Thanks for your answer and Welcome at Tokfluence!');
        this.setState({ loading: false, finished: true });
      })
        .catch(() => {
          message.error('Error while saving your answer, please try again.');
          this.setState({ loading: false });
        });
    } else {
      message.error('Please select at least one goal.');
      this.setState({ loading: false });
    }
  }

  close = () => this.props.history.push('/search/');

  onChange = event => this.setState({ input: event.target.value })

  removeSelected = id => this.setState(prevState => ({
    selected: prevState.selected.filter(s => s !== id),
  }))

  addSelected = id => this.setState(prevState => ({ selected: [...prevState.selected, id] }))

  isSelected = id => this.state.selected.find(s => s === id);

  toggleCustomReason = () => this.setState(prevState => ({ textMode: !prevState.textMode }))

  render() {
    const {
      input, loading, finished, textMode,
    } = this.state;
    return (
      <Modal
        title={null}
        centered
        visible
        onOk={() => { if (!finished) { this.submit(); } }}
        closable={false}
        footer={null}
        width={600}
      >
        {finished
          ? (
            <Result
              status="success"
              title="Thanks for your answer and Welcome at Tokfluence!"
              subTitle="If you need more information on Tokfluence, please contact us at contact@tokfluence.com."
              extra={[
                <Button type="primary" key="console" onClick={this.close}>
          Browse influencers now
                </Button>,
              ]}
            />
          )
          : (
            <>
              <div>
                <div>
                  <h3 className="bold">
                    Tell us what you want to achieve with Tokfluence ?
                  </h3>
                  <div>
                    We want to help you reaching this goal. You can select multiple goals.
                  </div>
                </div>
                <br />
                {textMode ? (
                  <>
                    <Button
                      type="link"
                      icon="double-left"
                      onClick={this.toggleCustomReason}
                      style={{ paddingLeft: '0' }}
                    >
Back to choices
                    </Button>
                    <br />
                    <Input.TextArea
                      value={input}
                      onChange={this.onChange}
                      placeholder="What do you want to achieve with Tokfluence ?"
                    />
                  </>
                )
                  : (
                    <div className="Goals_wrapper">
                      {GOALS.map(g => (
                        <div
                          onClick={() => {
                            if (this.isSelected(g.id)) {
                              this.removeSelected(g.id);
                            } else {
                              this.addSelected(g.id);
                            }
                          }}
                          className={`Goals_item_wrapper ${this.isSelected(g.id) ? 'Goals_item_wrapper_selected' : ''}`}
                        >
                          <div className="Goals_picture"><img src={g.picture} alt={g.title} /></div>
                          <div className="Goals_title title">{g.title}</div>
                          <div className="Goals_icon">
                            <Icon type="check-circle" />
                          </div>
                        </div>
                      ))}
                      <div
                        onClick={this.toggleCustomReason}
                        className="Goals_item_wrapper"
                      >
                        <div className="Goals_picture"><img src="img/onboarding/6else.png" alt="Something else" /></div>
                        <div className="Goals_title title">Something else</div>
                        <div className="Goals_icon">
                          <Icon type="form" />
                        </div>
                      </div>
                    </div>
                  )}
              </div>
              <div className="Modal_action_wrapper">
                <Button
                  loading={loading}
                  onClick={this.submit}
                  type="primary"
                  htmlType="submit"
                  className="width-100"
                >
              Submit
                </Button>
              </div>
            </>
          )}

      </Modal>
    );
  }
}

FeedbackModal.propTypes = {
  history: PropTypes.object,
};

FeedbackModal.defaultProps = {
  history: {},
};


export default withRouter(FeedbackModal);
