export const COUNTRIES = [
  {
    name: 'Palestine',
    countryCode: 'PS',
    states: [],
  },
  {
    name: 'Albania',
    countryCode: 'AL',
    states: [],
  },
  {
    name: 'Argentina',
    countryCode: 'AR',
    states: [],
  },
  {
    name: 'Armenia',
    countryCode: 'AM',
    states: [],
  },
  {
    name: 'Angola',
    countryCode: 'AG',
    states: [],
  },
  {
    name: 'Australia',
    countryCode: 'AU',
    states: [],
  },
  {
    name: 'Austria',
    countryCode: 'AT',
    states: [],
  },
  {
    name: 'Azerbaijan',
    countryCode: 'AZ',
    states: [],
  },
  {
    name: 'Bahamas',
    countryCode: 'BS',
    states: [],
  },
  {
    name: 'Bahrain',
    countryCode: 'BH',
    states: [],
  },
  {
    name: 'Barbados',
    countryCode: 'BB',
    states: [],
  },
  {
    name: 'Belarus',
    countryCode: 'BY',
    states: [],
  },
  {
    name: 'Belgium',
    countryCode: 'BE',
    states: [],
  },
  {
    name: 'Bolivia',
    countryCode: 'BO',
    states: [],
  },
  {
    name: 'Botswana',
    countryCode: 'BW',
    states: [],
  },
  {
    name: 'Brazil',
    countryCode: 'BR',
    states: [],
  },
  {
    name: 'Bulgaria',
    countryCode: 'BG',
    states: [],
  },
  {
    name: 'Cameroon',
    countryCode: 'CM',
    states: [],
  },
  {
    name: 'Canada',
    countryCode: 'CA',
    states: [
      {
        short: 'AB',
        name: 'Alberta',
        country: 'CA',
      },
      {
        short: 'BC',
        name: 'British Columbia',
        country: 'CA',
      },
      {
        short: 'MB',
        name: 'Manitoba',
        country: 'CA',
      },
      {
        short: 'NB',
        name: 'New Brunswick',
        country: 'CA',
      },
      {
        short: 'NL',
        name: 'Newfoundland and Labrador',
        country: 'CA',
        alt: [
          'Newfoundland',
          'Labrador',
        ],
      },
      {
        short: 'NT',
        name: 'Northwest Territories',
        country: 'CA',
      },
      {
        short: 'NS',
        name: 'Nova Scotia',
        country: 'CA',
      },
      {
        short: 'NU',
        name: 'Nuvanut',
        country: 'CA',
      },
      {
        short: 'ON',
        name: 'Ontario',
        country: 'CA',
      },
      {
        short: 'PE',
        name: 'Prince Edward Island',
        country: 'CA',
      },
      {
        short: 'QC',
        name: 'Quebec',
        country: 'CA',
      },
      {
        short: 'SK',
        name: 'Saskatchewan',
        country: 'CA',
      },
      {
        short: 'YT',
        name: 'Yukon',
        country: 'CA',
      },
    ],
  },
  {
    name: 'Chile',
    countryCode: 'CL',
    states: [],
  },
  {
    name: 'China',
    countryCode: 'CN',
    states: [],
  },
  {
    name: 'Colombia',
    countryCode: 'CO',
    states: [],
  },
  {
    name: 'Costa Rica',
    countryCode: 'CR',
    states: [],
  },
  {
    name: 'Croatia',
    countryCode: 'HR',
    states: [],
  },
  {
    name: 'Curacao',
    countryCode: 'CW',
    states: [],
  },
  {
    name: 'Cyprus',
    countryCode: 'CY',
    states: [],
  },
  {
    name: 'Czech Republic',
    countryCode: 'CZ',
    states: [],
  },
  {
    name: 'Denmark',
    countryCode: 'DK',
    states: [],
  },
  {
    name: 'Dominican Republic',
    countryCode: 'DO',
    states: [],
  },
  {
    name: 'Ecuador',
    countryCode: 'EC',
    states: [],
  },
  {
    name: 'Egypt',
    countryCode: 'EG',
    states: [],
  },
  {
    name: 'El Salvador',
    countryCode: 'SV',
    states: [],
  },
  {
    name: 'Estonia',
    countryCode: 'EE',
    states: [],
  },
  {
    name: 'Finland',
    countryCode: 'FI',
    states: [],
  },
  {
    name: 'Fiji',
    countryCode: 'FJ',
    states: [],
  },
  {
    name: 'France',
    countryCode: 'FR',
    states: [],
  },
  {
    name: 'Georgia',
    countryCode: 'GE',
    states: [],
  },
  {
    name: 'Germany',
    countryCode: 'DE',
    states: [],
  },
  {
    name: 'Ghana',
    countryCode: 'GH',
    states: [],
  },
  {
    name: 'Greece',
    countryCode: 'GR',
    states: [],
  },
  {
    name: 'Guatemala',
    countryCode: 'GT',
    states: [],
  },
  {
    name: 'Honduras',
    countryCode: 'HN',
    states: [],
  },
  {
    name: 'Hungary',
    countryCode: 'HU',
    states: [],
  },
  {
    name: 'Iceland',
    countryCode: 'IS',
    states: [],
  },
  {
    name: 'Ivory Coast',
    countryCode: 'CI',
    states: [],
  },
  {
    name: 'India',
    countryCode: 'IN',
    states: [],
  },
  {
    name: 'Indonesia',
    countryCode: 'ID',
    states: [],
  },
  {
    name: 'Ireland',
    countryCode: 'IE',
    states: [],
  },
  {
    name: 'Isle of Man',
    countryCode: 'IM',
    states: [],
  },
  {
    name: 'Israel',
    countryCode: 'IL',
    states: [],
  },
  {
    name: 'Italy',
    countryCode: 'IT',
    states: [],
  },
  {
    name: 'Japan',
    countryCode: 'JP',
    states: [],
  },
  {
    name: 'Jersey',
    countryCode: 'JE',
    states: [],
  },
  {
    name: 'Jordan',
    countryCode: 'JO',
    states: [],
  },
  {
    name: 'Kazakhstan',
    countryCode: 'KZ',
    states: [],
  },
  {
    name: 'Kenya',
    countryCode: 'KE',
    states: [],
  },
  {
    name: 'Kosovo',
    countryCode: 'XK',
    states: [],
  },
  {
    name: 'Kuwait',
    countryCode: 'KW',
    states: [],
  },
  {
    name: 'Latvia',
    countryCode: 'LV',
    states: [],
  },
  {
    name: 'Lebanon',
    countryCode: 'LB',
    states: [],
  },
  {
    name: 'Lithuania',
    countryCode: 'LT',
    states: [],
  },
  {
    name: 'Luxembourg',
    countryCode: 'LU',
    states: [],
  },
  {
    name: 'Macedonia',
    countryCode: 'MK',
    states: [],
  },
  {
    name: 'Madagascar',
    countryCode: 'MG',
    states: [],
  },
  {
    name: 'Malaysia',
    countryCode: 'MY',
    states: [],
  },
  {
    name: 'Maldives',
    countryCode: 'MV',
    states: [],
  },
  {
    name: 'Malta',
    countryCode: 'MT',
    states: [],
  },
  {
    name: 'Mauritius',
    countryCode: 'MU',
    states: [],
  },
  {
    name: 'Mexico',
    countryCode: 'MX',
    states: [],
  },
  {
    name: 'Moldova',
    countryCode: 'MD',
    states: [],
  },
  {
    name: 'Mongolia',
    countryCode: 'MN',
    states: [],
  },
  {
    name: 'Morocco',
    countryCode: 'MA',
    states: [],
  },
  {
    name: 'Mozambique',
    countryCode: 'MZ',
    states: [],
  },
  {
    name: 'Myanmar',
    countryCode: 'MM',
    states: [],
  },
  {
    name: 'Namibia',
    countryCode: 'NA',
    states: [],
  },
  {
    name: 'Netherlands',
    countryCode: 'NL',
    states: [],
  },
  {
    name: 'New Zealand',
    countryCode: 'NZ',
    states: [],
  },
  {
    name: 'Nicaragua',
    countryCode: 'NI',
    states: [],
  },
  {
    name: 'Nigeria',
    countryCode: 'NG',
    states: [],
  },
  {
    name: 'Norway',
    countryCode: 'NO',
    states: [],
  },
  {
    name: 'Oman',
    countryCode: 'OM',
    states: [],
  },
  {
    name: 'Pakistan',
    countryCode: 'PK',
    states: [],
  },
  {
    name: 'Panama',
    countryCode: 'PA',
    states: [],
  },
  {
    name: 'Papua New Guinea',
    countryCode: 'PG',
    states: [],
  },
  {
    name: 'Paraguay',
    countryCode: 'PY',
    states: [],
  },
  {
    name: 'Peru',
    countryCode: 'PE',
    states: [],
  },
  {
    name: 'Philippines',
    countryCode: 'PH',
    states: [],
  },
  {
    name: 'Poland',
    countryCode: 'PL',
    states: [],
  },
  {
    name: 'Portugal',
    countryCode: 'PT',
    states: [],
  },
  {
    name: 'Puerto Rico',
    countryCode: 'PR',
    states: [],
  },
  {
    name: 'Qatar',
    countryCode: 'QA',
    states: [],
  },
  {
    name: 'Romania',
    countryCode: 'RO',
    states: [],
  },
  {
    name: 'Russia',
    countryCode: 'RU',
    states: [],
  },
  {
    name: 'Rwanda',
    countryCode: 'RW',
    states: [],
  },
  {
    name: 'Saudi Arabia',
    countryCode: 'SA',
    states: [],
  },
  {
    name: 'Serbia',
    countryCode: 'RS',
    states: [],
  },
  {
    name: 'Seychelles',
    countryCode: 'SC',
    states: [],
  },
  {
    name: 'Singapore',
    countryCode: 'SG',
    states: [],
  },
  {
    name: 'Senegal',
    countryCode: 'SN',
    states: [],
  },
  {
    name: 'Slovakia',
    countryCode: 'SK',
    states: [],
  },
  {
    name: 'Slovenia',
    countryCode: 'SI',
    states: [],
  },
  {
    name: 'South Africa',
    countryCode: 'ZA',
    states: [],
  },
  {
    name: 'South Korea',
    countryCode: 'KR',
    states: [],
  },
  {
    name: 'Spain',
    countryCode: 'ES',
    states: [],
  },
  {
    name: 'Saint Lucia',
    countryCode: 'LC',
    states: [],
  },
  {
    name: 'Suriname',
    countryCode: 'SR',
    states: [],
  },
  {
    name: 'Sweden',
    countryCode: 'SE',
    states: [],
  },
  {
    name: 'Switzerland',
    countryCode: 'CH',
    states: [],
  },
  {
    name: 'Taiwan',
    countryCode: 'TW',
    states: [],
  },
  {
    name: 'Tanzania',
    countryCode: 'TZ',
    states: [],
  },
  {
    name: 'Thailand',
    countryCode: 'TH',
    states: [],
  },
  {
    name: 'Trinidad and Tobago',
    countryCode: 'TT',
    states: [],
  },
  {
    name: 'Tunisia',
    countryCode: 'TN',
    states: [],
  },
  {
    name: 'Turkey',
    countryCode: 'TR',
    states: [],
  },
  {
    name: 'United Arab Emirates',
    countryCode: 'AE',
    states: [],
  },
  {
    name: 'Uganda',
    countryCode: 'UG',
    states: [],
  },
  {
    name: 'Ukraine',
    countryCode: 'UA',
    states: [],
  },
  {
    name: 'United Kingdom',
    countryCode: 'GB',
    states: [],
  },
  {
    name: 'Uruguay',
    countryCode: 'UY',
    states: [],
  },
  {
    name: 'United States',
    countryCode: 'US',
    states: [
      {
        short: 'AK',
        name: 'Alaska',
        country: 'US',
      },
      {
        short: 'AL',
        name: 'Alabama',
        country: 'US',
      },
      {
        short: 'AZ',
        name: 'Arizona',
        country: 'US',
      },
      {
        short: 'AR',
        name: 'Arkansas',
        country: 'US',
      },
      {
        short: 'CA',
        name: 'California',
        country: 'US',
      },
      {
        short: 'CO',
        name: 'Colorado',
        country: 'US',
      },
      {
        short: 'CT',
        name: 'Connecticut',
        country: 'US',
      },
      {
        short: 'DC',
        name: 'District of Columbia',
        alt: [
          'Washington DC',
          'Washington D.C.',
        ],
        country: 'US',
      },
      {
        short: 'DE',
        name: 'Delaware',
        country: 'US',
      },
      {
        short: 'FL',
        name: 'Florida',
        country: 'US',
      },
      {
        short: 'GA',
        name: 'Georgia',
        country: 'US',
      },
      {
        short: 'HI',
        name: 'Hawaii',
        country: 'US',
      },
      {
        short: 'ID',
        name: 'Idaho',
        country: 'US',
      },
      {
        short: 'IL',
        name: 'Illinois',
        country: 'US',
      },
      {
        short: 'IN',
        name: 'Indiana',
        country: 'US',
      },
      {
        short: 'IA',
        name: 'Iowa',
        country: 'US',
      },
      {
        short: 'KS',
        name: 'Kansas',
        country: 'US',
      },
      {
        short: 'KY',
        name: 'Kentucky',
        country: 'US',
      },
      {
        short: 'LA',
        name: 'Louisiana',
        country: 'US',
      },
      {
        short: 'ME',
        name: 'Maine',
        country: 'US',
      },
      {
        short: 'MD',
        name: 'Maryland',
        country: 'US',
      },
      {
        short: 'MA',
        name: 'Massachusetts',
        country: 'US',
      },
      {
        short: 'MI',
        name: 'Michigan',
        country: 'US',
      },
      {
        short: 'MN',
        name: 'Minnesota',
        country: 'US',
      },
      {
        short: 'MS',
        name: 'Mississippi',
        country: 'US',
      },
      {
        short: 'MO',
        name: 'Missouri',
        country: 'US',
      },
      {
        short: 'MT',
        name: 'Montana',
        country: 'US',
      },
      {
        short: 'NE',
        name: 'Nebraska',
        country: 'US',
      },
      {
        short: 'NH',
        name: 'New Hampshire',
        country: 'US',
      },
      {
        short: 'NV',
        name: 'Nevada',
        country: 'US',
      },
      {
        short: 'NJ',
        name: 'New Jersey',
        country: 'US',
      },
      {
        short: 'NM',
        name: 'New Mexico',
        country: 'US',
      },
      {
        short: 'NY',
        name: 'New York',
        country: 'US',
      },
      {
        short: 'NC',
        name: 'North Carolina',
        country: 'US',
      },
      {
        short: 'ND',
        name: 'North Dakota',
        country: 'US',
      },
      {
        short: 'OH',
        name: 'Ohio',
        country: 'US',
      },
      {
        short: 'OK',
        name: 'Oklahoma',
        country: 'US',
      },
      {
        short: 'OR',
        name: 'Oregon',
        country: 'US',
      },
      {
        short: 'PA',
        name: 'Pennsylvania',
        country: 'US',
      },
      {
        short: 'RI',
        name: 'Rhode Island',
        country: 'US',
      },
      {
        short: 'SC',
        name: 'South Carolina',
        country: 'US',
      },
      {
        short: 'SD',
        name: 'South Dakota',
        country: 'US',
      },
      {
        short: 'TN',
        name: 'Tennessee',
        country: 'US',
      },
      {
        short: 'TX',
        name: 'Texas',
        country: 'US',
      },
      {
        short: 'UT',
        name: 'Utah',
        country: 'US',
      },
      {
        short: 'VT',
        name: 'Vermont',
        country: 'US',
      },
      {
        short: 'VA',
        name: 'Virginia',
        country: 'US',
      },
      {
        short: 'WA',
        name: 'Washington',
        country: 'US',
      },
      {
        short: 'WV',
        name: 'West Virginia',
        country: 'US',
      },
      {
        short: 'WI',
        name: 'Wisconsin',
        country: 'US',
      },
      {
        short: 'WY',
        name: 'Wyoming',
        country: 'US',
      },
    ],
  },
  {
    name: 'Venezuela',
    countryCode: 'VE',
    states: [],
  },
  {
    name: 'Vietnam',
    countryCode: 'VN',
    states: [],
  },
  {
    name: 'Zambia',
    countryCode: 'ZM',
    states: [],
  },
  {
    name: 'Zimbabwe',
    countryCode: 'ZW',
    states: [],
  },
];

export const getCountryNameFromCode = code => (
  COUNTRIES.find(c => c.countryCode === code) || { name: code });
