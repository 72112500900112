import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: {
      tagline1: 'Number 1 website',
      tagline2: 'for searching Tiktok influencers',
      taglinehint_search: 'Search',
      taglinehint_filter: 'filter',
      taglinehint_order: ' and order',
      taglinehint_influence: ' influencers among',
      taglinehint_million: '3 million accounts.',
      argumentTitle: 'Find the influencers you need.',
      IregisterFree: 'I register for free',
      argumentTitle1: 'Tokfluence allows you to search by',
      country: 'country',
      numberOfFollowers: 'number of followers',
      argumentTitle2: ' or even ',
      engagementRate: 'engagement rate',
      AreYouAnAgency: 'Are you a agency ?',
      contactUs: 'Contact us at',
      errorEnterEmail: 'Enter your email!',
      placeholderEmail: 'Email',
      errorEnterPassword: 'Enter your password !',
      placeholderPassword: 'Password',
      connect: 'Login',
      ForgetPassword: 'Forgot password',
      errorLoginEmail: 'Login error : this email does not exist.',
      errorLoginPassword: 'Login error : Wrong password.',
      errorLoginVerify: 'Login error : You must verify your account by email.',
      errorUnknown: 'Unknown error.',
      errorConfirmPassword: 'Please confirm your password !',
      paymentError: 'Error while processing payment',
      placeholderPasswordConfirmation: 'Password (confirmation)',
      acceptConditions: 'You must accept the terms of use.',
      IAcceptConditions1: 'I accept the ',
      IAcceptConditions2: 'terms of use',
      createMyAccount: 'Create my account',
      IalreadyHaveAnAccount: 'I already have an account',
      errorPasswordSize: 'The paswword must have minimum 8 characters.',
      errorPasswordDifferent: 'Both password are different.',
      errorRegisterPassword: 'Login error ! Verify your email and password',
      enterNewPassword: 'Enter a new password',
      errorEnterNewPassword: 'Enter your password!',
      placeholderNewPassword: 'New password',
      update: 'Update',
      passwordUpdatedSuccess: 'Password updated',
      errorLinkResetPasswordExpired: 'The link for updating your password has expired.',
      forgotPassword: 'Forgot password',
      reinitPassword: 'Re-init my password',
      forgotPasswordSuccess: 'We just sent you an email with the instructions.',
      errorNoAccountWithThisEmail: 'There is no account with this email.',
      welcome: 'Welcome',
      letsgo: "Let's go",
      disconnect: 'Log out',
      validate: 'Validate',
      yes: 'Yes',
      no: 'No',

    },
    // },
    // fr: {
    //   translation: {
    //     tagline1: 'Recherche d\'influenceurs',
    //     taglinehint_search: 'Recherchez',
    //     taglinehint_filter: 'filtrez',
    //     taglinehint_order: ' et triez',
    //     taglinehint_influence: ' les influenceurs parmi',
    //     taglinehint_million: '4 millions de comptes.',
    //     argumentTitle: "Trouvez les influenceurs qu'il vous faut.",
    //     IregisterFree: "Je m'inscris gratuitement",
    //     argumentTitle1: 'Tokfluence vous permet de rechercher par',
    //     country: 'pays',
    //     numberOfFollowers: 'nombre de followers',
    //     argumentTitle2: ' ou encore par ',
    //     engagementRate: "taux d'engagement",
    //     AreYouAnAgency: 'Vous êtes une agence ?',
    //     contactUs: 'Contactez nous à',
    //     placeholderEmail: 'Email',
    //     reinitPassword: 'Réinitialiser mon mot de passe',
    //     connect: 'Connexion',
    //     forgotPassword: 'Mot de passe oublié',
    //     update: 'Mettre à jour',
    //     enterNewPassword: 'Enter a new password',
    //     errorEnterNewPassword: 'Enter your password!',
    //     placeholderNewPassword: 'New password',

  //   },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'en',

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
