/* eslint-disable react/jsx-closing-tag-location */
import React from 'react';
import { Icon, Modal } from 'antd';
import Axios from './requests';
import { API_URL } from './constants';
import { getSessionId } from './user';

export const getReportList = () => Axios.post(
  `${API_URL}/platform-users/reports/list`,
  null,
  { headers: { sessionId: getSessionId() } },
);

export const getReportById = reportId => Axios.post(
  `${API_URL}/platform-users/reports/get-by-id`,
  { reportId },
  { headers: { sessionId: getSessionId() } },
);

export const askReport = influencerId => Axios.post(
  `${API_URL}/platform-users/reports/create`,
  { influencerId },
  { headers: { sessionId: getSessionId() } },
);

export const buyCredits = ({ quantity, stripePk }) => Axios.post(
  `${API_URL}/platform-users/reports/buy-credits`,
  { quantity, stripePk },
  { headers: { sessionId: getSessionId() } },
);

export const displayModalConfirm = ({ credits, onOk }) => Modal.confirm({
  title: 'You are about to request a profile report costing 1 credit.',
  content: `This credit will be taken from your credit. You currently have ${credits} credits.`,
  okText: <span>
Buy report
    {' '}
    <Icon type="radar-chart" />
    {' '}
    {' '}
    <span className="title bold">1</span>
  </span>,
  okButtonProps: {
    className: 'Report_button',
  },
  onOk,
});

export default {};
