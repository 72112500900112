/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import ReactDOM from 'react-dom';
import { message } from 'antd';
import Moment from 'moment';
import App from './App';
import 'moment/locale/fr';

Moment.locale('en');
message.config({
  top: 64,
});

ReactDOM.render(<App />, document.getElementById('root'));
