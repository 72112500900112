/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import CampaignsView from './campaignsView';
import '../../styles/global.less';

const Campaign = props => <CampaignsView {...props} isTracking={props.isTracking} />;

Campaign.propTypes = {
  match: PropTypes.object,
  isTracking: PropTypes.bool,
};

Campaign.defaultProps = {
  match: {},
  isTracking: false,
};


export default withRouter(Campaign);
