import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Form, Icon, Input, Button, Menu, message, Modal, Dropdown,
} from 'antd';
import { withRouter } from 'react-router-dom';
import { isConnected, logout, getUserId } from '../services/user';
import BannerAnnouncement from './bannerAnnouncement';
import '../styles/global.less';
import '../styles/header.less';
import { isBannerDisplayed } from '../services/utils';


class Header extends Component {
  input=null;

  state={
    loading: false,
    menuSelected: [],
    width: null,
    viewProfileModale: false,
  }

  componentDidMount() {
    this.updateWindowDimensions();
    this.setMenuSelected();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentDidUpdate(prevProps) {
    if (this.props.history.location.pathname !== prevProps.history.location.pathname) {
      this.setMenuSelected();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions=() => {
    if (this.state.width !== window.innerWidth) {
      this.setState({ width: window.innerWidth });
    }
  }

  handleSubmit = (e) => {
    this.setState({ loading: true });
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        if (!values || !values.search || values.search.length === 0) {
          message.error('Please enter a username');
          this.setState({ loading: false });
          return;
        }
        const str = (values.search.substring(0, 1) === '@')
          ? values.search.substring(1, values.search.length) : values.search;
        getUserId(str).then((res) => {
          this.props.history.push(`/users/${res.data._id}`);
          this.props.form.resetFields();
          this.closeViewProfileModale();
        })
          .catch((error) => {
            if (error.response.status === 404) {
              message.error('Account not found');
            } else {
              message.error('Error while searching account, please try again.');
            }
            console.error(error);
          }).finally(() => {
            this.setState({ loading: false });
          });
      }
    });
  };

  hasErrors = fieldsError => Object.keys(fieldsError).some(field => fieldsError[field]);

  logout = () => {
    logout(() => { this.props.history.push('/login'); });
  }

  setMenuSelected = () => {
    if (this.props.history.location.pathname.includes('search')) {
      this.setState({ menuSelected: ['search'] });
    } else if (this.props.history.location.pathname.includes('campaigns')) {
      this.setState({ menuSelected: ['campaigns'] });
    } else if (this.props.history.location.pathname.includes('reports')) {
      this.setState({ menuSelected: ['reports'] });
    } else {
      this.setState({ menuSelected: [] });
    }
  }

  getViewProfileForm = (inline = true) => (
    <Form layout={inline ? 'inline' : null} onSubmit={this.handleSubmit} className="">
      <Form.Item className="Input_header_wrapper">
        {this.props.form.getFieldDecorator('search')(
          <Input
            ref={(node) => { this.input = node; }}
            prefix={<span style={{ color: 'rgba(0,0,0,.25)' }}>@</span>}
            placeholder="username"
            name="influencerName"
            type="text"
            style={{ maxWidth: '150px' }}
            disabled={this.state.loading}
          />,
        )}
      </Form.Item>
      <Form.Item>
        <Button
          loading={this.state.loading}
          icon="user"
          type="primary"
          ghost
          htmlType="submit"
          disabled={this.hasErrors(this.props.form.getFieldsError())}
        >
          View profile
        </Button>
      </Form.Item>
    </Form>
  )

  openViewProfileModale = () => this.setState({ viewProfileModale: true }, () => setTimeout(() => {
    this.input.focus();
  }, 100))

  closeViewProfileModale = () => this.setState({ viewProfileModale: false })

  render() {
    const { hasBack, isHome, profile } = this.props;
    const {
      menuSelected, width, viewProfileModale,
    } = this.state;
    return (
      <>
        <div className={`Header_wrapper ${hasBack ? 'Header_back' : ''}`}>
          <div className="Header_inner">
            <div className="Header_left">
              <div className="Header_logo_wrapper">
                <a href="/#/search">
                  <img src="img/logo.png" alt="Tokfluence - Influencer search tool" className="Header_logo" />
                </a>
              </div>
              {!isHome && (
              <div className="Form_wrapper_header">
                {width > 850 ? this.getViewProfileForm() : (
                  <Button
                    icon="user"
                    type="primary"
                    shape="circle"
                    ghost
                    onClick={this.openViewProfileModale}
                  >
                    <span className="onlyMobile">View profile</span>
                  </Button>
                )}
              </div>
              )}
            </div>
            {!isHome && (
            <div className="Menu_elements_wrapper">
              <Menu selectedKeys={menuSelected} mode="horizontal">
                <Menu.Item key="search">
                  <a href="/#/search">
                    <Icon type="search" />
                    <span className="Hide_on_mobile">Search</span>
                  </a>
                </Menu.Item>
                <Menu.Item key="campaigns">
                  <a href="/#/campaigns">
                    <Icon type="project" />
                    <span className="Hide_on_mobile">Campaigns</span>
                  </a>
                </Menu.Item>
                <Menu.Item key="reports">
                  <a href="/#/reports">
                    <Icon type="radar-chart" />
                    <span className="Hide_on_mobile">Reports</span>
                  </a>
                </Menu.Item>
              </Menu>
            </div>
            )}

            <div className="Header_actions_wrapper">
              {isConnected() ? (
                <Dropdown.Button
                  onClick={() => this.props.history.push('/settings')
              }
                  overlay={(
                    <Menu>
                      <Menu.Item
                        key="1"
                        onClick={this.logout}
                      >
                        <Icon type="logout" />
                    Logout
                      </Menu.Item>
                    </Menu>
  )}
                  className="Header_connect_button"
                >
                  {/* <Icon type="setting" /> */}
                  <Icon type="user" />
                </Dropdown.Button>
              )
                : (
                  <a href="/#/login" className="Header_connect_wrapper">
                    <Button icon="user" type="primary" shape="round" ghost className="Header_connect_button">
                      <span className="onlyMobile">Login</span>
                    </Button>
                  </a>
                )}
            </div>
          </div>
          {isBannerDisplayed(width, profile) ? <BannerAnnouncement /> : <></>}
        </div>
        <Modal
          className="View_profile_modal"
          title="View profile"
          footer={null}
          visible={viewProfileModale}
          onCancel={this.closeViewProfileModale}
        >
          {this.getViewProfileForm(false)}
        </Modal>
        <div className={`Header_pad_top ${isBannerDisplayed(width, profile) ? 'Header_pad_banner' : ''}`} />
      </>
    );
  }
}

Header.propTypes = {
  hasBack: PropTypes.bool,
  isHome: PropTypes.bool,
  form: PropTypes.object,
  history: PropTypes.object,
  profile: PropTypes.object,
};

Header.defaultProps = {
  hasBack: true,
  isHome: false,
  form: {},
  history: {},
  profile: null,
};

export default Form.create({ name: 'search' })(withRouter(Header));
