/* eslint-disable react/prop-types */
import React from 'react';
import { Result, Button } from 'antd';
import Header from '../../components/header';

const PaymentSuccess = ({ profile }) => (
  <div>
    <Header />
    <div style={{
      minHeight: '800px',
      paddingTop: '20px',
    }}
    >
      <Result
        status="success"
        title="Successfully Purchased Audience credits."
        subTitle={profile ? `Your now have ${profile.audienceAnalysisCredits} credits.` : ''}
        extra={[
          <a href="/#/search">
            <Button type="primary" key="search">
        Go To Search Page
            </Button>
          </a>,
          <a href="/#/campaigns"><Button key="camp">See my Campaigns</Button></a>,
        ]}
      />
    </div>
  </div>
);

export default PaymentSuccess;
