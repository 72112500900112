/* eslint-disable react/no-unescaped-entities */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import {
  Button, Avatar, Empty, Skeleton, Tag, Tooltip, Icon,
  Statistic,
} from 'antd';
import Moment from 'moment';
import Header from '../../components/header';
import { getReportList } from '../../services/reports';
import Credits from '../../components/credits';
import {
  getCountLabel,
} from '../../services/utils';

import '../../styles/reportList.less';

const getStatusText = (status) => {
  switch (status) {
    case 'IN_PROGRESS':
      return (
        <span>
          <Icon type="loading" />
          {' '}
        IN PROGRESS
        </span>
      );
    case 'QUEUED':
      return 'QUEUED';
    default:
    case 'DONE':
      return 'AVAILABLE';
  }
};


const Report = ({ report }) => (
  <div className="Report_item_vignette_wrapper">
    <div className="Report_item_vignette_inner">

      <div className="Report_item_vignette_left">
        <div>
          {report.influencer.profilePicUrl ? (
            <Avatar size={80} src={report.influencer.profilePicUrl} />
          ) : (
            <Avatar size={80} icon="user" />
          )}
        </div>
        <div className="Report_item_vignette_name">
          <div className="title bold Report_item_vignette_title">
            {report.influencer.fullName}
          </div>
          <div className="Report_item_vignette_subname">
            {/* <a href={`/#/users/${report.influencer._id}`}> */}
            @
            {report.influencer.username}
            {/* </a> */}
          </div>
          <div className="Report_item_updated">
            {`Generated on ${new Moment(new Date(report.updatedAt)).format('MM/DD/YYYY')} `}
          </div>

          {report.status !== 'DONE' && (
          <div className="Report_item_status">
            <Tag color={report.status === 'IN_PROGRESS' ? 'orange' : 'blue'}>{getStatusText(report.status)}</Tag>
            {report.status === 'IN_PROGRESS' && (
            <Tooltip title="Your report will be ready soon">
              <Icon type="info-circle" />
            </Tooltip>
            )}
          </div>
          )}
        </div>
      </div>
      <div className="flex center" style={{ justifyContent: 'center', marginTop: '8px' }}>
        <Statistic style={{ padding: '12px' }} title="Followers" value={getCountLabel(report.influencer.followerCount)} />
        <Statistic style={{ padding: '12px' }} title="Engagement" value={`${getCountLabel(report.influencer.globalEngagementRate)}%`} />
      </div>
      <div className="Report_item_vignette_right">
        <a href={`/#/users/${report.influencer._id}`}>
          <Button
            type="link"
          >
View Account
          </Button>
        </a>
        <a
          href={`/#/reports/${report._id}`}
        >
          <Button
            disabled={!(report.status === 'DONE')}
            type="primary"
            icon="eye"
            className="width-100"
          >
View report
          </Button>
        </a>
      </div>

    </div>
  </div>
);

Report.propTypes = {
  report: PropTypes.object,
};

Report.defaultProps = {
  report: {},
};

class Reports extends Component {
  state={ loading: false, reports: null }

  componentDidMount() {
    this.setState({ loading: true });
    getReportList().then(res => this.setState({ reports: res.data }))
      .catch((e) => {
        console.error(e);
      }).finally(() => {
        this.setState({ loading: false });
      });
  }

  render() {
    const { reports, loading } = this.state;
    const { profile } = this.props;
    return (
      <div>
        <Header profile={profile} />
        <div className="Report_list_wrapper">
          <div className="div-wrapper">
            <div className="container">
              <div className="Report_list_title_wrapper">
                <div>
                  <h1 className="Report_list_title">Your reports</h1>
                </div>
                <div className="flex" style={{ alignItems: 'center', marginRight: '12px' }}>
                  {(profile) && (
                  <div className="flex" style={{ alignItems: 'center' }}>
                    <div className="Report_list_credit_container">
                      <Credits profile={profile} />
                    </div>
                    <div>
                      <a href="/#/reports/buy-credits">
                        <Button type="link">
                                Buy credits
                        </Button>
                      </a>
                    </div>

                  </div>
                  )}
                </div>
              </div>

              <div className="subtitle Report_list_subtitle">Find here the reports you ordered</div>
              <br />
              <div className="Report_list">
                {loading
                  ? [1, 2, 3, 4, 5, 6].map(e => (
                    <div className="Loading_vignette" key={e}>
                      <div className="Loading_vignette_inner">
                        <Skeleton avatar paragraph={{ rows: 2 }} active />
                      </div>
                    </div>
                  ))
                  : (reports && reports.length > 0)
                    ? (
                      <div>
                        {reports.filter(r => (r.status === 'IN_PROGRESS') || (r.status === 'QUEUED')).length > 0 ? (
                          <>
                            <h2 className="Report_list_title">Pending reports</h2>
                            <div className="flex-wrap">
                              { reports
                                .filter(r => (r.status === 'IN_PROGRESS'))
                                .sort((a, b) => (new Date(b.updatedAt) - new Date(a.updatedAt)))
                                .map(r => <Report key={r._id} report={r} />)}
                              { reports
                                .filter(r => (r.status === 'QUEUED'))
                                .sort((a, b) => (new Date(b.updatedAt) - new Date(a.updatedAt)))
                                .map(r => <Report key={r._id} report={r} />)}
                            </div>
                            <br />
                          </>
                        ) : <></>}
                        <h2 className="Report_list_title">Available reports</h2>
                        <div className="flex-wrap">
                          { reports
                            .filter(r => r.status === 'DONE')
                            .sort((a, b) => (new Date(b.updatedAt) - new Date(a.updatedAt)))
                            .map(r => <Report key={r._id} report={r} />)}
                        </div>
                      </div>
                    ) : (
                      <div className="Report_list_empty">
                        <Empty
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                          description={<span>You don't have any reports yet</span>}
                        >
                          <a href="/#/search">
                            <Button type="primary">Browse influencers</Button>
                          </a>
                        </Empty>
                      </div>

                    )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Reports.propTypes = {
  profile: PropTypes.object,
};

Reports.defaultProps = {
  profile: {},
};

export default withRouter(Reports);
