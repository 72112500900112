/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-restricted-globals */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import flag from 'country-code-emoji';
import {
  Form,
  Button,
  Select,
  Checkbox,
  Slider,
  Tag,
  Icon,
  Input,
  Radio,
  Popover,
  Alert,
  Tooltip,
  Divider,
  InputNumber,
} from 'antd';
import { withRouter } from 'react-router-dom';
import COUNTRIES from '../services/influencerCountry';
import Loader from './loader';
import {
  parseSearch,
  getCountLabelInteger,
  filterSearchParamsWithPlan,
  getAlertStarter,
  getKeywordsStringFromArray,
  getTagsStringFromArray,
  getStarterTooltip,
  replaceAll,
  isAuthorized,
  isBannerDisplayed,
} from '../services/utils';
import {
  SORT_TYPES,
  SORT_TYPES_STARTER,
  SORT_ORDER,
  PROFILE_PIC_TYPES_RADIO,
  getProfilePicTypeTextFromId,
  FEATURES,
} from '../services/constants';
import { countInfluencers, getHashtagSuggestions } from '../services/search';
import TKIcon from './tkIcon';
import '../styles/searchForm.less';

const { Option } = Select;

class SearchForm extends Component {
  wrapper = React.createRef();

  initialSearchState = {
    loading: false,
    typegt: 1000,
    typelt: 1000000,
    influencerCountry: [],
    onlyVerified: false,
    onlyVerifiedInsta: false,
    onlyVerifiedYoutube: false,
    onlyWithEmails: false,
    onlyWithInstagram: false,
    onlyWithYoutube: false,
    instagramMinFollowers: null,
    instagramMaxFollowers: null,
    instagramPostsPerWeek: null,
    youtubeMinFollowers: null,
    youtubeMaxFollowers: null,
    instaMinNumberPosts: null,
    minEngagement: null,
    instaEngagementRate: null,
    minTiktokVideos: null,
    sortOrder: SORT_ORDER.desc.key,
    sortType: SORT_TYPES.tiktokFollowerCount.key,
    changesMade: false,
    count: null,
    countLoading: false,
    busy: false,
    showSlideValue: false,
    tiktokPopoverVisible: false,
    instaPopoverVisible: false,
    youtubePopoverVisible: false,
    helpPopoverVisible: false,
    genderPopoverVisible: false,
    keywordsPopoverVisible: false,
    hashtagPopoverVisible: false,
    mentionPopoverVisible: false,
    showFilters: false,
    gender: 'NONE',
    postsPerWeekMin: null,
    tiktokAverageViews: null,
    keywords: null,
    keywordInputValue: null,
    hashtags: null,
    hashtagInputValue: null,
    mentions: null,
    mentionInputValue: null,
    hashtagSuggestions: null,
    hashtagSuggestionsLoading: false,
  }

  state = {
    ...this.initialSearchState,
    searchHeight: 40,
    width: null,
  }

  marks = {
    1000: '1k',
    100000: '100k',
    500000: '500k',
    1000000: '1M+',
  };

  componentDidMount() {
    window.addEventListener('resize', this.resizeWindow);
    this.init();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.search !== this.props.location.search
      || prevProps.profile !== this.props.profile) {
      this.updateValues();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeWindow);
  }

  init = () => {
    this.setState({ width: window.innerWidth });
    this.updateValues(() => {
      this.props.form.setFieldsValue({ influencerSize: [this.state.typegt, this.state.typelt] });
    });
    this.calculateSearchHeightForPadding();
  }

  resizeWindow = () => {
    if (window.innerWidth !== this.state.width) {
      this.setState({
        width: window.innerWidth,
        loading: false,
        showSlideValue: false,
        tiktokPopoverVisible: false,
        instaPopoverVisible: false,
        youtubePopoverVisible: false,
        genderPopoverVisible: false,
        keywordsPopoverVisible: false,
        hashtagPopoverVisible: false,
        mentionPopoverVisible: false,
        helpPopoverVisible: false,
        showFilters: false,
      }, () => { this.calculateSearchHeightForPadding(); });
    }
  }

  calculateSearchHeightForPadding = () => setTimeout(() => this.setState({
    searchHeight: (this.wrapper && this.wrapper.current) ? this.wrapper.current.clientHeight : 40,
  }), 200)

  getSearchStateFromURL = (search) => {
    const {
      type,
      countries,
      verified,
      email,
      insta,
      yt,
      instamin,
      instamax,
      ermin,
      instaermin,
      instaminpost,
      tiktokminvideos,
      sortorder,
      sorttype,
      gender,
      ppw,
      keywords,
      hashtags,
      mentions,
      verifiedInsta,
      verifiedYoutube,
      ytmin,
      ytmax,
      tiktokAverageViews,
      instappw,
    } = parseSearch(search);
    const typeComa = (type && type.length > 0) ? type.split(',') : ['1000', '1000000'];
    const countriesList = countries ? countries.split(',') : [];
    return {
      typegt: parseInt(typeComa[0], 0),
      typelt: parseInt(typeComa[1], 0),
      influencerCountry: countriesList,
      onlyVerified: (verified && verified === 'true'),
      onlyVerifiedInsta: (verifiedInsta && verifiedInsta === 'true'),
      onlyVerifiedYoutube: (verifiedYoutube && verifiedYoutube === 'true'),
      onlyWithEmails: (email && email === 'true'),
      onlyWithInstagram: (insta && insta === 'true'),
      onlyWithYoutube: (yt && yt === 'true'),
      instagramMinFollowers: instamin,
      instagramMaxFollowers: instamax,
      instagramPostsPerWeek: instappw,
      youtubeMinFollowers: ytmin,
      youtubeMaxFollowers: ytmax,
      instaMinNumberPosts: instaminpost,
      minEngagement: ermin,
      instaEngagementRate: instaermin,
      sortOrder: sortorder || SORT_ORDER.desc.key,
      sortType: sorttype || SORT_TYPES.tiktokFollowerCount.key,
      gender,
      postsPerWeekMin: ppw,
      tiktokAverageViews,
      minTiktokVideos: tiktokminvideos,
      keywords: keywords ? keywords.split(',') : [],
      hashtags: hashtags ? hashtags.split(',') : [],
      mentions: mentions ? mentions.split(',') : [],
    };
  };


  updateValues = (func) => {
    const newState = this.getSearchStateFromURL(this.props.location.search);
    this.setState(filterSearchParamsWithPlan(newState, this.props.profile), () => {
      if (func) { func(); }
    });
  }

  handleSubmit = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    if (this.state.keywordInputValue && this.state.keywordInputValue.length > 0) {
      this.handleKeywordsInputConfirm({
        processCallbackAfterChange: false,
        callback: () => { this.submitSearch(); },
      });
    } else if (this.state.hashtagInputValue && this.state.hashtagInputValue.length > 0) {
      this.handleHashtagInputConfirm({
        processCallbackAfterChange: false,
        callback: () => { this.submitSearch(); },
      });
    } else if (this.state.mentionInputValue && this.state.mentionInputValue.length > 0) {
      this.handleMentionInputConfirm({
        processCallbackAfterChange: false,
        callback: () => { this.submitSearch(); },
      });
    } else {
      this.submitSearch();
    }
  };

  submitSearch = () => {
    this.setState({
      loading: true,
      showSlideValue: false,
      tiktokPopoverVisible: false,
      instaPopoverVisible: false,
      youtubePopoverVisible: false,
      genderPopoverVisible: false,
      keywordsPopoverVisible: false,
      hashtagPopoverVisible: false,
      mentionPopoverVisible: false,
      helpPopoverVisible: false,
      showFilters: false,
    }, () => {
      this.calculateSearchHeightForPadding();
    });
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const slider = this.isMobile() ? [this.state.typegt, this.state.typelt] : (values.influencerSize || []);
        const max = Math.max(slider[0], slider[1]);
        const min = Math.min(slider[0], slider[1]);
        this.props.history.push(
          `/search?type=${min},${max}&countries=${values.influencerCountry}${this.state.onlyVerified
            ? '&verified=true'
            : ''}${(this.state.onlyWithInstagram && this.state.onlyVerifiedInsta)
            ? '&verifiedInsta=true'
            : ''}${this.state.onlyWithEmails
            ? '&email=true'
            : ''}${this.state.onlyWithInstagram
            ? '&insta=true'
            : ''}${this.state.onlyWithYoutube
            ? '&yt=true'
            : ''}${(this.state.onlyWithYoutube && this.state.onlyVerifiedYoutube)
            ? '&verifiedYoutube=true'
            : ''}${(this.state.onlyWithInstagram && this.state.instagramMinFollowers)
            ? `&instamin=${this.state.instagramMinFollowers}`
            : ''}${(this.state.onlyWithInstagram && this.state.instagramMaxFollowers)
            ? `&instamax=${this.state.instagramMaxFollowers}`
            : ''}${(this.state.onlyWithYoutube && this.state.youtubeMinFollowers)
            ? `&ytmin=${this.state.youtubeMinFollowers}`
            : ''}${(this.state.onlyWithYoutube && this.state.youtubeMaxFollowers)
            ? `&ytmax=${this.state.youtubeMaxFollowers}`
            : ''}${this.state.minEngagement
            ? `&ermin=${this.state.minEngagement}`
            : ''}${this.state.onlyWithInstagram && this.state.instaEngagementRate
            ? `&instaermin=${this.state.instaEngagementRate}`
            : ''}${(this.state.onlyWithInstagram && this.state.instaMinNumberPosts)
            ? `&instaminpost=${this.state.instaMinNumberPosts}`
            : ''}${this.state.minTiktokVideos
            ? `&tiktokminvideos=${this.state.minTiktokVideos}`
            : ''}${this.state.keywords
            ? `&keywords=${this.state.keywords}`
            : ''}${this.state.hashtags
            ? `&hashtags=${this.state.hashtags}`
            : ''}${this.state.mentions
            ? `&mentions=${this.state.mentions}`
            : ''}${this.state.gender
            ? `&gender=${this.state.gender}`
            : ''}${this.state.postsPerWeekMin
            ? `&ppw=${this.state.postsPerWeekMin}`
            : ''}${(this.state.onlyWithInstagram && this.state.instagramPostsPerWeek)
            ? `&instappw=${this.state.instagramPostsPerWeek}`
            : ''}${this.state.tiktokAverageViews
            ? `&tiktokAverageViews=${this.state.tiktokAverageViews}`
            : ''}&sorttype=${this.state.sortType}&sortorder=${this.state.sortOrder}`,
        );
        this.setState({ loading: false, changesMade: false });
      }
    });
  }

  getCount = () => {
    this.setState({ countLoading: true });
    const {
      onlyVerified,
      onlyVerifiedInsta,
      onlyVerifiedYoutube,
      onlyWithEmails,
      onlyWithInstagram,
      onlyWithYoutube,
      instagramMinFollowers,
      instagramMaxFollowers,
      instagramPostsPerWeek,
      youtubeMinFollowers,
      youtubeMaxFollowers,
      instaMinNumberPosts,
      minEngagement,
      instaEngagementRate,
      minTiktokVideos,
      gender,
      postsPerWeekMin,
      tiktokAverageViews,
      keywords,
      hashtags,
      mentions,
    } = this.state;
    const values = this.props.form.getFieldsValue(['influencerSize', 'influencerCountry']);
    const slid = this.isMobile() ? [parseInt(this.state.typegt, 0), parseInt(this.state.typelt, 0)] : (values.influencerSize || []);
    const options = ({
      type: {
        gte: slid[0],
        lt: slid[1] === 1000000 ? undefined : slid[1],
      },
      country: values.influencerCountry,
      isTiktokVerified: onlyVerified ? true : undefined,
      instagramVerified: (onlyWithInstagram && onlyVerifiedInsta) ? true : undefined,
      isYoutubeVerified: (onlyWithYoutube && onlyVerifiedYoutube) ? true : undefined,
      hasContactEmail: onlyWithEmails ? true : undefined,
      hasInstagramLinked: onlyWithInstagram ? true : undefined,
      hasYoutubeLinked: onlyWithYoutube ? true : undefined,
      instagramFollowerCount: (onlyWithInstagram && (instagramMinFollowers || instagramMaxFollowers))
        ? {
          gte: parseInt(instagramMinFollowers, 0) || undefined,
          lt: parseInt(instagramMaxFollowers, 0) || undefined,
        }
        : undefined,
      youtubeSubscriberCount: (onlyWithYoutube && (youtubeMinFollowers || youtubeMaxFollowers))
        ? {
          gte: parseInt(youtubeMinFollowers, 0) || undefined,
          lt: parseInt(youtubeMaxFollowers, 0) || undefined,
        }
        : undefined,
      tiktokEngagementRate: minEngagement ? {
        gte: parseInt(minEngagement, 0),
      } : undefined,
      instagramEngagementRate: (onlyWithInstagram && instaEngagementRate) ? {
        gte: parseInt(instaEngagementRate, 0),
      } : undefined,
      instagramMediaCount: (onlyWithInstagram && instaMinNumberPosts) ? {
        gte: parseInt(instaMinNumberPosts, 0),
      } : undefined,
      tiktokVideoCount: minTiktokVideos ? {
        gte: parseInt(minTiktokVideos, 0),
      } : undefined,
      profilePicTypes: (gender && gender !== 'NONE') ? PROFILE_PIC_TYPES_RADIO[gender] : undefined,
      postsPerWeek: postsPerWeekMin ? {
        gte: parseInt(postsPerWeekMin, 0),
      } : undefined,
      instagramPostsPerWeek: instagramPostsPerWeek ? {
        gte: parseInt(instagramPostsPerWeek, 0),
      } : undefined,
      tiktokAverageViews: tiktokAverageViews ? {
        gte: parseInt(tiktokAverageViews, 0),
      } : undefined,
      profileBiographiesQuery: (keywords && keywords.length > 0) ? getKeywordsStringFromArray(keywords) : undefined,
      tags: (hashtags && hashtags.length > 0) ? getTagsStringFromArray(hashtags) : undefined,
      mentions: (mentions && mentions.length > 0) ? getTagsStringFromArray(mentions) : undefined,
    });

    countInfluencers(options).then((res) => {
      this.setState({ count: res.data });
    }).catch(error => console.error('count', error))
      .finally(() => this.setState({ countLoading: false }));
  }

  hasErrors = fieldsError => Object.keys(fieldsError).some(field => fieldsError[field]);

  onChangeElement = (value, id, submit = false) => this.setState({
    [id]: value,
  }, () => {
    if (submit) {
      this.handleSubmit();
    } else {
      this.callBackAfterChange();
    }
  });

  callBackAfterChange = () => {
    this.setState({ changesMade: true }, () => { this.getCount(); });
  }

  callBackAfterChangeSlider = () => {
    if (!this.state.busy) {
      this.setState({ busy: true }, () => {
        setTimeout(() => {
          this.setState({ busy: false });
        }, 500);
      });
      this.callBackAfterChange();
    }
  }

  openSlider = () => {
    this.props.form.setFieldsValue({ influencerSize: [this.state.typegt, this.state.typelt] });
    setTimeout(() => {
      this.setState({ showSlideValue: true });
    }, 150);
  }

  closeSlider = (callback = () => {}) => {
    this.setState({
      showSlideValue: false,
    }, () => {
      callback();
    });
  }

  getTiktokOptionsChecked = () => {
    const {
      onlyVerified, minEngagement, minTiktokVideos, postsPerWeekMin, typegt, typelt, tiktokAverageViews,
    } = this.state;
    const hasFollowersLimitation = ((typegt && typegt > 1000) || (typelt && typelt < 1000000)) ? true : undefined;

    return [onlyVerified, minEngagement, minTiktokVideos, postsPerWeekMin, tiktokAverageViews, hasFollowersLimitation].filter(e => (e === true || (e !== undefined && e !== null))).length;
  }

  getInstaOptionsChecked = () => {
    const {
      instaMinNumberPosts, instagramMinFollowers, instagramMaxFollowers, onlyWithInstagram, onlyVerifiedInsta, instaEngagementRate, instagramPostsPerWeek,
    } = this.state;

    return onlyWithInstagram ? [instaMinNumberPosts, instagramMinFollowers, instagramMaxFollowers, instagramPostsPerWeek, instaEngagementRate, onlyWithInstagram, onlyVerifiedInsta].filter(e => (e === true || (e !== undefined && e !== null))).length : 0;
  }

  getYoutubeOptionsChecked = () => {
    const {
      onlyWithYoutube, onlyVerifiedYoutube, youtubeMinFollowers, youtubeMaxFollowers,
    } = this.state;

    return onlyWithYoutube ? [onlyWithYoutube, onlyVerifiedYoutube, youtubeMinFollowers, youtubeMaxFollowers].filter(e => (e === true || (e !== undefined && e !== null))).length : 0;
  }

  clearAllCriterias = () => {
    this.props.history.push('/search');
    this.setState(this.initialSearchState, () => {
      this.props.form.resetFields();
      this.init();
    });
  }

  toggleFilters = () => this.setState(prevState => ({ showFilters: !prevState.showFilters }), () => {
    if (this.state.showFilters === false) {
      this.resizeWindow();
    }
  })

  onVisibleChange = (visible, id) => {
    if (id === 'tiktokPopoverVisible' && visible === false) {
      this.closeSlider(() => { this.changeVisibilityState(visible, id); });
    } else {
      this.changeVisibilityState(visible, id);
    }
  }

  changeVisibilityState = (visible, id) => {
    this.setState({ [id]: visible }, () => {
      if (visible === false && this.state.changesMade === true) {
        // this.handleSubmit();
      }
      if (id === 'tiktokPopoverVisible' && visible === true) {
        this.openSlider();
      }
    });
  }

  handleTagRemoval = (removedTag, id) => {
    const tags = this.state[id].filter(tag => tag !== removedTag);
    this.setState({ [id]: tags }, () => { this.callBackAfterChange(); });
  };

  handleInputChange = (value, id) => this.setState({ [id]: value });

  handleKeywordsInputConfirm = ({
    processCallbackAfterChange = true,
    callback = null,
  }) => {
    const { keywordInputValue } = this.state;
    let { keywords } = this.state;
    if (!keywords) {
      keywords = [];
    }
    const separated = (keywordInputValue && keywordInputValue.length > 0) ? keywordInputValue.split(',') : [];
    if (separated && separated.length > 0) {
      separated.forEach((e) => {
        if (e && keywords.indexOf(e) === -1) {
          keywords.push(e);
        }
      });
    }
    this.setState({
      keywords,
      keywordInputValue: '',
    }, () => {
      if (processCallbackAfterChange) {
        this.callBackAfterChange();
      }
      if (callback) { callback(); }
    });
  };

  handleHashtagInputConfirm = ({
    processCallbackAfterChange = true,
    callback = null,
  }) => {
    const { hashtagInputValue } = this.state;
    let { hashtags } = this.state;
    if (!hashtags) {
      hashtags = [];
    }
    const withoutHashtag = replaceAll(hashtagInputValue, '#', '');
    const cleaned = replaceAll(withoutHashtag, ' ', '');
    const separated = (cleaned && cleaned.length > 0) ? cleaned.split(',') : [];
    if (separated && separated.length > 0) {
      separated.forEach((e) => {
        if (e && hashtags.indexOf(e) === -1) {
          hashtags.push(e);
        }
      });
    }
    this.setState({
      hashtags,
      hashtagInputValue: '',
    }, () => {
      if (processCallbackAfterChange) {
        this.callBackAfterChange();
      }
      if (callback) { callback(); }
      this.getHashtagSuggestions(separated[separated.length - 1]);
    });
  };

  getHashtagSuggestions = (h) => {
    this.setState({ hashtagSuggestionsLoading: true });
    getHashtagSuggestions(h).then((res) => {
      if (res && res.data
        && res.data.graphql
        && res.data.graphql.hashtag.edge_hashtag_to_related_tags
        && res.data.graphql.hashtag.edge_hashtag_to_related_tags.edges
        && res.data.graphql.hashtag.edge_hashtag_to_related_tags.edges.length > 0) {
        const hashtagSuggestions = res.data.graphql.hashtag.edge_hashtag_to_related_tags.edges.reduce((
          acc, current,
        ) => ([
          ...acc,
          current.node.name,
        ]), []);
        this.setState({
          hashtagSuggestions: hashtagSuggestions.filter(s => s.substring(0, 4) !== 'inst'
        && s.substring(s.length - 4, s.length) !== 'gram'
        && !this.state.hashtags.includes(s)),
        });
      }
    }).finally(() => {
      this.setState({ hashtagSuggestionsLoading: false });
    });
  }

  addHashtag = (h) => {
    const { hashtags } = this.state;
    const tags = (hashtags || []);
    const alreadyContained = tags.includes(h);
    if (h && !alreadyContained) {
      this.setState(prevState => ({ hashtags: [...(prevState.hashtags || []), h] }), () => {
        this.callBackAfterChange();
        this.getHashtagSuggestions(h);
      });
    }
  }

  handleMentionInputConfirm = ({
    processCallbackAfterChange = true,
    callback = null,
  }) => {
    const { mentionInputValue } = this.state;
    let { mentions } = this.state;
    if (!mentions) {
      mentions = [];
    }
    const withoutHashtag = replaceAll(mentionInputValue, '@', '');
    const cleaned = replaceAll(withoutHashtag, ' ', '');
    if (cleaned && (!mentions || mentions.indexOf(cleaned) === -1)) {
      mentions = [...mentions, cleaned];
    }
    this.setState({
      mentions,
      mentionInputValue: '',
    }, () => {
      if (processCallbackAfterChange) {
        this.callBackAfterChange();
      }
      if (callback) { callback(); }
    });
  };

  changeSliderValues = (min, max) => this.setState({
    typegt: min,
    typelt: max,
  }, () => {
    this.props.form.setFieldsValue({ influencerSize: [this.state.typegt, this.state.typelt] });
    this.callBackAfterChange();
  });

  isMobile = () => (this.state.width < 800)

  onChangeCountry = (e) => {
    this.setState({ influencerCountry: e }, () => {
      this.callBackAfterChange();
    });
  }

  render() {
    const { getFieldDecorator, getFieldsError } = this.props.form;
    const {
      loading,
      hashtagInputValue,
      mentionInputValue,
      influencerCountry,
      changesMade,
      count,
      countLoading,
      showSlideValue,
      tiktokPopoverVisible,
      instaPopoverVisible,
      youtubePopoverVisible,
      genderPopoverVisible,
      searchHeight,
      showFilters,
      keywordInputValue,
      helpPopoverVisible,
      hashtagSuggestions,
      hashtagSuggestionsLoading,
      width,
    } = this.state;
    const { profile } = this.props;

    return (
      <>
        <div
          className="Search_wrapper_pad"
          style={{
            paddingBottom: `${searchHeight}px`,
          }}
        />
        <Form
          layout="inline"
          onSubmit={this.handleSubmit}
          className={`Search_wrapper_form ${isBannerDisplayed(width, profile) ? 'Search_wrapper_form_padding' : ''}`}
        >
          <div className="Header_form_wrapper" ref={this.wrapper}>
            <div className={`Header_form ${showFilters ? 'showFiltersMobile' : ''}`}>
              <div className="Tooltip_container">
                <Popover
                  className="Influencer_contact_container"
                  overlayClassName="Tooltip_overlay"
                  onVisibleChange={e => this.onVisibleChange(e, 'tiktokPopoverVisible')}
                  visible={tiktokPopoverVisible}
                  placement="bottomLeft"
                  trigger="click"
                  content={(
                    <div className="Influencer_criteria_wrapper" style={{ width: '440px', maxWidth: '100%' }}>
                      <h3>
                        <TKIcon />
                        {' '}
Tiktok
                      </h3>

                      <div>
                        <Checkbox
                          disabled={!isAuthorized(profile, FEATURES.ADVANCED_SEARCH)}
                          onChange={e => this.onChangeElement(e.target.checked, 'onlyVerified')}
                          checked={this.state.onlyVerified}
                          className="Influencer_criteria_checkbox"
                        >
                          <img src="img/verified.png" alt="verified" className="Verified_logo_search" />
                          {' '}
                  Only verified
                        </Checkbox>
                        {!isAuthorized(profile, FEATURES.ADVANCED_SEARCH) && (
                          getStarterTooltip('These criterias are available in Business plan.')
                        )}
                      </div>

                      <div>
                        <Divider />
                        <h3>Followers</h3>
                        {!this.isMobile() ? getFieldDecorator('influencerSize', {
                        })(
                          <Slider
                            range
                            marks={this.marks}
                            step={1000}
                            min={1000}
                            max={1000000}
                            tooltipVisible={showSlideValue}
                            onChange={this.callBackAfterChangeSlider}
                            className="Influencer_size"
                          />,
                        ) : (
                          <div>
                            <span>Min</span>
                            {' '}
                            <InputNumber
                              min={1000}
                              max={1000000}
                              value={this.state.typegt}
                              onChange={e => this.onChangeElement(e, 'typegt')}
                            />
                            <InputNumber
                              min={1000}
                              max={1000000}
                              value={this.state.typelt}
                              onChange={e => this.onChangeElement(e, 'typelt')}
                            />
                            {' '}
                            <span>Max</span>
                          </div>
                        )}
                        <div className="flex">
                          <div>
                            <Button
                              type="link"
                              size="small"
                              onClick={() => {
                                this.changeSliderValues(1000, 10000);
                              }}
                            >
Nano (1k - 10k)
                            </Button>
                            <Button size="small" type="link" onClick={() => this.changeSliderValues(10000, 100000)}>Micro (10k - 100k)</Button>
                            <Button size="small" type="link" onClick={() => this.changeSliderValues(100000, 999999)}>Macro (100k - 1M)</Button>
                            <Button size="small" type="link" onClick={() => this.changeSliderValues(1000000, 1000000)}>Mega (1M+)</Button>
                          </div>
                        </div>
                      </div>
                      <div>
                        <Divider />
                        <h3>
Engagement Rate
                          <Tooltip title="The engagement rate is computed with the global number of likes, the number of followers and the number of videos.">
                            <span style={{ marginLeft: '8px' }}><Icon type="question-circle" /></span>
                          </Tooltip>
                        </h3>
                  Minimum
                        {' '}
                        <Input

                          type="number"
                          className="Instagram_input"
                          value={this.state.minEngagement}
                          onChange={e => this.onChangeElement(e.target.value, 'minEngagement')}
                        />

                      </div>
                      <div>
                        <Divider />
                        <h3>
Videos
                          {!isAuthorized(profile, FEATURES.ADVANCED_SEARCH) && (
                          <>
                            {' '}
                            <Icon type="lock" />
                          </>
                          )}
                        </h3>
                        {!isAuthorized(profile, FEATURES.ADVANCED_SEARCH) && (
                          <div>
                            {getAlertStarter('These criterias are available in Business plan.')}
                            <br />
                          </div>
                        )}
                        <div>
                          <div>
                  Minimum
                            {' '}
                            <Input
                              disabled={!isAuthorized(profile, FEATURES.ADVANCED_SEARCH)}
                              type="number"
                              className="Instagram_input"
                              value={this.state.minTiktokVideos}
                              onChange={e => this.onChangeElement(e.target.value, 'minTiktokVideos')}
                            />

                          </div>
                          <div style={{ marginTop: '4px' }}>
                  Average posts per week
                            {' '}
                            <Input
                              disabled={!isAuthorized(profile, FEATURES.ADVANCED_SEARCH)}
                              type="number"
                              className="Instagram_input"
                              value={this.state.postsPerWeekMin}
                              onChange={e => this.onChangeElement(e.target.value, 'postsPerWeekMin')}
                            />
                            <Tooltip title="Post per week feature is not available on all users.">
                              <span style={{ marginLeft: '8px' }}><Icon type="question-circle" /></span>
                            </Tooltip>
                          </div>
                          <div style={{ marginTop: '4px' }}>
                  Min. Average views per video
                            {' '}
                            <Input
                              disabled={!isAuthorized(profile, FEATURES.ADVANCED_SEARCH)}
                              type="number"
                              className="Instagram_input"
                              value={this.state.tiktokAverageViews}
                              onChange={e => this.onChangeElement(e.target.value, 'tiktokAverageViews')}
                            />
                          </div>
                        </div>
                      </div>

                    </div>
                  )}
                >
                  <Button
                    className={`Button_criteria ${this.getTiktokOptionsChecked() > 0 ? 'bold' : ''}`}
                    ghost={this.getTiktokOptionsChecked() === 0}
                    type="primary"
                  >
                    <TKIcon color={this.getTiktokOptionsChecked() === 0 ? 'purple' : 'white'} />
                    {`Tiktok ${this.state.typegt && this.state.typelt
                      ? `${this.state.typegt && this.state.typegt > 1000
                        ? `${this.state.typelt < 1000000 ? '[' : '>'}${getCountLabelInteger(this.state.typegt)}`
                        : ''}${this.state.typelt && this.state.typelt < 1000000
                        ? `${this.state.typegt && this.state.typegt > 1000 ? ' - ' : '<'}${getCountLabelInteger(this.state.typelt)}${this.state.typegt && this.state.typegt > 1000 ? ']' : ''}`
                        : ''}` : ''}${this.getTiktokOptionsChecked() > 0 ? ` (${this.getTiktokOptionsChecked()})` : ''}`}


                  </Button>
                </Popover>
              </div>

              <div className="Tooltip_container">
                <Popover
                  className=" Influencer_contact_container"
                  overlayClassName="Tooltip_overlay"
                  onVisibleChange={e => this.onVisibleChange(e, 'instaPopoverVisible')}
                  visible={instaPopoverVisible}
                  placement="bottomLeft"
                  trigger="click"
                  content={(
                    <div className="Influencer_criteria_wrapper Influencer_contact_wrapper">
                      <h3>
                        <Icon type="instagram" />
                        {' '}
Instagram
                      </h3>
                      {!isAuthorized(profile, FEATURES.ADVANCED_SEARCH) && (
                      <div>
                        {getAlertStarter('These criterias are available in Startup, Business or Gold plans.')}
                        <br />
                      </div>
                      )}

                      <div>
                        <Checkbox
                          disabled={!isAuthorized(profile, FEATURES.ADVANCED_SEARCH)}
                          onChange={e => this.onChangeElement(e.target.checked, 'onlyWithInstagram')}
                          checked={this.state.onlyWithInstagram}
                          className="Influencer_criteria_checkbox"
                        >
                          <Tag color="blue"><Icon type="instagram" /></Tag>
                          {' '}
                  has an Instagram account
                        </Checkbox>
                      </div>
                      <div className={`Instagram_input_wrapper ${this.state.onlyWithInstagram ? '' : 'Instagram_input_wrapper_disabled'}`}>
                        <div className="Instagram_input_value">
                          <Checkbox
                            disabled={!isAuthorized(profile, FEATURES.ADVANCED_SEARCH) || !this.state.onlyWithInstagram}
                            onChange={e => this.onChangeElement(e.target.checked, 'onlyVerifiedInsta')}
                            checked={this.state.onlyVerifiedInsta}
                            className="Influencer_criteria_checkbox"
                          >
                            <img src="img/verified.png" alt="verified" className="Verified_logo_search" />
                            {' '}
                  Only verified
                          </Checkbox>
                        </div>
                        <div>
                          <Divider />
                          <h3 className="Grow_one">Followers</h3>
                          <div>
                            <span>Min</span>
                            {' '}
                            <InputNumber
                              type="number"
                              min={0}
                              className="Instagram_input"
                              disabled={!this.state.onlyWithInstagram}
                              value={this.state.instagramMinFollowers}
                              onChange={e => this.onChangeElement(e, 'instagramMinFollowers')}
                            />
                            <InputNumber
                              type="number"
                              min={this.state.instagramMinFollowers}
                              className="Instagram_input"
                              disabled={!this.state.onlyWithInstagram}
                              value={this.state.instagramMaxFollowers}
                              onChange={e => this.onChangeElement(e, 'instagramMaxFollowers')}
                            />
                            {' '}
                            <span>Max</span>
                          </div>
                          <Divider />
                        </div>
                        <div className="Instagram_input_value">
                          <div className="Grow_one">Engagement Rate Min </div>
                          <InputNumber
                            type="number"
                            min={0}
                            className="Instagram_input"
                            disabled={!this.state.onlyWithInstagram}
                            value={this.state.instaEngagementRate}
                            onChange={e => this.onChangeElement(e, 'instaEngagementRate')}
                          />
                        </div>
                        <div className="Instagram_input_value">
                          <div className="Grow_one">Minimum No. of posts </div>
                          <InputNumber
                            type="number"
                            min={0}
                            className="Instagram_input"
                            disabled={!this.state.onlyWithInstagram}
                            value={this.state.instaMinNumberPosts}
                            onChange={e => this.onChangeElement(e, 'instaMinNumberPosts')}
                          />
                        </div>
                        <div className="Instagram_input_value">
                          <div className="Grow_one">No of Posts per week </div>
                          <InputNumber
                            type="number"
                            min={0}
                            className="Instagram_input"
                            disabled={!this.state.onlyWithInstagram}
                            value={this.state.instagramPostsPerWeek}
                            onChange={e => this.onChangeElement(e, 'instagramPostsPerWeek')}
                          />
                        </div>

                      </div>
                    </div>
)}
                >
                  <Button
                    className={`Button_criteria ${this.getInstaOptionsChecked() > 0 ? 'bold' : ''}`}
                    ghost={this.getInstaOptionsChecked() === 0}
                    type="primary"
                    icon="instagram"
                  >
                    {`Instagram${this.state.instagramMinFollowers
                      ? (this.state.instagramMaxFollowers
                        ? ` [${getCountLabelInteger(this.state.instagramMinFollowers)} - ${getCountLabelInteger(this.state.instagramMaxFollowers)}]`
                        : ` >${getCountLabelInteger(this.state.instagramMinFollowers)}`)
                      : this.state.instagramMaxFollowers
                        ? ` ${`>${getCountLabelInteger(this.state.instagramMaxFollowers)}`}`
                        : ''}${this.getInstaOptionsChecked() > 0 ? ` (${this.getInstaOptionsChecked()})` : ''}`}
                    {!isAuthorized(profile, FEATURES.ADVANCED_SEARCH) && <Icon type="lock" />}
                  </Button>
                </Popover>
              </div>
              <div className="Tooltip_container">
                <Popover
                  className="Influencer_contact_container"
                  overlayClassName="Tooltip_overlay"
                  onVisibleChange={e => this.onVisibleChange(e, 'youtubePopoverVisible')}
                  visible={youtubePopoverVisible}
                  placement="bottomLeft"
                  trigger="click"
                  content={(
                    <div className="Influencer_criteria_wrapper Influencer_contact_wrapper">
                      <h3>
                        <Icon type="youtube" />
                        {' '}
Youtube
                      </h3>
                      {!isAuthorized(profile, FEATURES.ADVANCED_SEARCH) && (
                      <div>
                        {getAlertStarter('These criterias are available in Startup, Business or Gold plans.')}
                        <br />
                      </div>
                      )}
                      <div>
                        <Checkbox
                          disabled={!isAuthorized(profile, FEATURES.ADVANCED_SEARCH)}
                          onChange={e => this.onChangeElement(e.target.checked, 'onlyWithYoutube')}
                          checked={this.state.onlyWithYoutube}
                          className="Influencer_criteria_checkbox"
                        >
                          <Tag color="red"><Icon type="youtube" /></Tag>
                          {' '}
                  has a Youtube channel
                        </Checkbox>
                      </div>
                      <div className={`Instagram_input_wrapper ${this.state.onlyWithYoutube ? '' : 'Instagram_input_wrapper_disabled'}`}>
                        <div className="Instagram_input_value">
                          <Checkbox
                            disabled={!isAuthorized(profile, FEATURES.ADVANCED_SEARCH) || !this.state.onlyWithYoutube}
                            onChange={e => this.onChangeElement(e.target.checked, 'onlyVerifiedYoutube')}
                            checked={this.state.onlyVerifiedYoutube}
                            className="Influencer_criteria_checkbox"
                          >
                            <img src="img/verified.png" alt="verified" className="Verified_logo_search" />
                            {' '}
                  Only verified
                          </Checkbox>
                        </div>
                        <div>
                          <Divider />
                          <h3 className="Grow_one">Followers</h3>
                          <div>
                            <span>Min</span>
                            {' '}
                            <Input
                              type="number"
                              className="Instagram_input"
                              disabled={!this.state.onlyWithYoutube}
                              value={this.state.youtubeMinFollowers}
                              onChange={e => this.onChangeElement(e.target.value, 'youtubeMinFollowers')}
                            />
                            <Input
                              type="number"
                              className="Instagram_input"
                              disabled={!this.state.onlyWithYoutube}
                              value={this.state.youtubeMaxFollowers}
                              onChange={e => this.onChangeElement(e.target.value, 'youtubeMaxFollowers')}
                            />
                            {' '}
                            <span>Max</span>
                          </div>
                        </div>
                      </div>
                    </div>

)}
                >
                  <Button
                    className={`Button_criteria ${this.getYoutubeOptionsChecked() > 0 ? 'bold' : ''}`}
                    ghost={this.getYoutubeOptionsChecked() === 0}
                    type="primary"
                    icon="youtube"
                  >
                    {`Youtube${this.state.youtubeMinFollowers
                      ? (this.state.youtubeMaxFollowers
                        ? ` [${getCountLabelInteger(this.state.youtubeMinFollowers)} - ${getCountLabelInteger(this.state.youtubeMaxFollowers)}]`
                        : ` >${getCountLabelInteger(this.state.youtubeMinFollowers)}`)
                      : this.state.youtubeMaxFollowers
                        ? ` ${`>${getCountLabelInteger(this.state.youtubeMaxFollowers)}`}`
                        : ''}${this.getYoutubeOptionsChecked() > 0 ? ` (${this.getYoutubeOptionsChecked()})` : ''}`}
                    {!isAuthorized(profile, FEATURES.ADVANCED_SEARCH) && <Icon type="lock" />}
                  </Button>
                </Popover>
              </div>

              <div className="Tooltip_container">
                <Popover
                  overlayClassName="Tooltip_overlay"
                  placement="bottomLeft"
                  trigger="click"
                  onVisibleChange={e => this.onVisibleChange(e, 'keywordsPopoverVisible')}
                  visible={this.state.keywordsPopoverVisible}
                  content={(
                    <div style={{ width: '440px', maxWidth: '100%' }}>
                      <h3>
                        <Icon type="key" />
                        {' '}
Keywords
                      </h3>
                      <div>
                        {!isAuthorized(profile, FEATURES.ADVANCED_SEARCH) && (
                          <>
                            {getAlertStarter('The keywords filter is available in Startup, Business or Gold plans.')}
                            <br />
                          </>
                        )}
                      </div>
                      {this.state.keywords && this.state.keywords.length > 0 ? (
                        <div>
                          {this.state.keywords.map((tag) => {
                            const isLongTag = tag.length > 20;
                            const tagElem = (
                              <Tag color="geekblue" key={tag} closable onClose={() => this.handleTagRemoval(tag, 'keywords')}>
                                {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                              </Tag>
                            );
                            return isLongTag ? (
                              <Tooltip title={tag} key={tag}>
                                {tagElem}
                              </Tooltip>
                            ) : (
                              tagElem
                            );
                          })}
                        </div>
                      ) : (
                        <div style={{ color: 'grey' }}>
                          <i>No keyword yet.</i>
                        </div>
                      )}
                      {this.state.keywords && this.state.keywords.length > 0
                     && this.state.keywords.length < 3 && (
                     <div style={{ marginTop: '8px' }}>
                       <Alert
                         type="warning"
                         showIcon
                         message="Add more keywords to have relevant results (min. 3)"
                       />
                     </div>
                      )}
                      <div style={{ marginTop: '12px' }}>
                        <Input
                          disabled={!isAuthorized(profile, FEATURES.ADVANCED_SEARCH)}
                          placeholder="Please input a keyword (ex: Fitness) and press enter"
                          type="text"
                          style={{ width: '100%' }}
                          value={keywordInputValue}
                          onChange={e => this.handleInputChange(e.target.value, 'keywordInputValue')}
                          onPressEnter={this.handleKeywordsInputConfirm}
                        />
                      </div>
                      <div style={{ marginTop: '10px' }}>
                        <Alert
                          message={(
                            <span>
Tokfluence searches these keywords in
                              {' '}
                              <b>Tiktok</b>
,
                              {' '}
                              <b>Instagram</b>
                              {' '}
and
                              {' '}
                              <b>Youtube</b>
                              {' '}
Bios.
                              <br />
                              <b>Add as many keywords as possible</b>
                              {' '}
to reach your target audience.
                              {' '}
                              <br />
(ex: fitness, fit, fitguy, fitgirl...)
                            </span>
)}
                          type="info"
                          showIcon
                        />
                      </div>
                    </div>
              )}
                >
                  <Button
                    className={`Button_criteria ${(this.state.keywords && this.state.keywords.length > 0) ? 'bold' : ''}`}
                    ghost={!this.state.keywords || this.state.keywords.length < 1}
                    type="primary"
                  >
                    <Icon type="key" />
                    {' '}
                    {`Keywords ${(this.state.keywords && this.state.keywords.length > 0) ? `(${this.state.keywords.length})` : ''}`}
                    {!isAuthorized(profile, FEATURES.ADVANCED_SEARCH) && <Icon type="lock" />}
                  </Button>
                </Popover>
              </div>


              <div className="Tooltip_container">
                <Popover
                  overlayClassName="Tooltip_overlay"
                  placement="bottomLeft"
                  trigger="click"
                  onVisibleChange={e => this.onVisibleChange(e, 'hashtagPopoverVisible')}
                  visible={this.state.hashtagPopoverVisible}
                  content={(
                    <div style={{ width: '440px', maxWidth: '100%' }}>
                      <h3>
                        #
                        {' '}
Hashtags
                      </h3>
                      <div>
                        {!isAuthorized(profile, FEATURES.ADVANCED_SEARCH) && (
                          <>
                            {getAlertStarter('The hashtag filter is available in Startup, Business or Gold plans.')}
                            <br />
                          </>
                        )}

                      </div>
                      {this.state.hashtags && this.state.hashtags.length > 0 ? (
                        <div>
                          {this.state.hashtags.map((tag) => {
                            const isLongTag = tag.length > 20;
                            const tagElem = (
                              <Tag color="blue" key={tag} closable onClose={() => this.handleTagRemoval(tag, 'hashtags')}>
                                {isLongTag ? `#${tag.slice(0, 20)}...` : `#${tag}`}
                              </Tag>
                            );
                            return isLongTag ? (
                              <Tooltip title={tag} key={tag}>
                                {tagElem}
                              </Tooltip>
                            ) : (
                              tagElem
                            );
                          })}
                        </div>
                      ) : (
                        <div style={{ color: 'grey' }}>
                          <i>No hashtag yet.</i>
                        </div>
                      )}
                      {hashtagSuggestions && hashtagSuggestions.length > 0
                      && (
                      <div className="Hashtag_suggestions_wrapper">
                      Related Hashtags
                        {' '}
                        {hashtagSuggestions.map(s => (
                          <a
                            key={s}
                            onClick={() => this.addHashtag(s)}
                          >
                            {`#${s} `}
                          </a>
                        ))}
                        {hashtagSuggestionsLoading && <Icon type="loading" />}
                      </div>

                      )}

                      <div style={{ marginTop: '12px' }}>
                        <Input
                          disabled={!isAuthorized(profile, FEATURES.ADVANCED_SEARCH)}
                          placeholder="Please input a hashtag (ex: #Fitness) and press enter"
                          type="text"
                          style={{ width: '100%' }}
                          value={hashtagInputValue}
                          onChange={e => this.handleInputChange(e.target.value, 'hashtagInputValue')}
                          onPressEnter={this.handleHashtagInputConfirm}
                        />
                      </div>
                      <div style={{ marginTop: '10px' }}>
                        <Alert
                          message={(
                            <span>
Tokfluence searches for accounts who used these Hashtags in their
                              {' '}
                              <b>Tiktok videos or Instagram posts.</b>
                              <br />
                              <b>Add as many keywords as possible</b>
                              {' '}
to reach your target audience.
                              {' '}
                              <br />
(ex: #fitness, #fit, #fitguy, #fitgirl...)
                            </span>
)}
                          type="info"
                          showIcon
                        />
                      </div>
                    </div>
              )}
                >
                  <Button
                    className={`Button_criteria ${(this.state.hashtags && this.state.hashtags.length > 0) ? 'bold' : ''}`}
                    ghost={!this.state.hashtags || this.state.hashtags.length < 1}
                    type="primary"
                  >
                    #
                    {' '}
                    {`Hashtags ${(this.state.hashtags && this.state.hashtags.length > 0) ? `(${this.state.hashtags.length})` : ''}`}
                    {!isAuthorized(profile, FEATURES.ADVANCED_SEARCH) && <Icon type="lock" />}
                  </Button>
                </Popover>
              </div>


              <div className="Tooltip_container">
                <Popover
                  overlayClassName="Tooltip_overlay"
                  placement="bottomLeft"
                  trigger="click"
                  onVisibleChange={e => this.onVisibleChange(e, 'mentionPopoverVisible')}
                  visible={this.state.mentionPopoverVisible}
                  content={(
                    <div style={{ width: '440px', maxWidth: '100%' }}>
                      <h3>
                        @
                        {' '}
Mentions
                      </h3>
                      <div>
                        {!isAuthorized(profile, FEATURES.ADVANCED_SEARCH) && (
                          <>
                            { getAlertStarter('The Mention filter is available in Business plan.')}
                            <br />
                          </>
                        )}

                      </div>
                      {this.state.mentions && this.state.mentions.length > 0 ? (
                        <div>
                          {this.state.mentions.map((tag) => {
                            const isLongTag = tag.length > 20;
                            const tagElem = (
                              <Tag color="blue" key={tag} closable onClose={() => this.handleTagRemoval(tag, 'mentions')}>
                                {isLongTag ? `@${tag.slice(0, 20)}...` : `@${tag}`}
                              </Tag>
                            );
                            return isLongTag ? (
                              <Tooltip title={tag} key={tag}>
                                {tagElem}
                              </Tooltip>
                            ) : (
                              tagElem
                            );
                          })}
                        </div>
                      ) : (
                        <div style={{ color: 'grey' }}>
                          <i>No account listed yet.</i>
                        </div>
                      )}
                      <div style={{ marginTop: '12px' }}>
                        <Input
                          disabled={!isAuthorized(profile, FEATURES.ADVANCED_SEARCH)}
                          placeholder="Please input a Tiktok or Instagram username (ex: @garyvee) and press enter"
                          type="text"
                          style={{ width: '100%' }}
                          value={mentionInputValue}
                          onChange={e => this.handleInputChange(e.target.value, 'mentionInputValue')}
                          onPressEnter={this.handleMentionInputConfirm}
                        />
                      </div>
                      <div style={{ marginTop: '10px' }}>
                        <Alert
                          message={(
                            <span>
Tokfluence searches for accounts who mentioned these accounts in their
                              {' '}
                              <b>Tiktok or Instagram posts.</b>
                              <br />
                              <b>You can use it to monitor when people mention your brand on Instagram or TikTok</b>
                            </span>
)}
                          type="info"
                          showIcon
                        />
                      </div>
                    </div>
              )}
                >
                  <Button
                    className={`Button_criteria ${(this.state.mentions && this.state.mentions.length > 0) ? 'bold' : ''}`}
                    ghost={!this.state.mentions || this.state.mentions.length < 1}
                    type="primary"
                  >
                    @
                    {' '}
                    {`Mentions ${(this.state.mentions && this.state.mentions.length > 0) ? `(${this.state.mentions.length})` : ''}`}
                    {!isAuthorized(profile, FEATURES.ADVANCED_SEARCH) && <Icon type="lock" />}
                  </Button>
                </Popover>
              </div>


              <div className="Tooltip_container">
                <Popover
                  overlayClassName="Tooltip_overlay"
                  placement="bottomLeft"
                  trigger="click"
                  onVisibleChange={e => this.onVisibleChange(e, 'genderPopoverVisible')}
                  visible={genderPopoverVisible}
                  content={(
                    <>
                      {!isAuthorized(profile, FEATURES.ADVANCED_SEARCH) && (
                      <div style={{ maxWidth: '550px' }}>
                        {getAlertStarter('The gender criteria are available in Startup, Business or Gold plan.')}
                        <br />
                      </div>
                      )}
                      <div>
                        <Radio.Group
                          disabled={!isAuthorized(profile, FEATURES.ADVANCED_SEARCH)}
                          onChange={e => this.onChangeElement(e.target.value, 'gender')}
                          value={this.state.gender || 'NONE'}
                        >
                          <Radio.Button value="NONE">All</Radio.Button>
                          {Object.keys(PROFILE_PIC_TYPES_RADIO).map(pp => (
                            <Radio.Button key={pp} value={pp}>
                              {
                          getProfilePicTypeTextFromId(pp)}
                            </Radio.Button>
                          ))}
                        </Radio.Group>
                        <br />
                        <div style={{ marginTop: '10px' }}>
                          <Alert message="Based on profile picture recognition algorithm." type="warning" showIcon />
                        </div>
                      </div>
                    </>
              )}
                >
                  <Button
                    className={`Button_criteria ${(this.state.gender && this.state.gender !== 'NONE') ? 'bold' : ''}`}
                    ghost={!(this.state.gender && this.state.gender !== 'NONE')}
                    type="primary"
                  >
                    <Icon type="woman" />
                    <Icon type="man" />
                    {' '}
                    {(this.state.gender && this.state.gender !== 'NONE') ? getProfilePicTypeTextFromId(this.state.gender) : 'Gender'}
                    {!isAuthorized(profile, FEATURES.ADVANCED_SEARCH) && <Icon type="lock" />}
                  </Button>
                </Popover>
              </div>


              <div className="Tooltip_container">
                {getFieldDecorator('influencerCountry', {
                  initialValue: influencerCountry,
                })(
                  <Select
                    className="Select_search"
                    mode="multiple"
                    placeholder="Select one country"
                    onChange={(e) => { setTimeout(() => this.onChangeCountry(e)); }}
                    loading={loading || countLoading}
                    filterOption={(input, option) => (option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    || option.props.label.toLowerCase().indexOf(input.toLowerCase()) >= 0)}
                  >
                    {Object.values(COUNTRIES).map(c => (
                      <Option
                        value={c.countryCode}
                        label={c.name || c.countryCode}
                        key={c.countryCode}
                      >
                        <span role="img" aria-label={c.countryCode}>
                          {flag(c.countryCode)}
                        </span>
                        {` ${c.name || c.countryCode}`}
                      </Option>
                    ))}
                  </Select>,
                )}
              </div>
              <div className="Tooltip_container">
                <div className={`Has_email_container ${this.state.onlyWithEmails ? 'Checkbox_email_checked' : ''}`}>
                  <Checkbox
                    disabled={!isAuthorized(profile, FEATURES.ADVANCED_SEARCH)}
                    onChange={e => this.onChangeElement(e.target.checked, 'onlyWithEmails')}
                    checked={this.state.onlyWithEmails}
                    className="Influencer_criteria_checkbox "
                  >
                    <Tag color="blue"><Icon type="mail" /></Tag>
                    {!isAuthorized(profile, FEATURES.ADVANCED_SEARCH) && <Icon type="lock" />}
                  </Checkbox>
                </div>
              </div>
              <div className="Tooltip_container">
                <Button
                  type="link"
                  onClick={this.clearAllCriterias}
                >
Clear
                </Button>
              </div>
              {isAuthorized(profile, FEATURES.ADVANCED_SEARCH) && (
              <div className="Tooltip_container">
                <Popover
                  overlayClassName="Tooltip_overlay"
                  placement="bottomLeft"
                  trigger="click"
                  onVisibleChange={e => this.onVisibleChange(e, 'helpPopoverVisible')}
                  visible={helpPopoverVisible}
                  title="Tips for Tokfluence search"
                  content={(
                    <>
                      <div style={{ maxWidth: '450px' }}>
                        <h2>Search examples</h2>
                        <ul>
                          <li>
                            <b>Strong Instagram account:</b>
                            {' '}
                            <a
                              onClick={() => { this.setState({ helpPopoverVisible: false }); }}
                              href="/#/search?type=1000,1000000&countries=US&insta=true&instamin=10000&keywords=&sorttype=tiktokFollowerCount&sortorder=desc"
                            >
                        US Tiktok Accounts having more than 10k followers on Instagram.
                            </a>
                            {' '}
(30.2k results)
                          </li>
                          <br />
                          <li>
                            <b>Affordable influencers in the US:</b>
                            {' '}
                            <a
                              onClick={() => { this.setState({ helpPopoverVisible: false }); }}
                              href="/#/search?type=50000,200000&countries=US&email=true&insta=true&ermin=4&keywords=&sorttype=tiktokFollowerCount&sortorder=desc"
                            >
                              {' US Accounts between 50k and 200K Followers, Engagement > 4, with an Instagram account and email info available.'}
                            </a>
                            {' '}
(12k results)
                          </li>
                          <br />
                          <li>
                            <b>Women with Instagram account:</b>
                            {' '}
                            <a
                              onClick={() => { this.setState({ helpPopoverVisible: false }); }}
                              href="/#/search?type=1000,200000&countries=US&email=true&insta=true&instamin=10000&keywords=&gender=ONLY_WOMEN&ppw=1&sorttype=tiktokFollowerCount&sortorder=desc"
                            >
                              {'US women with less than 200K Followers, an Instagram account > 10k followers, posting minimum 1 time per week and email info available.'}
                            </a>
                            {' '}
(4.4k results)
                          </li>

                        </ul>
                        <h2>Niches</h2>
                        <ul>
                          <li>
                            <a
                              onClick={() => { this.setState({ helpPopoverVisible: false }); }}
                              href="/#/search?type=1000,1000000&countries=&keywords=&hashtags=fitness,fit,gym,fitmotivation,fitnesslifestyles,fitgoals,fitnessblogger,fitnesstrainer,fitnesslifestyle,fitforlife,workout,fitnessmotivation,fitnesslife,workoutmotivation,fitnessgoals,fitnessjourney&mentions=&sorttype=tiktokFollowerCount&sortorder=desc"
                            >
                              <b>Fitness profiles</b>
                            </a>
                            {' '}
(76k results)
                          </li>
                          <li>
                            <a
                              onClick={() => { this.setState({ helpPopoverVisible: false }); }}
                              href="/#/search?type=1000,1000000&countries=&keywords=&hashtags=fashion,style,fashionista,fashionblogger,fashionlover,fashionpost,fashionaddict,fashiondiaries,lookbook,styleoftheday,styleinspiration,outfitideas,styleinfluencer,styleinspirations,outfitblog,fashiondetails,fashionaddicted,fashionistastyle&mentions=&sorttype=tiktokFollowerCount&sortorder=desc"
                            >
                              <b>Fashion profiles</b>
                            </a>
                            {' '}
(102k results)
                          </li>
                          <li>
                            <a
                              onClick={() => { this.setState({ helpPopoverVisible: false }); }}
                              href="/#/search?type=1000,1000000&countries=&keywords=&hashtags=gaming,gamer,gamers,pcgaming,game,pcgames,consolegaming,gamingposts,gamingforlife,gamingcommunity,gamingnews,gamerlife,gamelife,gameplay,videogames,gaminglife,gamercommunity,gamerlifestyle,gamernation,gamersoftheworld&mentions=&sorttype=tiktokFollowerCount&sortorder=desc"
                            >
                              <b>Gaming profiles</b>
                            </a>
                            {' '}
(47k results)
                          </li>
                        </ul>
                      </div>
                    </>
              )}
                >
                  <div className="Help_button_wrapper">
                    <div>
                      <Icon type="question-circle" />
                      {' '}
Tips
                    </div>
                  </div>
                </Popover>
              </div>
              )}
              <div
                className="Tooltip_container Apply_button_global"
                style={{ marginRight: '8px' }}
              >
                <Button
                  className="Button_search "
                  icon="search"
                  type="primary"
                  ghost={!(changesMade && !isNaN(count))}
                  htmlType="submit"
                  loading={countLoading}
                  onClick={this.handleSubmit}
                  disabled={this.hasErrors(getFieldsError())}
                >
                  <span>Apply</span>
                  {(changesMade && !isNaN(count))
                    ? (
                      <span style={{ fontSize: '14px', marginLeft: '6px' }}>
                        {' '}
                    (
                        {!isNaN(count) ? (
                          <b>
                            {getCountLabelInteger(count)}
                            {' '}
                        results
                          </b>
                        )
                          : ''}
                    )
                      </span>
                    ) : <></>}
                </Button>
              </div>

              <div className="Sort_container">
                <div><b>Order by </b></div>
                <div className="flex Sort_wrapper">
                  <div style={{ marginLeft: '4px' }}>
                    <Select
                      className="Select_sort Select_sort_type"
                      value={this.state.sortType}
                      onChange={e => this.onChangeElement(e, 'sortType', true)}
                      style={{ marginRight: '4px' }}
                    >
                      {Object.values(SORT_TYPES)
                        .filter(e => (isAuthorized(profile, FEATURES.ADVANCED_SEARCH) ? true : SORT_TYPES_STARTER.includes(e.key)))
                        .map(item => (
                          <Option
                            value={item.key}
                            label={item.label}
                            key={item.key}
                          >
                            {item.label}
                          </Option>
                        ))}
                    </Select>
                  </div>
                  <div style={{ marginLeft: '4px' }}>
                    <Radio.Group
                      value={this.state.sortOrder}
                      onChange={e => this.onChangeElement(e.target.value, 'sortOrder', true)}
                      style={{ marginRight: '4px' }}
                    >
                      {Object.values(SORT_ORDER).map(item => (
                        <Radio.Button
                          value={item.key}
                          key={item.key}
                        >
                          {item.label}
                        </Radio.Button>
                      ))}
                    </Radio.Group>
                  </div>
                </div>
              </div>
            </div>
            <div className="Filter_button" onClick={this.toggleFilters}>
              <Icon type="filter" />
              {' '}
Filters
            </div>
          </div>
          {loading && <Loader />}
          <div className={`Changes_not_applied ${(changesMade && !isNaN(count)) ? 'Changes_not_applied_visible' : ''}`}>
            Last changes are not applied, Click apply to search
          </div>

        </Form>
      </>
    );
  }
}

SearchForm.propTypes = {
  form: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
  profile: PropTypes.object,
};

SearchForm.defaultProps = {
  form: {},
  history: {},
  location: {},
  profile: {},
};

export default Form.create({ name: 'search' })(withRouter(SearchForm));
