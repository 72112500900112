import Axios from 'axios';
import { isConnected } from './user';

Axios.interceptors.response.use(response => response, (error) => {
  if (isConnected() && error && error.response && error.response.status === 401) {
    localStorage.removeItem('sessionId');
    localStorage.removeItem('type');
    window.location.replace(`${window.location.origin}/#/login`);
  }
  return Promise.reject(error);
});

export default Axios;
