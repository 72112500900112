/* eslint-disable react/prop-types */
import React from 'react';

const getIconFromColor = (color) => {
  switch (color) {
    case 'purple':
      return 'img/tkicon_p.png';
    case 'white':
      return 'img/tkicon_w.png';
    case 'blue':
      return 'img/tkicon_b.png';
    default:
      return 'img/tkicon.png';
  }
};

const TKIcon = ({ width = '14px', color }) => (
  <i className="anticon" style={{ width }}>
    <img
      style={{
        display: 'block',
        width: '100%',
        maxWidth: '100%',
        maxHeight: '100%',
        overflow: 'hidden',
      }}
      alt="tiktok"
      src={getIconFromColor(color)}
    />
  </i>
);

export default TKIcon;
