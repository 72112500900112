import Axios from './requests';
import { API_URL } from './constants';
import { getSessionId } from './user';

export const listCampaigns = (archived = false) => Axios.post(`${API_URL}/platform-users/campaigns/list`, { archived }, {
  headers: {
    sessionId: getSessionId(),
  },
});

export const createCampaign = name => Axios.post(`${API_URL}/platform-users/campaigns/create`, { name }, {
  headers: {
    sessionId: getSessionId(),
  },
});

export const deleteCampaign = id => Axios.post(`${API_URL}/platform-users/campaigns/delete`, { campaignId: id }, {
  headers: {
    sessionId: getSessionId(),
  },
});

export const updateCampaign = args => Axios.post(`${API_URL}/platform-users/campaigns/update`, { campaignId: args.id, ...args }, {
  headers: {
    sessionId: getSessionId(),
  },
});

export const getCampaignDetails = id => Axios.post(`${API_URL}/platform-users/campaigns/get-by-id`, { campaignId: id }, {
  headers: {
    sessionId: getSessionId(),
  },
});

export const getInfluencersFromCampaign = id => Axios.post(`${API_URL}/platform-users/campaigns/list-influencers`, { campaignId: id }, {
  headers: {
    sessionId: getSessionId(),
  },
});

export const addInfluencerToCampaign = (campaignId, influencerId) => Axios.post(`${API_URL}/platform-users/campaigns/add-influencer`, { campaignId, influencerId }, {
  headers: {
    sessionId: getSessionId(),
  },
});

export const addBulkInfluencersToCampaign = (campaignId, influencerIds) => Axios.post(`${API_URL}/platform-users/campaigns/bulk-add-influencers`, { campaignId, influencerIds }, {
  headers: {
    sessionId: getSessionId(),
  },
});

export const RemoveInfluencerFromCampaign = (campaignId, influencerId) => Axios.post(`${API_URL}/platform-users/campaigns/delete-influencer`, { campaignId, influencerId }, {
  headers: {
    sessionId: getSessionId(),
  },
});

export const updateInfluencerInCampaign = args => Axios.post(`${API_URL}/platform-users/campaigns/update-influencer`, args, {
  headers: {
    sessionId: getSessionId(),
  },
});

export const getDownloadListURL = (campaignId, stage) => `${API_URL}/platform-users/campaigns/${campaignId}/download-influencers-list?sessionId=${getSessionId()}${(stage && stage !== 'ALL') ? `&stage=${stage}` : ''}`;

export const addPostToTrack = args => Axios.post(`${API_URL}/platform-users/campaigns/tracking/add-video`, args, {
  headers: {
    sessionId: getSessionId(),
  },
});

export const getTrackingDetails = campaignId => Axios.post(`${API_URL}/platform-users/campaigns/tracking/get-details`, { campaignId }, {
  headers: {
    sessionId: getSessionId(),
  },
});

export const refreshTracking = campaignId => Axios.post(`${API_URL}/platform-users/campaigns/tracking/refresh`, { campaignId }, {
  headers: {
    sessionId: getSessionId(),
  },
});

export const createTrackedLink = ({
  campaignId, influencerId, id, target,
}) => Axios.post(`${API_URL}/platform-users/campaigns/tracking/create-tracked-link`, {
  campaignId, influencerId, id, target,
}, {
  headers: {
    sessionId: getSessionId(),
  },
});

export const updateTrackedLink = ({
  linkId, id, target,
}) => Axios.post(`${API_URL}/platform-users/campaigns/tracking/update-tracked-link`, {
  linkId, id, target,
}, {
  headers: {
    sessionId: getSessionId(),
  },
});

export const listTrackedLink = ({
  campaignId,
}) => Axios.post(`${API_URL}/platform-users/campaigns/tracking/list-tracked-links`, {
  campaignId,
}, {
  headers: {
    sessionId: getSessionId(),
  },
});

export const deleteTrackedLink = ({
  linkId,
}) => Axios.post(`${API_URL}/platform-users/campaigns/tracking/delete-tracked-link`, {
  linkId,
}, {
  headers: {
    sessionId: getSessionId(),
  },
});

export default {};
