import React, { Component } from 'react';
import {
  Modal, Input, Button, Icon, message, Form,
} from 'antd';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { updateCampaign } from '../services/campaigns';
import '../styles/createCampaign.less';


class CreateCampaign extends Component {
  state={ loading: false }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.update(values.name);
      }
    });
  };

  update = (name) => {
    this.setState({ loading: true });
    updateCampaign({ id: this.props.campaignToEdit._id, name })
      .then(() => {
        message.success('Campaign successfuly updated');
        this.props.refreshCampaigns();
        this.props.onClose();
      }).catch((e) => {
        console.error(e);
        message.error('Couldn\'t update the campaign, please try again.');
      })
      .finally(() => { this.setState({ loading: false }); });
  }

  hasErrors = fieldsError => Object.keys(fieldsError).some(field => fieldsError[field]);

  render() {
    const { isVisible, campaignToEdit } = this.props;
    const { getFieldDecorator, getFieldsError } = this.props.form;
    const { loading } = this.state;
    return (
      <Modal
        closable
        centered
        title="Edit a campaign"
        visible={isVisible}
        onCancel={this.props.onClose}
        className="Create_campaign_modal"
        footer={null}
      >
        <Form onSubmit={this.handleSubmit}>
          <Form.Item>
            {getFieldDecorator('name', {
              initialValue: campaignToEdit.name,
              rules: [{
                required: true,
                message: 'Please input the name of the campaign',
              }],
            })(
              <Input
                placeholder="Name the campaign"
                size="large"
              />,
            )}
          </Form.Item>

          <Form.Item>
            <Button
              disabled={this.hasErrors(getFieldsError())}
              key="submit"
              htmlType="submit"
              type="primary"
              loading={loading}
              className="Create_list_action"
              size="large"
            >
              <Icon type="save" />
              {' '}
Save
            </Button>
          </Form.Item>
        </Form>


      </Modal>
    );
  }
}

CreateCampaign.propTypes = {
  isVisible: PropTypes.bool,
  form: PropTypes.object,
  refreshCampaigns: PropTypes.func,
  campaignToEdit: PropTypes.object,
  onClose: PropTypes.func,
};

CreateCampaign.defaultProps = {
  isVisible: false,
  form: {},
  refreshCampaigns: () => {},
  campaignToEdit: {},
  onClose: () => {},
};

export default Form.create({ name: 'campaign_edit' })(withRouter(CreateCampaign));
