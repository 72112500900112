import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Icon } from 'antd';
import { validateEmail } from '../services/user';
import '../styles/login.less';

class ValidateEmail extends Component {
  state={ loading: true }

  componentDidMount() {
    const { match } = this.props;
    const { token } = match.params;
    validateEmail(token).then(() => {
      this.props.getProfile().finally(() => {
        this.props.history.push('/search/?firstvisit=true');
      });
    }).catch(() => {
      this.setState({ loading: false });
    });
  }

  render() {
    const { loading } = this.state;
    return (
      <div className="Login_global_global">
        <div className="Login_global_wrapper">
          <div className="Login_form_wrapper center">
            {loading ? <Icon type="loading" className="Valide_email_loading_icon" />
              : (
                <div>
                  <div>
                    <Icon type="close-circle" className="Valide_email_error_icon" />
                  </div>
                  <br />
                  <div className="bold">
            Your link has expired
                  </div>

                </div>
              )}
          </div>
        </div>
      </div>
    );
  }
}
ValidateEmail.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
  getProfile: PropTypes.func,
};

ValidateEmail.defaultProps = {
  history: {},
  match: {},
  getProfile: () => {},
};
export default withRouter(ValidateEmail);
