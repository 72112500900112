import React from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import '../styles/loader.less';

function FullScreenLoader({ tip }) {
  return (
    <div className="FullScreenLoader">
      <Spin tip={tip} size="large" />
    </div>
  );
}

FullScreenLoader.propTypes = {
  tip: PropTypes.string,
};

FullScreenLoader.defaultProps = {
  tip: 'Loading...',
};

export default FullScreenLoader;
