import React, { Component } from 'react';
import {
  Form, Icon, Input, Button, message,
} from 'antd';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { forgetPassword } from '../services/user';
import '../styles/global.less';
import '../styles/login.less';

class ForgetPassword extends Component {
  state = { loading: false };

  handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({ loading: true });
        forgetPassword(values.email.toLowerCase())
          .then(() => {
            this.setState({ loading: false });
            message.success(this.props.t('forgotPasswordSuccess'));
          })
          .catch((error) => {
            this.setState({ loading: false });
            if (error && error.response && error.response.status === 404) {
              message.error(this.props.t('errorNoAccountWithThisEmail'));
            } else {
              message.error(this.props.t('errorUnknown'));
            }
          });
      }
    });
  };

  render() {
    const { t } = this.props;
    const { getFieldDecorator } = this.props.form;
    const { loading } = this.state;
    return (
      <div className="Login_global_global">
        <div className="Login_global_wrapper">
          <div className="Login_form_wrapper">
            <div className="Login_form_logo_wrapper">
              <img
                src="img/logo.png"
                className="Login_form_logo"
                alt="Logo tokfluence"
              />
            </div>
            <h2 className="center">{t('forgotPassword')}</h2>
            <Form onSubmit={this.handleSubmit} className="login-form">
              <Form.Item label="Email">
                {getFieldDecorator('email', {
                  rules: [{ required: true, message: t('errorEnterEmail') }],
                })(
                  <Input
                    prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                    placeholder={t('placeholderEmail')}
                    type="email"
                  />,
                )}
              </Form.Item>
              <Form.Item>
                <Button loading={loading} type="primary" htmlType="submit" className="login-form-button">
                  {t('reinitPassword')}
                </Button>
                <a className="Login_register_link" href="/#/login">
                  {t('connect')}
                </a>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}

ForgetPassword.propTypes = {
  t: PropTypes.func,
  form: PropTypes.object,
};

ForgetPassword.defaultProps = {
  t: () => {},
  form: null,
};

export default Form.create({ name: 'forgetPassword' })(
  withTranslation()(withRouter(ForgetPassword)),
);
