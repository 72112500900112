import Axios from './requests';
import { API_URL } from './constants';
import { getCookie } from './utils';

export const getSessionId = () => localStorage.getItem('sessionId');

export const isConnected = () => localStorage.getItem('sessionId')
  && localStorage.getItem('sessionId') != null
  && localStorage.getItem('sessionId') !== 'undefined';

export const login = (email, password) => Axios.post(`${API_URL}/platform-users/login`, { email, password }).then(response => localStorage.setItem('sessionId', response.data.sessionId));

export const register = ({
  email,
  password,
  firstName,
  lastName,
  companyName,
  source,
}) => Axios.post(`${API_URL}/platform-users/register`, {
  email,
  password,
  firstName,
  lastName,
  companyName,
  source: getCookie('tokref') || source,
});

export const logout = redirectHome => Axios.post(`${API_URL}/platform-users/logout`, null, {
  headers: {
    sessionId: getSessionId(),
  },
})
  .then(() => {
    localStorage.removeItem('sessionId');
    localStorage.removeItem('type');
    if (redirectHome) {
      redirectHome();
    }
  })
  .catch(() => {
    localStorage.removeItem('sessionId');
    localStorage.removeItem('type');
    if (redirectHome) {
      redirectHome();
    }
  });

export const getProfile = () => Axios.post(`${API_URL}/platform-users/get-profile`, null, {
  headers: {
    sessionId: getSessionId(),
  },
});

export const getUserId = username => Axios.post(`${API_URL}/platform-users/get-user-by-username`, { username }, {
  headers: {
    sessionId: getSessionId(),
  },
});
export const getUserData = userId => Axios.post(`${API_URL}/platform-users/get-user-by-id`, { userId }, {
  headers: {
    sessionId: getSessionId(),
  },
});

export const refreshUserData = userId => Axios
  .post(`${API_URL}/platform-users/refresh-user`, { userId }, {
    headers: {
      sessionId: getSessionId(),
    },
  })
  .then(() => new Promise(resolve => setTimeout(resolve, 5000)))
  .then(() => getUserData(userId));

export const requestUserData = userId => Axios.post(`${API_URL}/platform-users/request-user`, { userId }, {
  headers: {
    sessionId: getSessionId(),
  },
});

export const forgetPassword = email => Axios.post(`${API_URL}/platform-users/forget-password`, { email });

export const resetPassword = (password, resetPasswordToken) => Axios.post(`${API_URL}/platform-users/reset-password`, { password, resetPasswordToken }).then((data) => {
  localStorage.setItem('sessionId', data.data.sessionId);
});


export const updateInfluencerNote = (influencerId, notes) => Axios.post(
  `${API_URL}/platform-users/influencers/update-notes`, { influencerId, notes }, {
    headers: {
      sessionId: getSessionId(),
    },
  },
);

export const updateBillingAddress = updatedProfile => Axios.post(`${API_URL}/platform-users/update-billing-address`, updatedProfile, {
  headers: { sessionId: getSessionId() },
});

export const subscribe = (
  subscriptionId,
  stripeToken,
  stripePk,
  promoCode,
) => Axios.post(
  `${API_URL}/platform-users/subscriptions/create`,
  {
    subscriptionId,
    stripeToken,
    stripePk,
    promoCode,
  },
  { headers: { sessionId: getSessionId() } },
);

export const checkPromoCode = promoCode => Axios.post(
  `${API_URL}/platform-users/subscriptions/check-promo-code`,
  { promoCode },
  { headers: { sessionId: getSessionId() } },
);

export const getContactInfos = userId => Axios.post(`${API_URL}/platform-users/get-contact-infos`, { userId }, {
  headers: {
    sessionId: getSessionId(),
  },
});

export const getSubscriptionList = () => Promise.resolve({
  data: [
    {
      id: 'startup-annually',
      currency: 'dol',
      price: '2500',
      monthly: false,
      nameSimple: 'STARTUP',
    },
    {
      id: 'startup-monthly',
      currency: 'dol',
      price: '4900',
      monthly: true,
      nameSimple: 'STARTUP',
    },
    {
      id: 'business-v3-annually',
      currency: 'dol',
      price: '6500',
      monthly: false,
      nameSimple: 'BUSINESS_V3',
    },
    {
      id: 'business-v3-monthly',
      currency: 'dol',
      price: '12900',
      monthly: true,
      nameSimple: 'BUSINESS_V3',
    },
    {
      id: 'platinum-annually',
      currency: 'dol',
      price: '15000',
      monthly: false,
      nameSimple: 'PLATINUM',
    },
    {
      id: 'platinum-monthly-fix',
      currency: 'dol',
      price: '29900',
      monthly: true,
      nameSimple: 'PLATINUM',
    },
    {
      id: 'database-monthly',
      currency: 'dol',
      price: '999000',
      monthly: true,
      nameSimple: 'DATABASE',
    },
    {
      id: 'database-yearly',
      currency: 'dol',
      price: '999000',
      monthly: false,
      nameSimple: 'DATABASE',
    },
  ],
});

export const getCreditList = () => Promise.resolve({
  data: [
    {
      id: 'credits-one',
      name: '1 credit',
      description: 'For Influencer reports',
      currency: 'dol',
      price: '300',
      monthly: true,
      quantity: 1,
    },
    {
      id: 'credits-ten',
      name: '10 credits',
      description: 'For Influencer reports',
      currency: 'dol',
      price: '2000',
      monthly: true,
      quantity: 10,

    },
    {
      id: 'credits-fifty',
      name: '50 credits',
      currency: 'dol',
      description: 'For Influencer reports',
      price: '5000',
      monthly: true,
      quantity: 50,
    },
  ],
});
// Axios.get(`${API_URL}/constants/subscriptions`,
//   { headers: { sessionId: getSessionId() } });

export const sendFeedback = goal => Axios.post(
  `${API_URL}/platform-users/update-goal`,
  { goal },
  { headers: { sessionId: getSessionId() } },
);

export const validateEmail = token => Axios.post(
  `${API_URL}/platform-users/validate-email`,
  { emailValidationToken: token },
  { headers: { sessionId: getSessionId() } },
).then(response => localStorage.setItem('sessionId', response.data.sessions[0]));

export const getFollowedBy = userId => Axios.post(
  `${API_URL}/platform-users/get-followed-by`,
  { userId },
  { headers: { sessionId: getSessionId() } },
);

export const upgradePlan = (
  subscriptionId,
  stripePk,
) => Axios.post(
  `${API_URL}/platform-users/subscriptions/upgrade`,
  {
    subscriptionId,
    stripePk,
  },
  { headers: { sessionId: getSessionId() } },
);

export const getTikTokVideos = userId => Axios.post(
  `${API_URL}/platform-users/get-tiktok-videos`,
  { userId },
  { headers: { sessionId: getSessionId() } },
);
export const createCustomerPortal = () => Axios.post(
  `${API_URL}/platform-users/subscriptions/create-customer-portal-session`,
  null,
  { headers: { sessionId: getSessionId() } },
);
