/* eslint-disable react/jsx-closing-tag-location */
import React from 'react';
import PropTypes from 'prop-types';
import {
  getUpgradeButton,
} from '../services/utils';
import '../styles/upgradePanel.less';

const UpgradePanel = ({
  title, description, actions, displayPricing,
}) => (
  <>
    <div className="Search_free_plan_warning">
      <div className="Search_upgrade_wrapper">
        <div className="center">
          <h1>{title}</h1>
          <div>
            {description}
            {displayPricing && (
            <>
                      Checkout our pricing page here:
              {' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://tokfluence.com/pricing"
              >
Pricing page
              </a>
.
              <div>Please contact us at contact@tokfluence.com for any questions.</div>
            </>
            )}
          </div>
          <div style={{ marginTop: '8px' }}>
            {actions}

          </div>

        </div>
      </div>

    </div>
  </>
);

UpgradePanel.propTypes = {
  title: PropTypes.string,
  description: PropTypes.object,
  actions: PropTypes.object,
  displayPricing: PropTypes.bool,
};

UpgradePanel.defaultProps = {
  title: 'UPGRADE',
  description: <b>Only the first 5 results are available with a Free plan. </b>,
  displayPricing: true,
  actions: <>
    {getUpgradeButton({ text: 'Upgrade', icon: 'rocket' })}
  </>,
};

export default UpgradePanel;
