/* eslint-disable max-len */
/* eslint-disable consistent-return */
import React, { Component } from 'react';
import {
  Drawer, Divider, Col, Row, Form, Icon, Input, Button, message, Select,
} from 'antd';
import PropTypes from 'prop-types';
import { updateBillingAddress } from '../services/user';
import { COUNTRIES } from '../services/country';
import Loader from './loader';
import '../styles/editProfile.less';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

class EditProfile extends Component {
  state = { loading: false, country: false };

  constructor(props) {
    super(props);
    this.InputRef = React.createRef();
  }

  static getDerivedStateFromProps(props, state) {
    if (
      props.profile
      && props.profile.billingAddress
      && props.profile.billingAddress.country
      && !state.country
    ) {
      return {
        ...state,
        country: COUNTRIES.find(c => c.countryCode === props.profile.billingAddress.country),
      };
    }
    return state;
  }

  handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const updatedProfile = {
          name: values.name || undefined,
          street: values.street || undefined,
          postalCode: values.postalCode || undefined,
          city: values.city || undefined,
          country: values.country || undefined,
          state: values.state || undefined,
          vatNumber: values.vatNumber || undefined,
        };
        this.setState({ loading: true });
        return updateBillingAddress(updatedProfile)
          .then(() => {
            message.success('Save success !');
            this.props.close();
          })
          .catch((error) => {
            if (error.response.data.error.message === 'VAT number is not valid') {
              message.error('The VAT-ID number is not valid.');
            } else {
              message.error('Error while saving');
            }
          })
          .finally(() => this.setState({ loading: false }));
      }
    });
  };

  onChangeCountry = (countryCode) => {
    const country = COUNTRIES.find(c => c.countryCode === countryCode);
    this.setState({ country });
  };

  render() {
    const {
      isVisible, close, profile,
    } = this.props;
    const { getFieldDecorator } = this.props.form;
    const { loading, country } = this.state;
    return (
      <Drawer
        placement="right"
        width={600}
        onClose={close}
        visible={isVisible}
        className="Edit_profile_wrapper Subscription_modal"
      >
        <h1>My profile</h1>
        <Row>
          <Col span={24}>
            <Form {...formItemLayout} onSubmit={this.handleSubmit}>
              <Form.Item label="Email">
                {getFieldDecorator('email', { initialValue: profile.email })(
                  <Input
                    prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
                    type="text"
                    placeholder="Email"
                    disabled
                  />,
                )}
              </Form.Item>
              <Form.Item label="Name or company">
                {getFieldDecorator('name', {
                  initialValue: profile.billingAddress ? profile.billingAddress.name : undefined,
                  rules: [
                    {
                      required: true,
                      message: 'Fill in your first name and your name or the name of the company',
                    },
                  ],
                })(
                  <Input
                    prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                    type="text"
                    placeholder="Name or company"
                  />,
                )}
              </Form.Item>
              <Form.Item label="Street">
                {getFieldDecorator('street', {
                  initialValue: profile.billingAddress ? profile.billingAddress.street : undefined,
                  rules: [
                    {
                      required: true,
                      message: 'Fill in a Street',
                    },
                  ],
                })(
                  <Input
                    prefix={<Icon type="environment" style={{ color: 'rgba(0,0,0,.25)' }} />}
                    type="text"
                    placeholder="Street"
                  />,
                )}
              </Form.Item>
              <Form.Item label="Postal code">
                {getFieldDecorator('postalCode', {
                  initialValue: profile.billingAddress
                    ? profile.billingAddress.postalCode
                    : undefined,
                  rules: [
                    {
                      required: true,
                      message: 'Fill in a postal code',
                    },
                  ],
                })(
                  <Input
                    prefix={<Icon type="environment" style={{ color: 'rgba(0,0,0,.25)' }} />}
                    type="text"
                    placeholder="Postal code"
                  />,
                )}
              </Form.Item>
              <Form.Item label="City">
                {getFieldDecorator('city', {
                  initialValue: profile.billingAddress ? profile.billingAddress.city : undefined,
                  rules: [
                    {
                      required: true,
                      message: 'Fill in a city',
                    },
                  ],
                })(
                  <Input
                    prefix={<Icon type="environment" style={{ color: 'rgba(0,0,0,.25)' }} />}
                    type="text"
                    placeholder="City"
                  />,
                )}
              </Form.Item>

              <Form.Item label="Country">
                {getFieldDecorator('country', {
                  rules: [
                    {
                      required: true,
                      message: 'Fill in a country ',
                    },
                  ],
                  initialValue: profile.billingAddress ? profile.billingAddress.country : undefined,
                })(
                  <Select
                    onChange={this.onChangeCountry}
                    showSearch
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {COUNTRIES.map(c => (
                      <Select.Option key={c.countryCode} value={c.countryCode}>
                        {c.name}
                      </Select.Option>
                    ))}
                  </Select>,
                )}
              </Form.Item>

              {country && country.states && country.states.length > 0 && (
                <Form.Item label="State">
                  {getFieldDecorator('state', {
                    rules: [
                      {
                        required: true,
                        message: 'Fill in a state',
                      },
                    ],
                    initialValue: profile.billingAddress ? profile.billingAddress.state : undefined,
                  })(
                    <Select
                      showSearch
                      filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {country.states.map(s => (
                        <Select.Option key={s.short} value={s.short}>
                          {s.name}
                        </Select.Option>
                      ))}
                    </Select>,
                  )}
                </Form.Item>
              )}
              {/* <Form.Item label="VAT-ID (optional)">
                {getFieldDecorator('vatNumber', {
                  initialValue: profile.billingAddress
                    ? profile.billingAddress.vatNumber
                    : undefined,
                })(
                  <Input
                    prefix={<Icon type="euro" style={{ color: 'rgba(0,0,0,.25)' }} />}
                    type="text"
                    placeholder="VAT-ID (optional)"
                  />,
                )}
              </Form.Item> */}

              <Divider />
              <div className="flex_edit">
                <Button type="secondary" onClick={close} className="Edit_profile_button">
                  Cancel
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="Edit_profile_button Edit_profile_button_save"
                >
                  Save
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
        {loading && <Loader />}
      </Drawer>
    );
  }
}

EditProfile.propTypes = {
  isVisible: PropTypes.bool,
  close: PropTypes.func,
  profile: PropTypes.object,
  form: PropTypes.object,
};

EditProfile.defaultProps = {
  isVisible: false,
  close: null,
  profile: {},
  form: null,
};

export default Form.create({ name: 'editProfile' })(EditProfile);
