/* eslint-disable max-len */
/* eslint-disable react/no-unescaped-entities */
import React, { Component } from 'react';
import {
  Icon, Avatar, Skeleton, Tooltip,
  Statistic, Divider, message, Dropdown, Menu, Badge,
} from 'antd';
import flag from 'country-code-emoji';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import {
  Doughnut, HorizontalBar, Bar, Line,
} from 'react-chartjs-2';
import Moment from 'moment';
import Header from '../../components/header';
// import TKIcon from '../../components/tkIcon';
// import NumberCountBadge from '../../components/numberCountBadge';
import { getCountryNameFromCode } from '../../services/country';
import Loader from '../../components/loader';
import 'chartjs-plugin-datalabels';

import {
  isConnected,
} from '../../services/user';
import { getReportById, displayModalConfirm, askReport } from '../../services/reports';
import {
  getCountLabel,
  getCountLabelInteger,
} from '../../services/utils';
import '../../styles/global.less';
import '../../styles/reports.less';

const ReportAverageStat = ({ value, comparedValue, label }) => {
  const comparedPercent = comparedValue ? ((value / comparedValue * 100) - (100)).toFixed(0) : 0;
  return (
    <div className="Report_average_stats center">
      <Statistic title={label} value={getCountLabel(value)} />

      {(comparedValue && comparedPercent !== 0) ? (
        <div className="Report_average_stats_compared center">
          <div className="Report_average_stats_compared_value">
            <Tooltip title="Compared to similar influencers" placement="bottom">
              <Badge
                count={`${value >= comparedValue ? '+' : ''}
    ${comparedPercent}
    %`}
                className="title"
                style={{
                  backgroundColor: value > comparedValue
                    ? '#52c41a' : 'red',
                }}
              />
            </Tooltip>
            <div className="Report_average_stats_compared_subtitle">
      Compared to similar influencers
            </div>

          </div>
        </div>
      ) : <></>}
    </div>
  );
};

ReportAverageStat.propTypes = {
  value: PropTypes.number,
  comparedValue: PropTypes.number,
  label: PropTypes.string,
};

ReportAverageStat.defaultProps = {
  value: 0,
  comparedValue: null,
  label: '',
};


class ReportId extends Component {
  state= {
    loading: false, report: null, askReportLoading: false,
  }

  LINE_DATA = {
    legend: {
      display: false,
    },
    fill: true,
    lineTension: 0.5,
    lineJoin: 'round',
    backgroundColor: 'rgb(251, 2, 84,0.3)',
    borderColor: '#fb0154',
    borderCapStyle: 'butt',
    borderDash: [],
    borderJoinStyle: 'miter',
    pointBorderColor: '#fb0154',
    pointBackgroundColor: '#fff',
    pointBorderWidth: 1,
    pointHoverRadius: 5,
    pointHoverBackgroundColor: '#fb0154',
    pointHoverBorderColor: 'rgba(220,220,220,1)',
    pointHoverBorderWidth: 2,
    pointRadius: 1,
    pointHitRadius: 10,
  };

  constructor(props) {
    super(props);
    if (!isConnected()) {
      this.props.history.push(`/login?next=reports/${(props.match.params && props.match.params.id)
        ? props.match.params.id : ''}`);
    }
  }

  componentDidMount() {
    if (this.props.match && this.props.match.params.id && this.props.match.params.id.length > 0) {
      this.getReportData();
    } else {
      this.props.history.push('/reports');
    }
  }

  getReportData = () => {
    this.setState({ loading: true });
    getReportById(this.props.match.params.id)
      .then(res => this.setState({
        report: res.data,
      }))
      .catch((e) => {
        console.error(e);
        if (e.response.status === 423) {
          this.props.history.push('/reports');
        } else {
          message.error('Error while loading the report');
        }
      }).finally(() => this.setState({ loading: false }));
  }

  getAudienceReachabilityLabel = () => {
    const { report } = this.state;
    const value = 100 - report.massFollowingRate;
    if (value >= 90) {
      return (
        <span style={{ color: '#49de8c' }}>
Excellent
        </span>
      );
    } if (value >= 80) {
      return (
        <span style={{ color: '#36ecb2' }}>
Very Good
        </span>
      );
    } if (value >= 70) {
      return (
        <span style={{ color: '#00ca89' }}>
Good
        </span>
      );
    } if (value >= 60) {
      return (
        <span style={{ color: '#bd7c03' }}>
Poor
        </span>
      );
    }
    if (value >= 50) {
      return (
        <span style={{ color: '#bd7c03' }}>
Very Poor
        </span>
      );
    }
    if (value >= 50) {
      return (
        <span style={{ color: '#bd7c03' }}>
Bad
        </span>
      );
    }
    return (
      <span style={{ color: 'red' }}>
Very Bad
      </span>
    );
  }

  getPotentialReachLikeForAccount = account => (
    account.followerCount * (account.globalEngagementRate / 100))

  getPotentialReachViewsForAccount = account => this.getPotentialReachLikeForAccount(account) * 5

  getEstimatedCostForAccountLower = account => (
    (this.getPotentialReachViewsForAccount(account) / 1000) * 4).toFixed(0)

  getEstimatedCostForAccountHigher = account => (
    (this.getPotentialReachViewsForAccount(account) / 1000) * 6).toFixed(0)

    refreshReport = () => {
      const { profile } = this.props;
      const { report } = this.state;
      displayModalConfirm({
        credits: profile.audienceAnalysisCredits,
        onOk: () => {
          this.setState({ askReportLoading: true });
          askReport(report.userData._id).then(() => {
            message.success('Report asked, you\'ll receive an email when it\'s ready.');
          }).catch((e) => {
            console.error(e);
            message.error('Error while asking for the report');
          }).finally(() => {
            this.setState({ askReportLoading: false });
          });
        },
      });
    }

    downloadPDF = () => window.print();

    render() {
      const {
        report, loading, askReportLoading,
      } = this.state;

      const { profile } = this.props;


      if (!report || loading) {
        return (
          <div>
            <Header />
            <div className="div-wrapper">
              <div className="container center">
                <div className="Report_header_wrapper">
                  <Skeleton avatar paragraph={{ rows: 14 }} active />
                </div>
              </div>
            </div>
          </div>
        );
      }
      const account = report ? report.userData : null;

      const genderData = {
        labels: [
          `Women ${report.gender.female.toFixed(0)}%`,
          `Men ${report.gender.male.toFixed(0)}%`,
        ],
        datasets: [{
          data: [report.gender.female.toFixed(0), report.gender.male.toFixed(0)],
          backgroundColor: [
            '#FF6384',
            '#36A2EB',
          ],
          hoverBackgroundColor: [
            '#FF6384',
            '#36A2EB',
          ],
        }],
      };

      const regions = report.regions.slice(0, 5);
      const regionLabels = regions.map(r => `${(getCountryNameFromCode(r.region)).name} - ${r.percentage.toFixed(0)}%`);
      const regionValues = regions.map(r => r.percentage.toFixed(0));

      const countryData = {
        labels: regionLabels,
        datasets: [
          {
            label: 'Countries',
            backgroundColor: '#6f70fd',
            borderColor: '#6f70fd',
            borderWidth: 1,
            hoverBackgroundColor: '#6f70fd',
            hoverBorderColor: '#6f70fd',
            data: regionValues,
          },
        ],
      };

      const genderAgeLabel = Object.keys(report.age).slice(0, 7);
      const genderAgeValuesMale = Object.values(report.age).slice(0, 7).map(r => (r.male || 0).toFixed(0));
      const genderAgeValuesFemale = Object.values(report.age).slice(0, 7)
        .map(r => (r.female || 0).toFixed(0));
      const genderAgeData = {
        labels: genderAgeLabel,
        datasets: [
          {
            label: 'Male',
            backgroundColor: '#36A2EB',
            borderColor: '#36A2EB',
            borderWidth: 1,
            hoverBackgroundColor: '#36A2EB',
            hoverBorderColor: '#36A2EB',
            data: genderAgeValuesMale,
          },
          {
            label: 'Female',
            backgroundColor: '#FF6384',
            borderColor: '#FF6384',
            borderWidth: 1,
            hoverBackgroundColor: '#FF6384',
            hoverBorderColor: '#FF6384',
            data: genderAgeValuesFemale,
          },
        ],
      };

      const followerHistory = report.countHistory
        .filter(c => c.type === 'FOLLOWER')
        .sort((a, b) => (new Date(a.createdAt) - new Date(b.createdAt)));

      const dataFollowers = {
        datasets: [
          {
            ...this.LINE_DATA,
            label: 'Followers',
            legend: {
              display: false,
            },
            data: followerHistory.map(e => ({ t: new Date(e.createdAt), y: e.value })),
          },
        ],
      };

      return (
        <div className="Report_page">
          <Header profile={profile} />
          {report && (
          <div>
            <div className="credits_tk">
This report was issued by
              <img
                style={{
                  width: '20px',
                  marginLeft: '8px',
                  marginRight: '6px',
                }}
                src="favicon.png"
                alt="tokfluence"
              />
Tokfluence.com on
              {' '}
              {` ${new Moment(new Date(report.updatedAt)).format('MM/DD/YYYY')} `}
            </div>

            <div className="div-wrapper">
              <div className="container center">
                <div className="Report_header_wrapper">
                  <div className="container">
                    <div className="No_print right Report_action_wrapper">
                      <div className="Report_date">
                       Report generated on
                        {' '}
                        {` ${new Moment(new Date(report.updatedAt)).format('MM/DD/YYYY')} `}
                      </div>
                      <div className="Report_actions">
                        <Dropdown.Button
                          onClick={() => this.downloadPDF()
              }
                          overlay={(
                            <Menu>
                              <Menu.Item
                                key="1"
                                onClick={this.refreshReport}
                              >
                    Refresh report
                                {' '}
                                <span className="title">
                                  <Icon type="radar-chart" />
1
                                </span>

                              </Menu.Item>
                            </Menu>
  )}
                          className="Header_connect_button"
                        >
                          {/* <Icon type="setting" /> */}
                          <Icon type="download" />
                          {' '}
Export PDF
                        </Dropdown.Button>
                      </div>

                    </div>
                    <div className="Report_header_inner">
                      <div className="Report_header_inner_content">
                        <div className="Report_header_pic">
                          {account.profilePicUrl ? (
                            <Avatar size={80} src={account.profilePicUrl} />
                          ) : (
                            <Avatar size={80} icon="user" />
                          )}
                        </div>
                        <div className="Report_header_name">
                          <h1 className="bold">
                            {account.fullName}
                            {account.verification
                      && account.verification.web === true
                      && <img src="img/verified.png" alt="verified" className="Verified_logo_search" />}
                          </h1>
                          <div>
                    @
                            {account.username}
                          </div>
                          <div style={{ marginTop: '8px' }}>
                            <div className="Report_header_region_wrap">
                              <Icon type="environment" />
                              {' '}
                              {(getCountryNameFromCode(account.region) || {}).name}
                            </div>
                          </div>
                          {/* <div>
                            <div
                              className="Profile_header_badge_wrapper"
                            >
                              <NumberCountBadge icon={<TKIcon />} color="black" number={getCountLabel(account.followerCount)} />
                              {account.instagramProfile && account.instagramProfile.followerCount && <NumberCountBadge icon={<Icon type="instagram" />} color="#5ab0ff" number={getCountLabel(account.instagramProfile.followerCount)} />}
                              {account.youtubeChannel && account.youtubeChannel.subscribers && <NumberCountBadge icon={<Icon type="youtube" />} color="#f4252f" number={getCountLabel(account.youtubeChannel.subscribers)} />}
                            </div>
                          </div> */}
                        </div>
                      </div>

                      <div className="Report_global_er">
                        <div className="flex">
                          <Statistic title="Followers" value={getCountLabel(report.userData.followerCount)} />
                          <Statistic title="Engagement Rate" value={`${report.userData.globalEngagementRate.toFixed(2)}%`} />
                        </div>
                      </div>
                    </div>
                  </div>


                </div>
                <div className="Rank_global">
                  <div className="flex">
                    <div className="Rank_container">
                      <div className="Rank_wrapper">
                        <div className="Rank_inner">
                          <div className="Rank_value title light">{report.worldRank + 1}</div>
                          <div className="Rank_title title bold">Global Rank</div>
                          <div className="Rank_subtitle">Worldwide</div>
                        </div>
                        <div className="Rank_tokfluence  title bold">
                          <img
                            style={{
                              width: '14px',
                              marginLeft: '8px',
                              marginRight: '6px',
                            }}
                            src="favicon.png"
                            alt="tokfluence"
                          />
Tokfluence
                        </div>
                      </div>
                      <div className="Rank_wrapper Country_rank_wrapper">
                        <div className="Rank_inner">
                          <div className="Rank_value title light">{report.countryRank + 1}</div>
                          <div className="Rank_title title bold">Country Rank</div>
                          <div className="Rank_subtitle">
                            {flag(account.region)}
                            {' '}
                            {(getCountryNameFromCode(account.region) || {}).name}
                          </div>
                        </div>
                        <div className="Rank_tokfluence  title bold">
                          <img
                            style={{
                              width: '14px',
                              marginLeft: '8px',
                              marginRight: '6px',
                            }}
                            src="favicon.png"
                            alt="tokfluence"
                          />
Tokfluence
                        </div>
                      </div>


                    </div>
                  </div>
                </div>


                <div className="Report_content_wrapper">
                  <div className="container">
                    <div className="Report_audience_content">
                      <h1 className="bold">
                        <Icon type="team" />
                        {' '}
Audience
                      </h1>
                      <div className="Report_audience_inner">
                        <div className="flex">
                          <div className="flex50">
                            <h2>
                              <Icon type="flag" />
                              {' '}
Countries
                            </h2>
                            <HorizontalBar
                              data={countryData}
                              options={
                            {
                              legend: {
                                display: false,
                              },
                              plugins: {
                                datalabels: {
                                  display: true,
                                  color: 'white',
                                },
                              },
                            }
                             }
                            />
                          </div>
                          <div className="flex50">
                            <h2>
                              <Icon type="man" />
                              <Icon type="woman" />
                              {' '}
Gender
                            </h2>
                            <Doughnut
                              data={genderData}
                              options={{
                                plugins: {
                                  datalabels: {
                                    display: true,
                                    color: 'white',
                                  },
                                },
                              }}
                            />
                          </div>

                        </div>
                        <div className="padding12">
                          <h2>
                            <Icon type="user" />
                            {' '}
Age & Gender
                          </h2>
                          <br />
                          {report && report.age && report.age.adults && (
                          <div className="Report_adults title ">
                            {'Adults: '}
                            {' '}
                            <span className="bold">
                              {`${report.age.adults.toFixed(0)}% `}
                            </span>
                            <Tooltip
                              title="People with 21+ years old"
                            >
                              <Icon type="info-circle" />
                            </Tooltip>

                          </div>
                          )}
                          <br />
                          <div>
                            <Bar
                              data={genderAgeData}
                              options={{
                                maintainAspectRatio: false,
                                plugins: {
                                  datalabels: {
                                    display: true,
                                    color: 'white',
                                  },
                                },
                              }}
                            />
                          </div>
                        </div>
                      </div>

                    </div>
                    <br />
                    <br />
                    <div className="flex bold flex">
                      <div className="flex50 right">
                        <div className="AudienceReachability_title title">
                    Audience Reachability:
                        </div>
                      </div>
                      <div className="left flex50 AudienceReachability_label title">
                        <div className="bold">
                          {this.getAudienceReachabilityLabel()}
                          {' '}
                          <span className="grey" style={{ fontSize: '13px' }}>
                            <Tooltip
                              title="Audience who follow less than 1,500 accounts are considered reachable. They probably see most of the influencer’s posts."
                            >
                              <Icon type="info-circle" />
                            </Tooltip>
                          </span>
                        </div>
                        <div className="grey" style={{ fontSize: '14px', fontWeight: '400' }}>
                          {(100 - report.massFollowingRate).toFixed(1)}
% of the audience have less than 1,500 followings.
                        </div>

                      </div>
                    </div>
                    <br />
                    <Divider />
                    <br />
                    <div className="left">
                      <h1>
                        <Icon type="like" />
                        {' '}
Performance
                      </h1>
                      <br />
                      <div className="flex Report_engagement_numbers center">
                        {account.averageViewsPerVideo && report.similarInfluencers && (
                        <ReportAverageStat
                          value={account.averageViewsPerVideo}
                          comparedValue={report.similarInfluencers.averageViewsPerVideo}
                          label="Average Views"
                        />
                        )}
                        {account.averageLikesPerVideo && report.similarInfluencers && (
                        <ReportAverageStat
                          value={account.averageLikesPerVideo}
                          comparedValue={report.similarInfluencers.averageLikesPerVideo}
                          label="Average Likes"
                        />
                        )}
                        {account.averageCommentsPerVideo && report.similarInfluencers && (
                        <ReportAverageStat
                          value={account.averageCommentsPerVideo}
                          comparedValue={report.similarInfluencers.averageCommentsPerVideo}
                          label="Average Comments"
                        />
                        )}
                        {account.averageSharePerVideo && report.similarInfluencers && (
                        <ReportAverageStat
                          value={account.averageSharePerVideo}
                          comparedValue={report.similarInfluencers.averageSharePerVideo}
                          label="Average Shares"
                        />
                        )}

                      </div>

                      <br />
                      <div className="flex bold flex">
                        <div className="right flex50">
                          <div className="AudienceReachability_title title">
                 Posts per week:
                          </div>
                        </div>
                        <div className="left flex50 AudienceReachability_label title">
                          <div className="bold">
                            {`${(report.userData.postsPerWeek && report.userData.postsPerWeek < 1) ? report.userData.postsPerWeek.toFixed(1) : (report.userData.postsPerWeek || 0).toFixed(0)}`}
                          </div>
                          <div className="grey" style={{ fontSize: '14px', fontWeight: '400' }}>
                        Average number of posts per week.
                            {(report.similarInfluencers && report.similarInfluencers.postsPerWeek) && (
                            <div>
                              <Tooltip title="Compared to similar influencers" placement="bottom">
                                <Badge
                                  count={`${report.userData.postsPerWeek > report.similarInfluencers.postsPerWeek ? '+' : ''}
                              ${((report.userData.postsPerWeek / report.similarInfluencers.postsPerWeek * 100)
                                - (100)).toFixed(0)}
                              %`}
                                  className="title"
                                  style={{
                                    backgroundColor: report.userData.postsPerWeek > report.similarInfluencers.postsPerWeek
                                      ? '#52c41a' : 'red',
                                  }}
                                />
                              </Tooltip>
                              {' '}
compared to similar influencers (
                              {report.similarInfluencers.postsPerWeek < 1
                                ? report.similarInfluencers.postsPerWeek.toFixed(1)
                                : report.similarInfluencers.postsPerWeek.toFixed(0)}
).
                            </div>
                            )}
                          </div>

                        </div>
                      </div>
                      <br />
                      <div className="flex bold flex">
                        <div className="right flex50">
                          <div className="AudienceReachability_title title">
                   Engagement Rate:
                          </div>
                        </div>
                        <div className="left flex50 AudienceReachability_label title">
                          <div className="bold">
                            {`${report.userData.globalEngagementRate.toFixed(2)}%`}
                          </div>
                          <div className="grey" style={{ fontSize: '14px', fontWeight: '400' }}>
                        Percent of the audience who engage with the content.
                            {(report.similarInfluencers && report.similarInfluencers.globalEngagementRate) && (
                            <div>
                              <Tooltip title="Compared to similar influencers" placement="bottom">
                                <Badge
                                  count={`${report.userData.globalEngagementRate > report.similarInfluencers.globalEngagementRate ? '+' : ''}
                              ${((report.userData.globalEngagementRate / report.similarInfluencers.globalEngagementRate * 100)
                                - (100)).toFixed(0)}
                              %`}
                                  className="title"
                                  style={{
                                    backgroundColor: report.userData.globalEngagementRate > report.similarInfluencers.globalEngagementRate
                                      ? '#52c41a' : 'red',
                                  }}
                                />
                              </Tooltip>
                              {' '}
compared to similar influencers (
                              {report.similarInfluencers.globalEngagementRate.toFixed(2)}
%
).
                            </div>
                            )}
                          </div>

                        </div>
                      </div>

                      <br />
                      <div className="flex bold flex">
                        <div className="right flex50">
                          <div className="AudienceReachability_title title">
                   Estimated price:
                          </div>
                        </div>
                        <div className="left flex50 AudienceReachability_label title">
                          {account.followerCount > 10000000
                            ? (
                              <>
                                <div className="bold">
                                  <Icon type="close-circle" />
                                  {' '}
Not available
                                </div>
                                <div className="grey" style={{ fontSize: '14px', fontWeight: '400' }}>
                             This account has more than 10M
                             followers so Tokfluence can't properly estimate its price.
                                </div>
                              </>
                            )
                            : (
                              <>

                                <div className="bold">
                        $
                                  {getCountLabel(
                                    this.getEstimatedCostForAccountLower(report.userData),
                                  )}
                                  {' '}
- $
                                  {getCountLabel(
                                    this.getEstimatedCostForAccountHigher(report.userData),
                                  )}
                                </div>
                                <div className="grey" style={{ fontSize: '14px', fontWeight: '400' }}>
                      Based on Tiktok ads pricing for CPM
                                </div>
                              </>
                            )}
                        </div>
                      </div>
                    </div>

                    <Divider />

                    <div className="left">
                      <h1>
                        <Icon type="stock" />
                        {' '}
Growth
                      </h1>
                      <div>
                        <br />
                        <div className="flex bold flex">
                          <div className="right flex50">
                            <div className="AudienceReachability_title title">
                   Followers:
                            </div>
                          </div>
                          <div className="left flex50 AudienceReachability_label title">
                            <div className="bold">
                              {getCountLabel(account.followerCount)}
                            </div>
                            <div className="grey" style={{ fontSize: '14px', fontWeight: '400' }}>
                      TikTok followers
                            </div>

                          </div>
                        </div>
                        <div>
                          <h2 className="left">Followers evolution</h2>
                          <Line
                            data={dataFollowers}
                            options={
                            {
                              plugins: {
                                datalabels: {
                                  display: false,
                                },
                              },
                              legend: {
                                display: false,
                              },
                              scales: {
                                yAxes: [{
                                  ticks: {
                                    beginAtZero: false,
                                    callback(value) {
                                      return getCountLabelInteger(value);
                                    },
                                  },
                                }],
                                xAxes: [
                                  {
                                    type: 'time',
                                  },
                                ],
                              },
                            }
                          }
                          />
                        </div>
                      </div>
                    </div>

                    <br />


                  </div>
                </div>
              </div>
            </div>
          </div>
          )}
          {askReportLoading && <Loader />}
        </div>
      );
    }
}

ReportId.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
  profile: PropTypes.object,
};

ReportId.defaultProps = {
  match: {},
  history: {},
  profile: {},
};


export default withRouter(ReportId);
