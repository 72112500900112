/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import {
  Empty,
  Avatar,
  Tag,
  Icon,
  Divider,
  Select,
  Menu,
  Dropdown,
  Button,
  Form,
  Input,
  Tooltip,
  message,
  Modal,
  Statistic,
  Radio,
} from 'antd';

import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import VirtualList from 'react-virtual-list';
import ViewReportButton from '../../components/viewReportButton';
import { getCountryNameFromCode } from '../../services/country';
import AudienceReportButton from '../../components/audienceReportButton';
import {
  RemoveInfluencerFromCampaign,
  updateInfluencerInCampaign,
  getDownloadListURL,
} from '../../services/campaigns';
import { STAGES, FEATURES } from '../../services/constants';
import {
  getCountLabelInteger,
  isAuthorized,
  getUpgradeButton,
  getCountLabel,
  getAlertStarter,
  getTotalCreatorsFromStageObject,
  parseSearch,
  stringifySearch,
} from '../../services/utils';
import '../../styles/creators.less';

const { TextArea } = Input;
const { Option } = Select;

const MyList = (properties) => {
  const {
    virtual,
    itemHeight,
    state,
    props,
    saveComment,
    editComment,
    onCancelComment,
    onChangeStatus,
    openRemoveInfluencerConfirm,
  } = properties;
  return (
    <ul
      className="Campaign_creator_list"
      loading={state.loading}
      itemLayout="vertical"
      style={virtual.style}
      size="large"
    >
      {virtual.items.map(item => (
        <li
          className={`Campaign_creator_item ${props.campaignDetails && props.campaignDetails.archived ? 'disabled' : ''}`}
          key={`${item._id}_${item.stage}`}
          disabled={(props.campaignDetails && props.campaignDetails.archived)}
          style={{ height: itemHeight - 12 }}
        >
          <div className="Campaign_creator_item_name_wrapper">
            <div>
              <Avatar size={60} src={item.profilePicUrl} />
            </div>
            <div className="Campaign_creator_item_name">
              <Tooltip
                placement="top"
                title={(
                  <span>
                    <Icon type="file-text" />
                    Click here to open user profile
                  </span>
                )}
              >
                <h3>
                  <span>
                    <a onClick={() => props.openAccountPreviewModale(item._id)}>
                      {item.username}
                    </a>
                    {' '}
                    {item.verified && <img src="img/verified.png" alt="verified" className="Verified_logo_search" />}
                  </span>
                </h3>
              </Tooltip>

              <div className="Campaign_account_attributes">
                {item.region && (
                  <div>
                    <div className="Campagin_account_region">
                      <Icon type="environment" />
                      {' '}
                      {(getCountryNameFromCode(item.region) || {}).name}
                    </div>
                  </div>

                )}
                {item.emails && item.emails.length > 0 && (
                  <div style={{ marginLeft: '4px' }}>
                    <Tooltip title="Click here to see the contact information">
                      <a onClick={() => props.openAccountPreviewModale(item._id)}>
                        {' '}
                        <Tag color="green"><Icon type="mail" /></Tag>
                      </a>
                    </Tooltip>

                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="Campaign_creator_comment_wrapper">
            <Form onSubmit={saveComment} className="Campaign_creator_comment">
              <Form.Item>
                {props.form.getFieldDecorator(`comment_${props.match.params.id}_${item._id}`, { initialValue: item.comment })(
                  <TextArea
                    className="Campaign_creator_comment_input"
                    autoSize={{ minRows: 2, maxRows: 2 }}
                    placeholder="No comment on this influencer for this campaign."
                    allowClear
                    disabled={state.editComment !== item._id
                    || (props.campaignDetails && props.campaignDetails.archived)}
                  />,
                )}
              </Form.Item>
              {props.campaignDetails && !props.campaignDetails.archived && (
                <div className="Campaign_creator_comment_actions">
                  {state.editComment !== item._id
                    ? (
                      <a onClick={() => editComment(item._id)}>
                        <Icon type="edit" />
                        {' '}
                        Edit
                      </a>
                    )
                    : (
                      <>
                        <a onClick={() => onCancelComment(item._id)}>
                          <Icon type="close-circle" />
                          {' '}
                          Cancel
                        </a>
                        &nbsp;-&nbsp;
                        <a onClick={saveComment}>
                          <Icon type="save" />
                          {' '}
                          Save
                        </a>
                      </>
                    )}
                </div>
              )}
            </Form>
          </div>

          <div className="Campaign_extra">
            <div className="Campaign_counts">
              <div className="Campaign_count_item">
                <Tooltip
                  placement="top"
                  title={(
                    <div>
                      Total videos
                    </div>
                  )}
                >
                  <Icon type="video-camera" />
                  {' '}
                  <b>{getCountLabelInteger(item.videosCount)}</b>
                </Tooltip>


              </div>
              <Divider type="vertical" />
              <div className="Campaign_count_item">
                <Tooltip
                  placement="top"
                  title={(
                    <div>
                      Total likes
                    </div>
                  )}
                >
                  <Icon type="heart" />
                  {' '}
                  <b>{getCountLabelInteger(item.likesCount)}</b>
                </Tooltip>
              </div>
              <Divider type="vertical" />
              <div className="Campaign_count_item">
                <Tooltip
                  placement="top"
                  title={(
                    <div>
                      Engagement Rate
                    </div>
                  )}
                >
                  <Icon type="like" />
                  {' '}
                  <b>
                    {getCountLabelInteger(item.globalEngagementRate)}
                    {' '}
                    ER
                  </b>
                </Tooltip>

              </div>
              <Divider type="vertical" />
              <div className="Campaign_count_item">
                <Tooltip
                  placement="top"
                  title={(
                    <div>
                      TikTok Followers
                    </div>
                  )}
                >
                  <Icon type="team" />
                  {' '}
                  <b>{getCountLabelInteger(item.followerCount)}</b>
                </Tooltip>


              </div>
            </div>
            <div className="flex" style={{ flexWrap: 'wrap', justifyContent: 'flex-end' }}>
              <div className="Campaign_audience_report_button_wrapper">
                {(item.report && item.report.length > 0)
                  ? <ViewReportButton reportId={item.report} />
                  : (
                    <AudienceReportButton
                      profile={props.profile}
                      account={item}
                      getProfile={props.getProfile}
                    />
                  )}
              </div>

              <div className="Select_campaign_status_wrapper">
                <Select
                  disabled={props.campaignDetails
                    ? props.campaignDetails.archived : true}
                  key={`${item._id}`}
                  defaultValue={item.stage}
                  className={`Select_campaign_status Select_${item.stage}`}
                  loading={state.statusChangeLoading}
                  onChange={e => onChangeStatus(item._id, e)}
                >
                  {Object.keys(STAGES).map(s => <Option key={s} value={s} className={`${s}`}>{s}</Option>)}
                </Select>
              </div>
            </div>


          </div>
          <div className="Campaign_element_option_actions">
            <Dropdown
              disabled={props.campaignDetails
                ? props.campaignDetails.archived : true}
              trigger={['click']}
              overlay={(
                <Menu>
                  <Menu.Item>
                    <a onClick={() => openRemoveInfluencerConfirm(item._id)}>
                      <Icon type="delete" />
                      {' '}
                      Remove from campaign
                    </a>
                  </Menu.Item>
                </Menu>
              )}
            >
              <Icon type="more" className="Icon_options_campaign" />
            </Dropdown>
          </div>
        </li>
      ))}
    </ul>
  );
};

const MyVirtualList = VirtualList()(MyList);

class Creators extends Component {
  state={
    statusChangeLoading: false,
    editComment: null,
    itemHeight: 110,
    width: 1200,
    selected: null,
  }

  componentDidMount() {
    window.addEventListener('resize', this.resizeWindow);
    this.resizeWindow();
    this.initSelected();
  }


  componentDidUpdate(prevProps) {
    if ((this.props.location.search !== prevProps.location.search)
      || (this.props.location.pathname !== prevProps.location.pathname)) {
      this.initSelected();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeWindow);
  }

  resizeWindow = () => {
    if (window.innerWidth !== this.state.width) {
      this.setState({
        width: window.innerWidth,
        itemHeight: this.calculateListElementHeight(window.innerWidth),
      });
    }
  }

  calculateListElementHeight = (width) => {
    if (width < 500) {
      return 360;
    }
    if (width < 1000) {
      return 290;
    } if (width < 1230) {
      return 174;
    }
    return 110;
  }

  initSelected = () => {
    const {
      stage,
    } = parseSearch(this.props.location.search);
    const path = this.props.location.pathname.split('/');
    const isTracking = path[path.length - 1] === 'tracking';
    this.setState({ selected: `${isTracking ? 'TRACKING' : (stage || 'ALL')}` });
  }

  getPotentialReachLikeForAccount = account => (
    account.followerCount * (account.globalEngagementRate / 100))

  getPotentialReachViewsForAccount = account => this.getPotentialReachLikeForAccount(account) * 5

  getEstimatedCostForAccount = account => (
    (this.getPotentialReachViewsForAccount(account) / 1000) * 5).toFixed(0)

  getPotentialReachLike = () => {
    const { influencers } = this.props;
    if (!influencers || influencers.length === 0) {
      return 0;
    }
    const global = influencers.reduce(
      (acc, i) => ({
        total: (i.followerCount * (i.globalEngagementRate / 100)) + (acc.total || 0),
      }),
      {},
    );
    return global.total.toFixed(0);
  }

  getPotentialReachView = () => this.getPotentialReachLike() * 5

  getEstimatedCost = () => ((this.getPotentialReachView() / 1000) * 5).toFixed(0)

  getTotalNumberOfFollowers = () => {
    const { influencers } = this.props;
    if (!influencers || influencers.length === 0) {
      return 0;
    }
    const global = influencers.reduce(
      (acc, i) => ({
        total: (i.followerCount) + (acc.total || 0),
      }),
      {},
    );
    return global.total.toFixed(0);
  }

  filterListFromStage = stage => this.props.influencers.filter((i) => {
    if (!stage || stage === 'ALL') {
      return true;
    }
    return i.stage === (stage);
  })

  openRemoveInfluencerConfirm = (influencerId) => {
    Modal.confirm({
      title: 'Are you sure you want to remove this creator from the campaign?',
      // content: 'You can\'t undo this. Use Archive if you just want to hide it.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: () => {
        this.removeInfluencerFromList(this.props.match.params.id, influencerId);
      },
    });
  }

  removeInfluencerFromList = (campaignId, influencerId) => {
    this.setState({ loading: true });
    RemoveInfluencerFromCampaign(campaignId, influencerId).then(() => {
      this.props.updateInfluencers(
        this.props.influencers.filter(i => i._id !== influencerId),
        () => {
          message.success('Influencer removed');
        },
      );
    }).catch((e) => {
      console.error(e);
      message.error("Couldn't remove influencer");
    }).finally(() => {
      this.setState({ loading: false });
    });
  }

  updateInfluencer = (influencerData) => {
    this.setState({ statusChangeLoading: true });
    return updateInfluencerInCampaign(influencerData).then((res) => {
      const news = this.props.influencers.map(i => (i._id === res.data._id ? res.data : i));
      this.props.updateInfluencers(news, () => {
        message.success('Influencer updated');
      });
    }).catch((e) => {
      console.error(e);
      message.error("Couldn't update influencer");
    }).finally(() => {
      this.setState({ statusChangeLoading: false });
    });
  }

  onChangeStatus = (influencerId, e) => this.updateInfluencer(
    {
      campaignId: this.props.match.params.id,
      influencerId,
      stage: e,
    },
  );

  editComment = id => this.setState({ editComment: id });

  saveComment = (e) => {
    if (e) {
      e.preventDefault();
    }
    this.props.form.validateFields((err, values) => {
      if (!err) {
        if (this.state.editComment) {
          this.updateInfluencer(
            {
              campaignId: this.props.match.params.id,
              influencerId: this.state.editComment,
              comment: values[`comment_${this.props.match.params.id}_${this.state.editComment}`],
            },
          ).catch((error) => {
            console.error(error);
            message.error('Error while saving comment');
          }).then(() => {
            this.setState({ editComment: null });
          });
        }
      }
    });
  };

  onCancelComment = () => this.setState({
    editComment: null,
  }, () => { this.props.form.resetFields(); });

  onChangeStage = (e) => {
    const stage = e.target.value;
    const currentSearch = parseSearch(this.props.location.search);
    const newSearch = { ...currentSearch, stage };
    if ((JSON.stringify(currentSearch) !== JSON.stringify(newSearch))
    ) {
      this.props.history.push({
        pathname: this.props.location.pathname,
        search: stringifySearch(newSearch),
      });
    }
  }


  getTextFromStage = (text) => {
    switch (text) {
      case STAGES.TO_CONTACT:
      default:
        return 'TO CONTACT';
      case STAGES.CONTACTING:
        return 'CONTACTING';
      case STAGES.INTERVIEWING:
        return 'INTERVIEWING';
      case STAGES.COLLABORATING:
        return 'COLLABORATING';
    }
  }

  render() {
    const {
      campaignDetails, stage, influencers, profile,
    } = this.props;
    const {
      loading, statusChangeLoading, itemHeight, selected,
    } = this.state;
    const filteredInfluencers = this.filterListFromStage(stage);
    return (
      <div>
        <div className="flex Creator_list_title">
          <div className="Creator_list_title_left">
            <h2>Creators</h2>
            {campaignDetails && !campaignDetails.archived && <a href="/#/search"><Button icon="plus" type="link" style={{}}>Add creators</Button></a>}
          </div>
          <div className="Creator_list_title_right">
            <div style={{ margin: '4px' }}>
              <Tooltip
                placement="left"
                title="Export all the data of the influencers including Emails, TikTok username, name, region, followers, engagement, also Instagram and Youtube info."
              >
                <Icon type="question-circle" />
              </Tooltip>
            </div>
            {(!isAuthorized(profile, FEATURES.EXPORT))
              ? (
                <Tooltip
                  placement="left"
                  title={(
                    <div>
                                  This feature is available in Startup, Business or Gold plans.
                      {' '}
                      {(
                        <>
Upgrade here:
                          {' '}
                          {getUpgradeButton({ isLink: true })}
                        </>
                                    )}
                    </div>
)}
                >
                  <Button
                    icon="download"
                    type="primary"
                  >
Export list
                    {` (${filteredInfluencers.length})`}
                  </Button>
                </Tooltip>
              )
              : (
                <Button
                  onClick={() => {
                    window.open(getDownloadListURL(
                      campaignDetails._id,
                      stage,
                    ));
                    setTimeout(() => { this.props.getProfile(); }, 200);
                  }}
                  disabled={
                                  (filteredInfluencers.length === 0)
                                  || (campaignDetails && campaignDetails.archived)
                                  }
                  icon="download"
                  type="primary"
                >
Export list
                  {` (${filteredInfluencers.length})`}
                </Button>
              )}
          </div>

        </div>
        {campaignDetails && campaignDetails.archived && (
        <div className="grey right">
          <i>Archived campaigns cannot be exported.</i>
        </div>
        )}
        <div className="Global_campaign_infos">
          <div className="Global_campaign_infos_inner">
            <div className="Global_campaign_item">
              <Statistic
                className={isAuthorized(profile, FEATURES.CAMPAIGN_DETAILS) ? '' : 'Stat_blur'}
                prefix={<Icon type="team" />}
                title="Total followers"
                value={getCountLabelInteger(this.getTotalNumberOfFollowers())}
              />
            </div>
            <div className="Global_campaign_item">
              <Statistic
                className={isAuthorized(profile, FEATURES.CAMPAIGN_DETAILS) ? '' : 'Stat_blur'}
                prefix={<Icon type="like" />}
                title={(
                  <Tooltip title="Total number of followers * global engagement rate">
                    <span>Potential reach (like)</span>
                    {' '}
                    <Icon type="info-circle" />
                  </Tooltip>
)}
                value={getCountLabel(this.getPotentialReachLike())}
              />
            </div>
            <div className="Global_campaign_item">
              <Statistic
                className={isAuthorized(profile, FEATURES.CAMPAIGN_DETAILS) ? '' : 'Stat_blur'}
                prefix={<Icon type="eye" />}
                title={(
                  <Tooltip title="Potential reach in like * 5">
                    <span>Potential reach (view)</span>
                    {' '}
                    <Icon type="info-circle" />
                  </Tooltip>
)}
                value={getCountLabel(this.getPotentialReachView())}
              />
            </div>
            <div className="Global_campaign_item">
              <Statistic
                className={isAuthorized(profile, FEATURES.CAMPAIGN_DETAILS) ? '' : 'Stat_blur'}
                prefix={<Icon type="dollar" />}
                title={(
                  <Tooltip title="Based on Tiktok ads pricing for CPM : [(Potential reach (view) / 1000) * 5]">
                    <span>Estimated cost</span>
                    {' '}
                    <Icon type="info-circle" />
                  </Tooltip>
)}
                value={getCountLabel(this.getEstimatedCost())}
              />
            </div>
          </div>
          {!isAuthorized(profile, FEATURES.CAMPAIGN_DETAILS) && (
          <div>
            <br />
            {getAlertStarter('The campaign KPIs are available in Startup, Business or Gold plans.')}
          </div>
          )}
        </div>
        <div>
          <div className="Creators_stages_list">
            <Radio.Group
              value={selected}
              onChange={this.onChangeStage}
              style={{ marginBottom: 16 }}
            >
              <Radio.Button value="ALL">{`ALL (${campaignDetails ? getTotalCreatorsFromStageObject(campaignDetails.stage) : 0})`}</Radio.Button>
              {Object.keys(STAGES).map(s => (
                <Radio.Button
                  key={s}
                  value={s}
                  className={s}
                >
                  {`${this.getTextFromStage(s)} (${campaignDetails ? campaignDetails.stage[s] : 0})`}
                </Radio.Button>
              ))}
            </Radio.Group>
          </div>

        </div>
        { influencers && influencers.length > 0 ? (
          <div>
            <div className="Campaign_creator_list_wrapper">
              <MyVirtualList
                items={filteredInfluencers}
                itemHeight={itemHeight + 12}
                itemBuffer={3}
                state={this.state}
                props={this.props}
                saveComment={this.saveComment}
                editComment={this.editComment}
                onCancelComment={this.onCancelComment}
                onChangeStatus={this.onChangeStatus}
                openRemoveInfluencerConfirm={this.openRemoveInfluencerConfirm}
                openAccountPreviewModale={this.props.openAccountPreviewModale}
                loading={loading || statusChangeLoading}
              />
            </div>
          </div>

        ) : (
          <Empty
            description={(
              <span>
                <span>No creators yet.</span>
                <br />
                {campaignDetails && !campaignDetails.archived && (
                <span>
                  <a href="/#/search">
                    <Button type="link">Add creators</Button>
                  </a>
                </span>
                )}
              </span>
)}
          />
        )}
      </div>
    );
  }
}

Creators.propTypes = {
  match: PropTypes.object,
  form: PropTypes.object,
  profile: PropTypes.object,
  influencers: PropTypes.array,
  campaignDetails: PropTypes.object,
  stage: PropTypes.string,
  getProfile: PropTypes.func,
  openAccountPreviewModale: PropTypes.func,
  updateInfluencers: PropTypes.func,
};

Creators.defaultProps = {
  match: {},
  form: {},
  profile: {},
  influencers: [],
  campaignDetails: {},
  stage: null,
  getProfile: () => {},
  openAccountPreviewModale: () => {},
  updateInfluencers: () => {},
};


export default Form.create({ name: 'comment' })(withRouter(Creators));
