import React, { Component } from 'react';
import {
  Button, Spin, Card, Divider, Icon, message,
} from 'antd';
import { Elements, StripeProvider } from 'react-stripe-elements';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Moment from 'moment';
import Header from '../components/header';
import EditProfile from '../components/editProfile';
import { isConnected, createCustomerPortal } from '../services/user';
import { parseSearch, getPlanTag } from '../services/utils';
import SubscriptionDrawer from '../components/subscriptionDrawer';
import Credits from '../components/credits';

import '../styles/global.less';
import '../styles/settings.less';
import { PLANS, getStripeToken } from '../services/constants';

class Settings extends Component {
  constructor(props) {
    super(props);
    if (!isConnected()) {
      this.props.history.push('/login?next=settings?upgrade=true');
    }
  }

  componentDidMount() {
    this.props.getProfile();
  }

  createPortalStripe = () => {
    createCustomerPortal().then((res) => {
      console.log('res', res);
      window.location.replace(res.data);
    }).catch((e) => {
      console.error(e);
      message.error('Error while redirecting to Stripe');
    });
  }

  closeEditProfile = () => {
    this.props.getProfile().then(() => {
      this.props.history.push(this.props.history.location.pathname);
    });
  }

    closeSubscriptionDrawer = () => this.props.history.push(this.props.history.location.pathname)


    render() {
    // const { loading } = this.state;
      const { profile, getProfile } = this.props;
      const { edit, upgrade } = parseSearch(this.props.location.search);
      const isEditProfileVisible = edit === 'true';
      const isUpgradeVisible = upgrade === 'true';
      return (
        <div className="App">
          <Header profile={profile} />
          <div className="Settings_global_wrapper">
            <div className="div-wrapper">
              <div className="container">
                <Spin spinning={!profile} tip="Loading...">
                  <div className="Settings_global_inner">
                    <div className="Settings_background_wrapper">
                      <img src="img/circles.png" alt="settings tokfluence" className="Settings_background" />
                    </div>
                    {profile && (
                    <>
                      <Card
                        className="Profile_card_settings"
                        title="Profile"
                        style={{ width: '300px', margin: 'auto' }}
                      >
                        <div>
                          <img src="img/profile.png" alt="profile" style={{ width: '100%' }} />
                        </div>
                        <Divider />
                        <h2>Account</h2>
                        <div>
Email:
                          {' '}
                          <span className="bold">{profile.email}</span>
                        </div>
                        <div style={{ marginTop: '12px' }}>
Current plan:
                          {' '}
                          {getPlanTag(profile.plan)}
                        </div>
                        {(profile.stripeSubscription
                        && (profile.stripeSubscription.status === 'active'
                        || profile.stripeSubscription.status === 'trialing')) && (
                          <div>
                            <div>
                              Until:
                              <b>
                                {` ${new Moment(
                                  profile.stripeSubscription.current_period_end * 1000,
                                ).format('MM/DD/YYYY')}`}
                              </b>
                            </div>
                            <div>
                              <i>
                                {' '}
Automatic renewal is
                                {' '}
                                {profile.stripeSubscription.cancel_at_period_end ? 'inactive' : 'active'}
                              </i>

                            </div>
                          </div>
                        )}
                        <Divider />
                        <h2>Credits</h2>
                        {(profile) && (
                          <div>
                            { profile.plan !== PLANS.FREE && profile.searchCredits && (
                            <div>
                            Search credits remaining:
                              {' '}
                              <div className="Search_credit">
                                <Icon type="search" />
                                {' '}
                                <span className="title bold">{profile.searchCredits.remaining}</span>
                                {(profile.searchCredits.total !== 'Unlimited') ? `/${profile.searchCredits.total}` : ''}
                              </div>
                              <br />
                              <br />
                            </div>
                            )}

                            {profile.exportCredits && (
                            <div>
                            Export credits remaining:
                              {' '}
                              <div className="Export_credit">
                                <Icon type="export" />
                                {' '}
                                <span className="title bold">{profile.exportCredits.remaining + profile.additionalExportCredits}</span>
                              </div>
                              <div className="Settings_credits_detailed">
                                {`Including ${profile.exportCredits.remaining}/${profile.exportCredits.total} credits from your plan.`}
                                {' '}
                              </div>
                              <br />
                            </div>
                            )}
                            <div>
                            Audience Credits:
                              {' '}
                              <Credits profile={profile} />
                            </div>

                            {(profile.reportCredits && profile.reportCredits.total > 0) ? (
                              <div className="Settings_credits_detailed">
                                {`Including ${profile.reportCredits.remaining}/${profile.reportCredits.total} credits from your plan.`}
                                {' '}
                              </div>
                            ) : <></>}

                            <div style={{ marginTop: '4px', textAlign: 'right' }}>
                              <a href="/#/reports/buy-credits">
                                <Button type="link">
                                Buy credits

                                </Button>
                              </a>
                            </div>

                          </div>
                        )}
                        <Divider />
                        {profile && profile.billingAddress && (
                        <>
                          <div className="">
                            <h2>Billing</h2>
                            <div>{profile.billingAddress.name}</div>
                            <div>{profile.billingAddress.street}</div>
                            <div>{`${profile.billingAddress.postalCode} - ${profile.billingAddress.city}`}</div>
                            <div>{profile.billingAddress.country}</div>
                            <div>{profile.billingAddress.vatNumber}</div>
                            <br />
                            {profile.plan !== PLANS.FREE && (
                            <div>
                              <Button
                                block
                                type="primary"
                                onClick={this.createPortalStripe}
                              >
Manage Subscription and invoices
                              </Button>
                              <Divider />
                            </div>
                            )}
                          </div>
                        </>
                        )}
                        <div className="flex" style={{ justifyContent: 'flex-end' }}>
                          <Button
                            onClick={() => this.props.history.push(`${this.props.history.location.pathname}?edit=true`)}
                            type="link"
                          >
Edit profile
                          </Button>
                          {(
                            profile.plan === PLANS.FREE
                            || profile.plan === PLANS.STARTUP
                            || profile.plan === PLANS.BUSINESS_V3) && (
                            <Button
                              onClick={() => this.props.history.push(`${this.props.history.location.pathname}?upgrade=true`)}
                              type="primary"
                            >
                              Upgrade
                            </Button>
                          )}
                        </div>
                        <div />

                      </Card>

                    </>
                    )}

                  </div>
                </Spin>
              </div>
            </div>
          </div>
          {profile && (
          <EditProfile
            profile={profile}
            isVisible={isEditProfileVisible}
            close={this.closeEditProfile}
          />
          )}
          <StripeProvider apiKey={getStripeToken()}>
            <div>
              <Elements>
                {profile ? (
                  <SubscriptionDrawer
                    isOpen={isUpgradeVisible}
                    close={this.closeSubscriptionDrawer}
                    updateUserProfile={getProfile}
                    profile={profile}
                  />
                ) : <div />}
              </Elements>
            </div>
          </StripeProvider>
        </div>
      );
    }
}

Settings.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  profile: PropTypes.object,
  getProfile: PropTypes.func,
};

Settings.defaultProps = {
  history: {},
  location: {},
  profile: {},
  getProfile: null,
};

export default withRouter(Settings);
