import React, { Component } from 'react';
import { Button, Icon } from 'antd';
import { CURRENT_BANNER_ID } from '../services/constants';

import '../styles/banner.less';

class BannerAnnouncement extends Component {
  state={ showBanner: false }

  componentDidMount() {
    const isHiding = localStorage.getItem(CURRENT_BANNER_ID) === 'true';
    this.setState({ showBanner: !isHiding });
  }

  closeBanner = () => {
    localStorage.setItem(CURRENT_BANNER_ID, true);
    this.setState({ showBanner: false });
  }

  render() {
    const { showBanner } = this.state;
    return (
      <div className="Banner_announcement_wrapper">
        <div className="Banner_announcement_inner">
          {showBanner
            ? (
              <>
                <div
                  className="title Banner_announcement_desc "
                >
Get 5
                  {' '}
                  <b>FREE</b>
                  {' '}
audience analysis credits for a 15 minutes customer feedback meeting.
                </div>
                <div className="Banner_announcement_action">
                  <Button size="small" type="primary" onClick={() => { window.open('https://calendly.com/tokfluence/customer-feedback'); }}>
      Book
                  </Button>
                </div>
                <div className="Banner_announcement_close" onClick={this.closeBanner}>
                  <Icon type="close" />
                </div>
              </>
            )
            : (
              <div className="title Banner_announcement_desc ">
            Have a great day !
              </div>
            )}
        </div>
      </div>
    );
  }
}

export default BannerAnnouncement;
