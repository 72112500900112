import React, { Component } from 'react';
import {
  Form, Icon, Input, Button, message,
} from 'antd';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { login, isConnected } from '../services/user';
import { parseSearch } from '../services/utils';

import '../styles/login.less';

class Login extends Component {
  state = { loading: false };

  componentDidMount() {
    if (isConnected()) {
      this.props.history.push('/search');
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.form.validateFields((error, values) => {
      if (!error) {
        this.setState({ loading: true });
        login(values.email.toLowerCase(), values.password)
          .then(() => {
            this.props.getProfile().finally(() => {
              this.setState({ loading: false });
              // eslint-disable-next-line react/prop-types
              const { next } = parseSearch(this.props.location.search);
              const decoded = decodeURIComponent(next);
              const adjusted = decoded.includes('upgrade') ? `${decoded}=true` : decoded;
              // eslint-disable-next-line react/prop-types
              this.props.history.push(next ? `/${adjusted}` : '/search');
            });
          })
          .catch((err) => {
            if (err && err.response && err.response.status === 404) {
              message.error('Login error : this email does not exist.');
            } else if (err && err.response && err.response.status === 401) {
              message.error('Login error : Wrong password.');
            } else if (err && err.response && err.response.status === 409) {
              message.error('Login error : You must verify your account, check your email.');
            } else {
              message.error('Unknown error.');
            }
            this.setState({ loading: false });
          });
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { loading } = this.state;
    const { ref } = parseSearch(this.props.location.search);
    return (
      <div className="Login_global_global">
        <div className="Login_global_wrapper">
          <div className="Login_form_wrapper">
            <div className="Login_form_logo_wrapper">
              <img
                src="img/logo.png"
                className="Login_form_logo"
                alt="Logo tokfluence"
              />
            </div>
            <Form onSubmit={this.handleSubmit} className="login-form">
              <Form.Item label="Email">
                {getFieldDecorator('email', {
                  rules: [{ required: true, message: 'Please input your username!' }],
                })(
                  <Input
                    prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                    placeholder="Enter your email"
                    type="email"
                  />,
                )}
              </Form.Item>
              <Form.Item label="Password">
                {getFieldDecorator('password', {
                  rules: [{ required: true, message: 'Please input your Password!' }],
                })(
                  <Input
                    prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                    type="password"
                    placeholder="Enter your password"
                  />,
                )}
              </Form.Item>
              <Form.Item>
                <Button
                  loading={loading}
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                >
                Login
                </Button>
                <a className="Login_register_link" href={`/#/register${ref ? `?ref=${ref}` : ''}`}>
                  {"I don't have an account yet"}
                </a>
                <a className="Login_forgot_link" href="/#/forget-password">
                Forgot password
                </a>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}

Login.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  form: PropTypes.object,
  getProfile: PropTypes.func,
};

Login.defaultProps = {
  history: {},
  location: {},
  form: null,
  getProfile: () => {},
};

export default Form.create({ name: 'login' })(withRouter(Login));
