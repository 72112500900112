import React from 'react';
import PropTypes from 'prop-types';

import { Icon, Tooltip } from 'antd';
import '../styles/credits.less';

const Credits = ({ profile }) => (profile ? (
  <Tooltip
    placement="bottom"
    title={(profile.reportCredits && profile.reportCredits.total > 0) ? `Including ${profile.reportCredits.remaining}/${profile.reportCredits.total} credits from your plan.` : ''}
  >
    <div className="Credits_wrapper title bold">
      <Icon type="radar-chart" />
      {' '}
      {(profile.audienceAnalysisCredits || 0) + (profile.reportCredits
        ? profile.reportCredits.remaining : 0)}
    </div>
  </Tooltip>
) : <></>);


Credits.propTypes = {
  profile: PropTypes.object,
};

Credits.defaultProps = {
  profile: null,
};

export default Credits;
