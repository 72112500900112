/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-closing-tag-location */
import React, { Component } from 'react';
import {
  Tooltip, Button, Icon, message,
} from 'antd';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { askReport, displayModalConfirm } from '../services/reports';

import '../styles/audienceReportButton.less';

class AudienceReportButton extends Component {
  state={ askReportLoading: false }

  render() {
    const {
      profile, account, getProfile, getReportCallbackSuccess,
    } = this.props;
    const { askReportLoading } = this.state;

    return (
      <Tooltip title="Checkout the audience report, costs 1 credit.">
        <Button
          onClick={() => {
            if (profile && ((profile.audienceAnalysisCredits || 0) + (profile.reportCredits
              ? profile.reportCredits.remaining : 0) > 0)) {
              displayModalConfirm({
                credits: (profile.audienceAnalysisCredits + (profile.reportCredits
                  ? profile.reportCredits.remaining : 0)),
                onOk: () => {
                  this.setState({ askReportLoading: true });
                  askReport(account._id).then((res) => {
                    message.success(<span>
                     Report asked, you'll receive an email when it's ready.
                      {' - '}
                      <a href="/#/reports/">View reports</a>
                    </span>, 4);
                    getProfile();
                    getReportCallbackSuccess(res.data._id);
                  }).catch((e) => {
                    console.error(e);
                    message.error('Error while asking for the report');
                  }).finally(() => {
                    this.setState({ askReportLoading: false });
                  });
                },
              });
            } else {
              this.props.history.push('/reports/buy-credits');
            }
          }}
          className="Report_button"
          size="small"
          shape="round"
          loading={askReportLoading}
        >
Audience report
          {' '}
          <Icon type="radar-chart" />
          <span className="title bold">1</span>
        </Button>
      </Tooltip>
    );
  }
}
AudienceReportButton.propTypes = {
  history: PropTypes.object,
  profile: PropTypes.object,
  getProfile: PropTypes.func,
  account: PropTypes.object,
  getReportCallbackSuccess: PropTypes.func,
};

AudienceReportButton.defaultProps = {
  history: {},
  profile: {},
  getProfile: () => {},
  account: {},
  getReportCallbackSuccess: () => {},
};
export default withRouter(AudienceReportButton);
