import React, { Component } from 'react';
import {
  HashRouter, Redirect, Route, Switch,
} from 'react-router-dom';
import smartlookClient from 'smartlook-client';
import ReactGA from 'react-ga';
import Login from './pages/login';
import ForgetPassword from './pages/forgot-password';
import ResetPassword from './pages/reset-password';
import Register from './pages/register';
import Campaign from './pages/campaigns/[id]';
import Search from './pages/search';
import Settings from './pages/settings';
import Id from './pages/users/id';
import Reports from './pages/reports';
import ReportId from './pages/reports/id';
import BuyCredits from './pages/reports/buycredits';
import PaymentSuccess from './pages/reports/payment-success';
import Loader from './components/loader';
import { getProfile, logout, isConnected } from './services/user';
import ConnectAs from './pages/connect-as';
import './components/i18n';
import ValidateEmail from './pages/validate-email';

class Routing extends Component {
  state = { profile: null };

  componentDidMount() {
    if (isConnected()) {
      this.getUserProfile();
      ReactGA.initialize('UA-165559678-1');
      ReactGA.pageview(`/${window.location.hash}`);
    }
    if (window && window.location && window.location.href.indexOf('localhost') === -1) {
      smartlookClient.init('5c99aa71980c613a173ceabf622a00a51425b57e');
    }
  }

  getUserProfile = () => getProfile().then((res) => {
    this.setState({ profile: res.data });
  }).catch((err) => {
    if (err && err.response && err.response.status === 401) {
      logout(() => { window.location.replace(`${window.location.origin}/#/login`); });
    } else {
      console.error(err);
    }
  })

  render() {
    const appProps = this.props;
    const { loading, profile } = this.state;
    return (
      <>
        <HashRouter>
          <Switch>
            <Route path="/login" exact render={props => <Login {...appProps} {...props} getProfile={this.getUserProfile} />} />
            <Route path="/register" exact render={props => <Register {...appProps} {...props} getProfile={this.getUserProfile} />} />
            <Route path="/users/:id" exact render={props => <Id {...appProps} {...props} profile={profile} getProfile={this.getUserProfile} />} />
            <Route path="/reports/" exact render={props => <Reports {...appProps} {...props} profile={profile} />} />
            <Route path="/reports/buy-credits" exact render={props => <BuyCredits {...appProps} {...props} profile={profile} getProfile={this.getUserProfile} />} />
            <Route path="/reports/payment-success" exact render={props => <PaymentSuccess {...appProps} {...props} profile={profile} />} />
            <Route path="/reports/:id" exact render={props => <ReportId {...appProps} {...props} profile={profile} />} />
            <Route path="/campaigns/:id?" exact render={props => <Campaign {...appProps} {...props} profile={profile} getProfile={this.getUserProfile} />} />
            <Route path="/campaigns/:id/tracking" exact render={props => <Campaign {...appProps} {...props} profile={profile} getProfile={this.getUserProfile} isTracking />} />
            <Route
              path="/validate-email/:token"
              exact
              render={props => (
                <ValidateEmail
                  {...appProps}
                  {...props}
                  getProfile={this.getUserProfile}
                />
              )}
            />
            <Route
              path="/settings"
              exact
              render={props => (
                <Settings
                  {...appProps}
                  {...props}
                  profile={profile}
                  getProfile={this.getUserProfile}
                />
              )}
            />
            <Route path="/search" exact render={props => <Search {...appProps} {...props} profile={profile} getProfile={this.getUserProfile} />} />
            <Route
              path="/forget-password"
              exact
              render={props => <ForgetPassword {...appProps} {...props} />}
            />
            <Route
              path="/reset-password/:resetPasswordToken"
              render={props => <ResetPassword {...appProps} {...props} />}
            />
            <Route
              path="/connect-as/:sessionId"
              exact
              render={props => <ConnectAs {...appProps} {...props} />}
            />
            <Redirect from="*" to="/login" />
          </Switch>
        </HashRouter>
        {loading && <Loader />}
      </>
    );
  }
}

export default Routing;
