/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-closing-tag-location */
import React from 'react';
import {
  Tooltip, Button,
} from 'antd';
import PropTypes from 'prop-types';

import '../styles/audienceReportButton.less';

const ViewReportButton = ({ reportId }) => (
  <Tooltip title="You have a report on this influencer. Click to display it.">
    <a href={`#/reports/${reportId}`}>
      <Button
        icon="radar-chart"
        className="Report_button"
        size="small"
        shape="round"
      >
View report
      </Button>
    </a>
  </Tooltip>
);
ViewReportButton.propTypes = {
  reportId: PropTypes.string,
};

ViewReportButton.defaultProps = {
  reportId: '',
};
export default ViewReportButton;
