import React, { Component } from 'react';
import {
  Form, Icon, Input, Button, message,
} from 'antd';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { resetPassword } from '../services/user';
import '../styles/login.less';

class ResetPassword extends Component {
  state = { loading: false };

  handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({ loading: true });
        resetPassword(values.password, this.props.match.params.resetPasswordToken)
          .then(() => {
            message.success(this.props.t('passwordUpdatedSuccess'));
            this.props.history.push('/accounts');
          })
          .catch((error) => {
            this.setState({ loading: false });
            if (error && error.response && error.response.status === 404) {
              message.error(this.props.t('errorLinkResetPasswordExpired'));
            } else {
              message.error(this.props.t('errorUnknown'));
            }
          });
      }
    });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue('password')) {
      callback(this.props.t('errorPasswordDifferent'));
    } else {
      callback();
    }
  };

  render() {
    const { t } = this.props;
    const { getFieldDecorator } = this.props.form;
    const { loading } = this.state;
    return (
      <div className="Login_global_global">
        <div className="Login_global_wrapper">
          <div className="Login_form_wrapper">
            <div className="Login_form_logo_wrapper">
              <img
                src="img/logo.png"
                className="Login_form_logo"
                alt="Logo tokfluence"
              />
            </div>
            <h2 className="center">{t('enterNewPassword')}</h2>
            <Form onSubmit={this.handleSubmit} className="login-form">
              <Form.Item>
                {getFieldDecorator('password', {
                  rules: [{ required: true, message: t('errorEnterNewPassword') }],
                })(
                  <Input
                    prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                    type="password"
                    placeholder={t('placeholderNewPassword')}
                  />,
                )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator('confirm', {
                  rules: [
                    {
                      required: true,
                      message: t('errorConfirmPassword'),
                    },
                    {
                      validator: this.compareToFirstPassword,
                    },
                  ],
                })(
                  <Input
                    prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                    type="password"
                    placeholder={t('placeholderPasswordConfirmation')}
                  />,
                )}
              </Form.Item>
              <Form.Item>
                <Button loading={loading} type="primary" htmlType="submit" className="login-form-button">
                  {t('update')}
                </Button>
                <a className="Login_register_link" href="/#/login">
                  {t('connect')}
                </a>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}

ResetPassword.propTypes = {
  form: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  t: PropTypes.func,
};

ResetPassword.defaultProps = {
  form: null,
  history: {},
  match: {},
  t: () => {},
};

export default Form.create({ name: 'resetPassword' })(withTranslation()(withRouter(ResetPassword)));
