/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';
import { withRouter } from 'react-router-dom';
import Header from '../../components/header';
import {
  isConnected,
} from '../../services/user';
import Loader from '../../components/loader';
import TiktokAccount from '../../components/tiktokAccount';
import {
  parseSearch,
} from '../../services/utils';
import '../../styles/global.less';
import '../../styles/user.less';

Moment.locale('en');
class Username extends Component {
  state={ loading: false }

  constructor(props) {
    super(props);
    window.scrollTo(0, 0);
    if (!isConnected()) {
      const { ref } = parseSearch(props.location.search);
      this.props.history.push(`/login?next=users/${(props.match.params && props.match.params.id)
        ? props.match.params.id : ''}${ref ? `&ref=${ref}` : ''}`);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.match
      && this.props.match.params
      && prevProps.match && prevProps.match.params
      && this.props.match.params.id !== prevProps.match.params.id) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    const { loading } = this.state;
    const { match, profile } = this.props;

    return (
      <div>
        <Header profile={profile} />
        {match.params && match.params.id && (
        <TiktokAccount
          accountId={match.params.id}
          profile={profile}
          getProfile={this.props.getProfile}
        />
        )}
        {loading && <Loader />}
      </div>
    );
  }
}

Username.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
  profile: PropTypes.object,
  getProfile: PropTypes.func,
  location: PropTypes.object,
};

Username.defaultProps = {
  match: {},
  history: {},
  profile: {},
  location: {},
  getProfile: () => {},
};


export default withRouter(Username);
