/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-closing-tag-location */
import React, { Component } from 'react';
import { message, Icon, Alert } from 'antd';
import PropTypes from 'prop-types';

import { injectStripe } from 'react-stripe-elements';
import Header from './header';
import { buyCredits } from '../services/reports';
import EditProfile from './editProfile';
import Loader from './loader';
import {
  getCreditList,
} from '../services/user';
import { getStripeToken } from '../services/constants';
import { isProfileBillingValid } from '../services/utils';
import '../styles/buycredits.less';

class BuyCreditsContent extends Component {
  state={
    credits: null, loading: false, isEditProfileVisible: false, selectedSub: null,
  }

  componentDidMount = () => {
    getCreditList().then((res) => {
      window.scrollTo(0, 0);
      this.setState({ credits: res.data });
    }).catch((e) => {
      console.error(e);
      message.error('Could not load credit list');
    });
  }

  buy = (sub) => {
    this.setState({ loading: true });
    buyCredits(
      {
        quantity: sub.quantity,
        stripePk: getStripeToken(),
      },
    ).then((res) => {
      this.props.stripe.redirectToCheckout({
        // Make the id field from the Checkout Session creation API response
        // available to this file, so you can provide it as parameter here
        // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
        sessionId: res.data.checkoutSessionId,
      }).then((result) => {
        // If `redirectToCheckout` fails due to a browser or network
        // error, display the localized error message to your customer
        // using `result.error.message`.
        console.error(result);
        message.error('Error with the payment');
      }).catch((errr) => {
        message.error('Error with the payment');
        console.error(errr);
      });
    }).catch((er) => {
      console.error(er);
      message.error('Error buying credits');
      this.setState({ loading: false });
    });
  }

  clickBuy = (sub) => {
    if (isProfileBillingValid(this.props.profile)) {
      this.buy(sub);
    } else {
      this.setState({ isEditProfileVisible: true, selectedSub: sub });
    }
  }

  closeEditProfile = () => {
    this.props.getProfile().then(() => this.setState({
      isEditProfileVisible: false,
    }, () => {
      if (isProfileBillingValid(this.props.profile) && this.state.selectedSub) {
        this.buy(this.state.selectedSub);
      }
    }));
  };

  render() {
    const { profile } = this.props;
    const { credits, loading, isEditProfileVisible } = this.state;
    return (
      <div>
        <Header profile={profile} />
        <div className="Buy_credits_page">
          <div className="div-wrapper">
            <div className="container">
              <h1>Credit options</h1>
              {(profile
              ) ? (
                <Alert
                  type="info"
                  showIcon
                  message={(
                    <>
                      <div>
                     You currently have
                        {' '}
                        <Icon type="radar-chart" />
                        {(profile.audienceAnalysisCredits || 0) + (profile.reportCredits
                          ? profile.reportCredits.remaining : 0)}
                        {' '}
credits.
                      </div>
                      {(profile.reportCredits && profile.reportCredits.total > 0) ? (
                        <div className="Settings_credits_detailed">
                          {`Including ${profile.reportCredits.remaining}/${profile.reportCredits.total} credits from your plan.`}
                          {' '}
                        </div>
                      ) : <></>}

                    </>
)}
                />
                ) : <></>}
              <br />
              <div className="" style={{ fontSize: '20px' }}>
                  Please select an option:
              </div>
              <div>
                {credits && credits.length > 0 && (
                <div>
                  <br />
                  <div className="Credit_list_wrapper">
                    {credits
                      .map(s => (
                        <div
                          key={s.id}
                          className="Subscription_list_element"
                          onClick={() => (s.price ? this.clickBuy(s) : null)}
                        >
                          <div className="Credit_logo"><Icon type="radar-chart" /></div>
                          <div style={{ display: 'flex', flexDirection: 'column', flexGrow: '1' }}>
                            <div className="Subscription_element_content">
                              <div className="Subscription_element_title title">
                                <Icon type="radar-chart" />
                                {s.name}
                              </div>
                              <div className="Subscription_element_subtitle">{s.description}</div>
                            </div>
                            <div className="Subscription_element_price title">
                              <div>
                                <span>
                                  {`$${s.price / 100}`}
                                </span>
                              </div>
                              {' '}
                              {s.price && (
                              <div className="Subscription_element_price_month" />
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>

                )}
              </div>

            </div>
          </div>
        </div>
        <div className="Need_more_credits_wrapper center">
          <div className="Saving_picture">
            <img src="img/saving.png" alt="saving" />
          </div>
          <div className="div-wrapper">
            <div className="container">
              <h1>Need more credits ?</h1>
              <div>
                  Send us an email at
                {' '}
                <a href="mailto:contact@tokfluence.com">contact@tokfluence.com</a>
, we'll be happy to discuss that with you.
              </div>
            </div>
          </div>
        </div>
        {profile && (
        <EditProfile
          profile={profile}
          isVisible={isEditProfileVisible}
          close={this.closeEditProfile}
        />
        )}
        {loading && <Loader />}
      </div>
    );
  }
}
BuyCreditsContent.propTypes = {
  profile: PropTypes.object,
  getProfile: PropTypes.func,
  stripe: PropTypes.object,
};

BuyCreditsContent.defaultProps = {
  profile: {},
  getProfile: () => {},
  stripe: {},
};

export default injectStripe(BuyCreditsContent);
