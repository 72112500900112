/* eslint-disable react/prop-types */
import React from 'react';
import {
  Modal, Alert, Button, Tooltip, Icon, Tag,
} from 'antd';
import Moment from 'moment';
import {
  PLANS, FEATURE_FLAG, FEATURES, getPlanPublicName,
} from './constants';
import EMAIL_REGEX from './emailRegex';
// eslint-disable-next-line import/prefer-default-export
export function parseSearch(search) {
  return (
    search
      .substr(1, Infinity) // Remove ?
      .split('&') // Split every fragment
      // Reduce
      .reduce((prev, fragment) => {
        const [key, value] = fragment.split('=');
        // eslint-disable-next-line no-param-reassign
        if (key) prev[key] = decodeURIComponent(value);
        return prev;
      }, {})
  );
}

export const getCountLabel = labelValue => (Math.abs(Number(labelValue)) >= 1.0e9
  ? `${(Math.abs(Number(labelValue)) / 1.0e9).toFixed(1)}B`
  : Math.abs(Number(labelValue)) >= 1.0e6
    ? `${(Math.abs(Number(labelValue)) / 1.0e6).toFixed(1)}M`
    : Math.abs(Number(labelValue)) >= 1.0e3
      ? `${(Math.abs(Number(labelValue)) / 1.0e3).toFixed(1)}K`
      : Number.isInteger(labelValue) ? labelValue : Math.abs(Number(labelValue)).toFixed(1));

export const getCountLabelInteger = labelValue => (Math.abs(Number(labelValue)) >= 1.0e9
  ? `${(Math.abs(Number(labelValue)) / 1.0e9).toFixed(0)}B`
  : Math.abs(Number(labelValue)) >= 1.0e6
    ? `${(Math.abs(Number(labelValue)) / 1.0e6).toFixed(0)}M`
    : Math.abs(Number(labelValue)) >= 1.0e3
      ? `${(Math.abs(Number(labelValue)) / 1.0e3).toFixed(0)}K`
      : Number.isInteger(labelValue) ? labelValue : Math.abs(Number(labelValue)).toFixed(0));


export function stringifySearch(obj) {
  return `?${Object.keys(obj).map(key => `${key}=${obj[key]}`).join('&')}`;
}

export const getTotalCreatorsFromStageObject = stage => Object.values(stage)
  .reduce((el, acc) => ({ value: ((el.value || 0) + acc) }), {}).value;

export const isPlan = (profile, plan) => {
  if (!profile || !plan) return false;
  return profile.plan === plan;
};

export const isFree = profile => isPlan(profile, PLANS.FREE);

export const isStarter = profile => isPlan(profile, PLANS.STARTER);

export const isAuthorized = (profile, feature, args = {}) => {
  if (!profile || !feature) {
    return true;
  }
  switch (feature) {
    case FEATURES.BULK_ADD:
      return profile.plan !== PLANS.FREE;
    case FEATURES.CREATE_CAMPAIGN:
      if (profile.plan.substr(0, 10) === 'ENTERPRISE') {
        return true;
      }
      switch (profile.plan) {
        default:
        case PLANS.FREE:
          return !((args.campaigns && args.campaigns.length > 0));
        case PLANS.STARTER:
        case PLANS.BUSINESS_V2:
          return !((args.campaigns && args.campaigns.length > 2));
        case PLANS.BUSINESS:
        case PLANS.GOLD:
          return !((args.campaigns && args.campaigns.length > 11));
        case PLANS.BUSINESS_V3:
        case PLANS.STARTUP:
        case PLANS.ENTERPRISE_MARIA:
        case PLANS.ENTERPRISE_BRENDAN:
        case PLANS.ENTERPRISE_LOOKWIDE:
        case PLANS.ENTERPRISE_MIMAMU:
        case PLANS.ENTERPRISE_BUTR_2:
        case PLANS.ENTERPRISE_BANDALABS:
        case PLANS.ENTERPRISE_LENARD:
        case PLANS.ENTERPRISE_RADHA:
        case PLANS.ENTERPRISE_SOCIALFUTURE:
        case PLANS.ENTERPRISE_VYTEN:
        case PLANS.PLATINUM:
          return true;
      }
    case FEATURES.TRACKING:
      switch (profile.plan) {
        default:
        case PLANS.FREE:
        case PLANS.STARTER:
        case PLANS.BUSINESS_V2:
        case PLANS.BUSINESS_V3:
        case PLANS.BUSINESS:
        case PLANS.STARTUP:
          return false;
        case PLANS.GOLD:
        case PLANS.ENTERPRISE_MARIA:
        case PLANS.ENTERPRISE_BRENDAN:
        case PLANS.ENTERPRISE_LOOKWIDE:
        case PLANS.ENTERPRISE_MIMAMU:
        case PLANS.ENTERPRISE_BUTR_2:
        case PLANS.ENTERPRISE_BANDALABS:
        case PLANS.ENTERPRISE_LENARD:
        case PLANS.ENTERPRISE_RADHA:
        case PLANS.ENTERPRISE_SOCIALFUTURE:
        case PLANS.ENTERPRISE_VYTEN:
        case PLANS.PLATINUM:
          return true;
      }
    case FEATURES.EXPORT:
      if (profile.plan.substr(0, 10) === 'ENTERPRISE') {
        return true;
      }
      switch (profile.plan) {
        default:
        case PLANS.FREE:
          return false;
        case PLANS.STARTER:
        case PLANS.BUSINESS_V2:
        case PLANS.BUSINESS_V3:
        case PLANS.BUSINESS:
        case PLANS.GOLD:
        case PLANS.STARTUP:
        case PLANS.ENTERPRISE_MARIA:
        case PLANS.ENTERPRISE_BRENDAN:
        case PLANS.ENTERPRISE_LOOKWIDE:
        case PLANS.ENTERPRISE_MIMAMU:
        case PLANS.ENTERPRISE_BUTR_2:
        case PLANS.ENTERPRISE_BANDALABS:
        case PLANS.ENTERPRISE_LENARD:
        case PLANS.ENTERPRISE_RADHA:
        case PLANS.ENTERPRISE_SOCIALFUTURE:
        case PLANS.ENTERPRISE_VYTEN:
        case PLANS.PLATINUM:
          return true;
      }
    case FEATURES.CAMPAIGN_DETAILS:
      if (profile.plan === PLANS.STARTER) {
        return false;
      }
      return true;
    case FEATURES.ADVANCED_SEARCH:
      if (profile.plan === PLANS.STARTER) {
        return false;
      }
      return true;
    case FEATURES.FULL_DATABASE:
      if (profile.plan === PLANS.FREE) {
        return false;
      }
      return true;
    case FEATURES.SEE_PHONE_NUMBER:
      if (profile.plan.substr(0, 10) === 'ENTERPRISE') {
        return true;
      }
      switch (profile.plan) {
        case PLANS.BUSINESS_V2:
        case PLANS.BUSINESS_V3:
        case PLANS.BUSINESS:
        case PLANS.GOLD:
        case PLANS.STARTUP:
        case PLANS.ENTERPRISE_MARIA:
        case PLANS.ENTERPRISE_BRENDAN:
        case PLANS.ENTERPRISE_LOOKWIDE:
        case PLANS.ENTERPRISE_MIMAMU:
        case PLANS.ENTERPRISE_BUTR_2:
        case PLANS.ENTERPRISE_BANDALABS:
        case PLANS.ENTERPRISE_LENARD:
        case PLANS.ENTERPRISE_RADHA:
        case PLANS.ENTERPRISE_SOCIALFUTURE:
        case PLANS.ENTERPRISE_VYTEN:
        case PLANS.PLATINUM:
          return true;
        default:
        case PLANS.FREE:
        case PLANS.STARTER:
          return false;
      }
    case FEATURES.SEE_EMAILS:
      switch (profile.plan) {
        case PLANS.FREE:
          return false;
        default:
        case PLANS.BUSINESS_V2:
        case PLANS.BUSINESS_V3:
        case PLANS.BUSINESS:
        case PLANS.GOLD:
        case PLANS.STARTUP:
        case PLANS.ENTERPRISE_MARIA:
        case PLANS.ENTERPRISE_BRENDAN:
        case PLANS.ENTERPRISE_LOOKWIDE:
        case PLANS.ENTERPRISE_MIMAMU:
        case PLANS.ENTERPRISE_BUTR_2:
        case PLANS.ENTERPRISE_BANDALABS:
        case PLANS.ENTERPRISE_LENARD:
        case PLANS.ENTERPRISE_RADHA:
        case PLANS.ENTERPRISE_SOCIALFUTURE:
        case PLANS.ENTERPRISE_VYTEN:
        case PLANS.PLATINUM:
          return true;
      }
    case FEATURES.FOLLOWED_BY:
      return FEATURE_FLAG[FEATURES.FOLLOWED_BY].includes(profile._id);
    case FEATURES.HELP_SEARCH:
      return false;
    default:
      return true;
  }
};

export const getUpgradeButton = ({
  text = 'Upgrade',
  isLink = false,
  icon = null,
}) => <a href="/#/settings?upgrade=true"><Button icon={icon} type={isLink ? 'link' : 'primary'}>{text}</Button></a>;

export const showUpgradeModal = ({ message, onOk }) => Modal.confirm({
  title: 'Upgrade',
  icon: 'info-circle',
  content: (
    <div>
      {message || 'This feature is available in Startup, Business or Gold plans.'}
      {' '}
     Contact us at
      {' '}
      <a href="mailto:contact@tokfluence.com">contact@tokfluence.com</a>
      {' '}
For any questions.
    </div>
  ),
  onOk: () => {
    onOk();
  },
  okText: 'Upgrade now',
  onCancel() {},
  cancelText: 'Cancel',
});
export const getAlertStarter = message => (
  <Alert
    type="info"
    showIcon
    message="Upgrade"
    description={(
      <div>
        {message || 'This feature is available in Startup, Business or Gold plans.'}
        {' '}
Contact us at
        {' '}
        <a href="mailto:contact@tokfluence.com">contact@tokfluence.com</a>
        {' '}
to upgrade.
      </div>
)}
  />
);

export const getStarterTooltip = message => (
  <Tooltip
    title={message || 'This feature is available in Business plan.'}
  >
    {' '}
    <span style={{ marginLeft: '8px' }}>
      <Icon style={{ color: '#1790ff' }} type="lock" />
    </span>
  </Tooltip>
);

export const getAlertFree = message => (
  <Alert
    type="info"
    showIcon
    message="Upgrade"
    description={(
      <div>
        {message || 'This feature is available in Startup, Business or Gold plans.'}
        {' '}
Contact us at
        {' '}
        <a href="mailto:contact@tokfluence.com">contact@tokfluence.com</a>
        {' '}
for any questions.
        <div style={{ textAlign: 'right' }}>
          {getUpgradeButton({ text: 'Upgrade' })}
        </div>
      </div>
)}
  />
);

export const STARTER_PLAN_SEARCHES = [
  'typegt',
  'typelt',
  'minEngagement',
  'influencerCountry',
  'sortOrder', 'sortType'];

export const filterSearchParamsWithPlan = (params, profile) => {
  if (!profile) {
    return params;
  }
  switch (profile.plan) {
    case PLANS.STARTER:
      return Object.keys(params).reduce((acc, el) => ({
        ...acc,
        [el]: (STARTER_PLAN_SEARCHES.indexOf(el) < 0) ? undefined : (params[el] || undefined),
      }), {});
    default:
    case PLANS.FREE:
    case PLANS.BUSINESS:
    case PLANS.BUSINESS_V2:
    case PLANS.GOLD:
      return params;
  }
};

export function getCookie(cname) {
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return undefined;
}

export const getKeywordsStringFromArray = array => `"${array.join('" OR "')}"`;

export const getTagsStringFromArray = array => array.map(a => [a]);

export const getPourcentageSaving = ({ n1, n2 }) => (100 - ((n2 / n1) * 100)).toFixed(0);

const USERNAME_REGEX = /^[a-zA-Z0-9._]+$/g;

export const isValidTikTokUsername = (username = '') => username.match(USERNAME_REGEX) && !username.match(/^[0-9]+$/g);
export const isValidEmail = (email = '') => email.match(EMAIL_REGEX);

export const groupByGeneric = (object, param) => object.reduce((
  acc, current,
) => ({
  ...acc,
  [current[param]]: {
    [param]: current[param],
    count: acc[current[param]] ? (acc[current[param]].count + 1) : 1,
    values: [
      ...(acc[current[param]] ? (acc[current[param]].values || []) : []),
      { ...current },
    ],
  },
}), {});

export const genetateERdata = (account) => {
  const countsFormatted = (account && account.countHistory) ? account.countHistory.map(c => ({ ...c, createdAt: (new Moment(c.createdAt).format('MM/DD/YYYY')) })) : null;
  const grouped = groupByGeneric(countsFormatted || [], 'createdAt');
  const consolidated = (Object.values(grouped || {})).reduce((
    acc, current,
  ) => {
    const likes = current.values.find(c => c.type === 'LIKES');
    const foll = current.values.find(c => c.type === 'FOLLOWER');
    const vids = current.values.find(c => c.type === 'VIDEOS');
    const val = likes && vids && foll && foll.value && likes.value && vids.value ? {
      createdAt: current.createdAt,
      er: (((likes.value / vids.value) / foll.value) * 100).toFixed(2),
    } : null;
    return val ? ([
      ...acc,
      val,
    ]) : acc;
  }, []);
  return consolidated;
};

export const genetateReachEvolutiondata = (account) => {
  const countsFormatted = (account && account.countHistory) ? account.countHistory.map(c => ({ ...c, createdAt: (new Moment(c.createdAt).format('MM/DD/YYYY')) })) : null;
  const grouped = groupByGeneric(countsFormatted || [], 'createdAt');
  const consolidated = (Object.values(grouped || {})).reduce((
    acc, current,
  ) => {
    const likes = current.values.find(c => c.type === 'LIKES');
    const foll = current.values.find(c => c.type === 'FOLLOWER');
    const vids = current.values.find(c => c.type === 'VIDEOS');

    const val = (likes && vids && foll && foll.value && likes.value && vids.value) ? {
      createdAt: current.createdAt,
      reach: foll.value * (((likes.value / vids.value) / foll.value) * 100) / 100,
    } : null;

    return val ? ([
      ...acc,
      val,
    ]) : acc;
  }, []);
  return consolidated;
};

export const isProfileBillingValid = profile => (
  profile.billingAddress
    && profile.billingAddress.name.length > 0
    && profile.billingAddress.street.length > 0
    && profile.billingAddress.postalCode.length > 0
    && profile.billingAddress.country.length > 0
    && profile.billingAddress.city.length > 0
);

export const isBannerDisplayed = () => false;
// if (!profile || !profile.stripeCustomer) {
//   return false;
// }
// const subscriptionCreatedPlusSeven = (new Date(profile.stripeCustomer.created * 1000));
// subscriptionCreatedPlusSeven.setDate(subscriptionCreatedPlusSeven.getDate() + 7);
// const isHiding = localStorage.getItem(CURRENT_BANNER_ID) === 'true';
// return subscriptionCreatedPlusSeven < new Date() && width > 800
// && !isHiding
// && profile && profile.plan !== PLANS.FREE && profile.plan !== PLANS.GOLD;

export const replaceAll = (string, search, replace) => string.split(search).join(replace);

export const getPlanTag = (plan) => {
  switch (plan) {
    case PLANS.STARTUP:
      return <Tag color="blue">{getPlanPublicName(plan)}</Tag>;
    case PLANS.STARTER:
    case PLANS.BUSINESS:
    case PLANS.BUSINESS_V2:
      return <Tag color="geekblue">{getPlanPublicName(plan)}</Tag>;
    case PLANS.GOLD:
      return <Tag color="orange">{getPlanPublicName(plan)}</Tag>;
    case PLANS.ENTERPRISE_MARIA:
    case PLANS.ENTERPRISE_BRENDAN:
    case PLANS.ENTERPRISE_LOOKWIDE:
    case PLANS.ENTERPRISE_MIMAMU:
    case PLANS.ENTERPRISE_BUTR_2:
    case PLANS.ENTERPRISE_VYTEN:
      return <Tag color="purple">{getPlanPublicName(plan)}</Tag>;
    default:
    case PLANS.FREE:
      return <Tag color="green">{getPlanPublicName(plan)}</Tag>;
  }
};
