import Axios from './requests';
import { API_URL } from './constants';
import { getSessionId } from './user';

export const search = ({
  type,
  country,
  page,
  limit,
  isTiktokVerified,
  instagramVerified,
  hasContactEmail,
  hasInstagramLinked,
  hasYoutubeLinked,
  instagramFollowerCount,
  tiktokEngagementRate,
  instagramMediaCount,
  tiktokVideoCount,
  profilePicTypes,
  postsPerWeek,
  sort,
  sortOrder,
  profileBiographiesQuery,
  instagramEngagementRate,
  isYoutubeVerified,
  youtubeSubscriberCount,
  tags,
  mentions,
  tiktokAverageViews,
  instagramPostsPerWeek,
}) => Axios.post(`${API_URL}/platform-users/search-influencers-v2`, {
  tiktokRegions: country,
  tiktokFollowerCount: type,
  skip: (limit || 20) * page,
  limit: limit || 20,
  isTiktokVerified,
  instagramVerified,
  hasContactEmail,
  hasInstagramLinked,
  hasYoutubeLinked,
  instagramFollowerCount,
  tiktokEngagementRate,
  instagramMediaCount,
  tiktokVideoCount,
  profilePicTypes,
  postsPerWeek,
  sort,
  sortOrder,
  profileBiographiesQuery,
  instagramEngagementRate,
  isYoutubeVerified,
  youtubeSubscriberCount,
  tags,
  mentions,
  tiktokAverageViews,
  instagramPostsPerWeek,
}, {
  headers: {
    sessionId: getSessionId(),
  },
});

export const countInfluencers = ({
  type,
  country,
  isTiktokVerified,
  instagramVerified,
  hasContactEmail,
  hasInstagramLinked,
  hasYoutubeLinked,
  instagramFollowerCount,
  tiktokEngagementRate,
  instagramMediaCount,
  tiktokVideoCount,
  profilePicTypes,
  postsPerWeek,
  profileBiographiesQuery,
  instagramEngagementRate,
  isYoutubeVerified,
  youtubeSubscriberCount,
  tags,
  mentions,
  tiktokAverageViews,
  instagramPostsPerWeek,
}) => Axios.post(`${API_URL}/platform-users/count-influencers-v2`, {
  tiktokRegions: country,
  tiktokFollowerCount: type,
  isTiktokVerified,
  hasContactEmail,
  hasInstagramLinked,
  hasYoutubeLinked,
  instagramFollowerCount,
  tiktokEngagementRate,
  instagramMediaCount,
  tiktokVideoCount,
  profilePicTypes,
  postsPerWeek,
  profileBiographiesQuery,
  instagramVerified,
  instagramEngagementRate,
  isYoutubeVerified,
  youtubeSubscriberCount,
  tags,
  mentions,
  tiktokAverageViews,
  instagramPostsPerWeek,
}, {
  headers: {
    sessionId: getSessionId(),
  },
});

export const getHashtagSuggestions = hashtag => Axios.get(`https://www.instagram.com/explore/tags/${hashtag}/?__a=1`);

export default search;
