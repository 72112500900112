const INFLUENCER_COUNTRIES = [
  { countryCode: 'US', count: '16104910', name: 'United States' },
  { countryCode: 'IN', count: '13155245', name: 'India' },
  { countryCode: 'GB', count: '3829499', name: 'United Kingdom' },
  { countryCode: 'KH', count: '3156591', name: 'Cambodia' },
  { countryCode: 'CN', count: '2219528', name: 'China' },
  { countryCode: 'FR', count: '1936741', name: 'France' },
  { countryCode: 'DE', count: '1809460', name: 'Germany' },
  { countryCode: 'CA', count: '1734051', name: 'Canada' },
  { countryCode: 'IT', count: '1721994', name: 'Italy' },
  { countryCode: 'BR', count: '1427894', name: 'Brazil' },
  { countryCode: 'AU', count: '1339793', name: 'Australia' },
  { countryCode: 'MX', count: '1313127', name: 'Mexico' },
  { countryCode: 'PK', count: '1181730', name: 'Pakistan' },
  { countryCode: 'ID', count: '1144063', name: 'Indonesia' },
  { countryCode: 'PH', count: '1020018', name: 'Philippines' },
  { countryCode: 'RU', count: '942566', name: 'Russia' },
  { countryCode: 'TH', count: '881943', name: 'Thailand' },
  { countryCode: 'ES', count: '808673', name: 'Spain' },
  { countryCode: 'EG', count: '805141', name: 'Egypt' },
  { countryCode: 'SA', count: '719091', name: 'Saudi Arabia' },
  { countryCode: 'TR', count: '679088', name: 'Turkey' },
  { countryCode: 'PL', count: '669063', name: 'Poland' },
  { countryCode: 'VN', count: '651774', name: 'Vietnam' },
  { countryCode: 'NL', count: '581561', name: 'Netherlands' },
  { countryCode: 'IQ', count: '551805', name: 'Iraq' },
  { countryCode: 'AR', count: '502291', name: 'Argentina' },
  { countryCode: 'MY', count: '458743', name: 'Malaysia' },
  { countryCode: 'AE', count: '451822', name: 'United Arab Emirates' },
  { countryCode: 'BE', count: '443763', name: 'Belgium' },
  { countryCode: 'RO', count: '421306', name: 'Romania' },
  { countryCode: 'SE', count: '406350', name: 'Sweden' },
  { countryCode: 'CO', count: '397419', name: 'Colombia' },
  { countryCode: 'CL', count: '369082', name: 'Chile' },
  { countryCode: 'JP', count: '356349', name: 'Japan' },
  { countryCode: 'DZ', count: '335512', name: 'Algeria' },
  { countryCode: 'IL', count: '332139', name: 'Israel' },
  { countryCode: 'MA', count: '316879', name: 'Morocco' },
  { countryCode: 'ZA', count: '252840', name: 'South Africa' },
  { countryCode: 'IE', count: '249635', name: 'Ireland' },
  { countryCode: 'NZ', count: '237101', name: 'New Zealand' },
  { countryCode: 'PE', count: '234049', name: 'Peru' },
  { countryCode: 'AT', count: '225027', name: 'Austria' },
  { countryCode: 'NO', count: '221970', name: 'Norway' },
  { countryCode: 'CH', count: '218053', name: 'Switzerland' },
  { countryCode: 'MM', count: '210276', name: 'Myanmar' },
  { countryCode: 'NP', count: '201295', name: 'Nepal' },
  { countryCode: 'UA', count: '187141', name: 'Ukraine' },
  { countryCode: 'EC', count: '185283', name: 'Ecuador' },
  { countryCode: 'DK', count: '171249', name: 'Denmark' },
  { countryCode: 'CZ', count: '168609', name: 'Czech Republic' },
  { countryCode: 'PT', count: '167252', name: 'Portugal' },
  { countryCode: 'HU', count: '164379', name: 'Hungary' },
  { countryCode: 'NG', count: '163684', name: 'Nigeria' },
  { countryCode: 'KW', count: '159677', name: 'Kuwait' },
  { countryCode: 'JO', count: '158341', name: 'Jordan' },
  { countryCode: 'TN', count: '156675', name: 'Tunisia' },
  { countryCode: 'GR', count: '156158', name: 'Greece' },
  { countryCode: 'BD', count: '154746', name: undefined },
  { countryCode: 'KR', count: '143024', name: 'South Korea' },
  { countryCode: 'LB', count: '139099', name: 'Lebanon' },
  { countryCode: 'BG', count: '134424', name: 'Bulgaria' },
  { countryCode: 'SG', count: '114382', name: 'Singapore' },
  { countryCode: 'FI', count: '114370', name: 'Finland' },
  { countryCode: 'QA', count: '107767', name: 'Qatar' },
  { countryCode: 'KZ', count: '105203', name: 'Kazakhstan' },
  { countryCode: 'DO', count: '102844', name: 'Dominican Republic' },
  { countryCode: 'TW', count: '85108', name: 'Taiwan' },
  { countryCode: 'LT', count: '77556', name: 'Lithuania' },
  { countryCode: 'OM', count: '73891', name: 'Oman' },
  { countryCode: 'SK', count: '70760', name: 'Slovakia' },
  { countryCode: 'LK', count: '69334', name: undefined },
  { countryCode: 'LY', count: '64224', name: undefined },
  { countryCode: 'RS', count: '63433', name: 'Serbia' },
  { countryCode: 'HR', count: '62268', name: 'Croatia' },
  { countryCode: 'VE', count: '61971', name: 'Venezuela' },
  { countryCode: 'LA', count: '61352', name: undefined },
  { countryCode: 'CR', count: '56127', name: 'Costa Rica' },
  { countryCode: 'UY', count: '54624', name: 'Uruguay' },
  { countryCode: 'GE', count: '54369', name: 'Georgia' },
  { countryCode: 'BO', count: '50656', name: 'Bolivia' },
  { countryCode: 'GT', count: '50465', name: 'Guatemala' },
  { countryCode: 'SN', count: '50456', name: 'Senegal' },
  { countryCode: 'LV', count: '49297', name: 'Latvia' },
  { countryCode: 'AZ', count: '49187', name: 'Azerbaijan' },
  { countryCode: 'BY', count: '47027', name: 'Belarus' },
  { countryCode: 'AL', count: '46143', name: 'Albania' },
  { countryCode: 'BH', count: '44432', name: 'Bahrain' },
  { countryCode: 'HN', count: '41876', name: 'Honduras' },
  { countryCode: 'KE', count: '40665', name: 'Kenya' },
  { countryCode: 'SI', count: '37952', name: 'Slovenia' },
  { countryCode: 'PS', count: '37952', name: 'Palestine' },
  // { countryCode: 'SO', count: '37190', name: undefined },
  // { countryCode: 'JM', count: '37007', name: undefined },
  // { countryCode: 'PR', count: '36787', name: 'Puerto Rico' },
  // { countryCode: 'PA', count: '36132', name: 'Panama' },
  // { countryCode: 'HK', count: '35959', name: undefined },
  // { countryCode: 'SV', count: '32151', name: 'El Salvador' },
  // { countryCode: 'MK', count: '30267', name: 'Macedonia' },
  // { countryCode: 'EE', count: '29395', name: 'Estonia' },
  // { countryCode: 'PY', count: '29187', name: 'Paraguay' },
  // { countryCode: 'MD', count: '28868', name: 'Moldova' },
  // { countryCode: 'TT', count: '27703', name: 'Trinidad and Tobago' },
  // { countryCode: 'AF', count: '27404', name: undefined },
  // { countryCode: 'MU', count: '25696', name: 'Mauritius' },
  // { countryCode: 'SY', count: '25150', name: undefined },
  // { countryCode: 'BA', count: '25077', name: undefined },
  // { countryCode: 'SD', count: '25043', name: undefined },
  // { countryCode: 'CY', count: '24190', name: 'Cyprus' },
  // { countryCode: 'YE', count: '23497', name: undefined },
  // { countryCode: 'MN', count: '21400', name: 'Mongolia' },
  // { countryCode: 'AM', count: '21010', name: 'Armenia' },
  // { countryCode: 'UZ', count: '19201', name: undefined },
  // { countryCode: 'LU', count: '19149', name: 'Luxembourg' },
  // { countryCode: 'RE', count: '18861', name: undefined },
  // { countryCode: 'NI', count: '17415', name: 'Nicaragua' },
  // { countryCode: 'BS', count: '15753', name: 'Bahamas' },
  // { countryCode: 'IR', count: '15631', name: undefined },
  // { countryCode: 'IS', count: '15109', name: 'Iceland' },
  // { countryCode: 'KG', count: '14650', name: undefined },
  // { countryCode: 'GH', count: '14609', name: 'Ghana' },
  // { countryCode: 'GP', count: '13823', name: undefined },
  // { countryCode: 'MT', count: '12540', name: 'Malta' },
  // { countryCode: 'FJ', count: '11800', name: 'Fiji' },
  // { countryCode: 'MC', count: '11672', name: undefined },
  // { countryCode: 'MV', count: '11418', name: 'Maldives' },
  // { countryCode: 'PS', count: '9519', name: undefined },
  // { countryCode: 'CI', count: '9288', name: 'Ivory Coast' },
  // { countryCode: 'GY', count: '8662', name: undefined },
  // { countryCode: 'PF', count: '8446', name: undefined },
  // { countryCode: 'NC', count: '8437', name: undefined },
  // { countryCode: 'BN', count: '8082', name: undefined },
  // { countryCode: 'TZ', count: '8006', name: 'Tanzania' },
  // { countryCode: 'ME', count: '7438', name: undefined },
  // { countryCode: 'AO', count: '7378', name: undefined },
  // { countryCode: 'NA', count: '7295', name: 'Namibia' },
  // { countryCode: 'UG', count: '7182', name: 'Uganda' },
  // { countryCode: 'BZ', count: '7113', name: undefined },
  // { countryCode: 'SR', count: '6233', name: 'Suriname' },
  // { countryCode: 'ET', count: '5966', name: undefined },
  // { countryCode: 'ZM', count: '5765', name: 'Zambia' },
  // { countryCode: 'MR', count: '5541', name: undefined },
  // { countryCode: 'CM', count: '5355', name: 'Cameroon' },
  // { countryCode: 'AS', count: '5017', name: undefined },
  // { countryCode: 'AW', count: '4895', name: undefined },
  // { countryCode: 'ZW', count: '4579', name: 'Zimbabwe' },
  // { countryCode: 'BT', count: '4559', name: undefined },
  // { countryCode: 'GU', count: '4520', name: undefined },
  // { countryCode: 'BB', count: '4218', name: 'Barbados' },
  // { countryCode: 'GA', count: '3759', name: undefined },
  // { countryCode: 'MZ', count: '3687', name: 'Mozambique' },
  // { countryCode: 'BW', count: '3434', name: 'Botswana' },
  // { countryCode: 'CD', count: '3419', name: undefined },
  // { countryCode: 'EN', count: '3375', name: undefined },
  // { countryCode: 'XK', count: '3189', name: 'Kosovo' },
  // { countryCode: 'MO', count: '3177', name: undefined },
  // { countryCode: 'MG', count: '3062', name: 'Madagascar' },
  // { countryCode: 'AI', count: '2822', name: undefined },
  // { countryCode: 'HT', count: '2797', name: undefined },
  // { countryCode: 'TL', count: '2376', name: undefined },
  // { countryCode: 'GI', count: '2353', name: undefined },
  // { countryCode: 'ML', count: '2351', name: undefined },
  // { countryCode: 'GL', count: '2274', name: undefined },
  // { countryCode: 'GM', count: '2212', name: undefined },
  // { countryCode: 'FO', count: '2075', name: undefined },
  // { countryCode: 'MQ', count: '2050', name: undefined },
  // { countryCode: 'AD', count: '2021', name: undefined },
  // { countryCode: 'CG', count: '1997', name: undefined },
  // { countryCode: 'TJ', count: '1897', name: undefined },
  // { countryCode: 'KY', count: '1892', name: undefined },
  // { countryCode: 'GN', count: '1862', name: undefined },
  // { countryCode: 'WS', count: '1717', name: undefined },
  // { countryCode: 'JE', count: '1638', name: 'Jersey' },
  // { countryCode: 'DJ', count: '1596', name: undefined },
  // { countryCode: 'AG', count: '1569', name: 'Angola' },
  // { countryCode: 'BM', count: '1562', name: undefined },
  // { countryCode: 'BJ', count: '1500', name: undefined },
  // { countryCode: 'RW', count: '1458', name: 'Rwanda' },
  // { countryCode: 'LC', count: '1429', name: 'Saint Lucia' },
  // { countryCode: 'PG', count: '1423', name: 'Papua New Guinea' },
  // { countryCode: 'GF', count: '1412', name: undefined },
  // { countryCode: 'CW', count: '1396', name: 'Curacao' },
  // { countryCode: 'CU', count: '1388', name: undefined },
  // { countryCode: 'SC', count: '1309', name: 'Seychelles' },
  // { countryCode: 'TG', count: '1293', name: undefined },
  // { countryCode: 'IM', count: '1258', name: 'Isle of Man' },
  // { countryCode: 'VI', count: '1245', name: undefined },
  // { countryCode: 'VC', count: '1220', name: undefined },
  // { countryCode: 'TO', count: '1216', name: undefined },
  // { countryCode: 'AN', count: '1187', name: undefined },
  // { countryCode: 'TM', count: '1161', name: undefined },
  // { countryCode: 'GD', count: '1116', name: undefined },
  // { countryCode: 'VG', count: '1099', name: undefined },
  // { countryCode: 'SL', count: '1071', name: undefined },
  // { countryCode: 'GG', count: '1070', name: undefined },
  // { countryCode: 'CV', count: '1058', name: undefined },
  // { countryCode: 'TC', count: '1032', name: undefined },
  // { countryCode: 'MW', count: '1003', name: undefined },
  // { countryCode: 'BF', count: '997', name: undefined },
  // { countryCode: 'KN', count: '985', name: undefined },
  // { countryCode: 'LR', count: '965', name: undefined },
  // { countryCode: 'UK', count: '893', name: undefined },
  // { countryCode: 'NE', count: '888', name: undefined },
  // { countryCode: 'AX', count: '886', name: undefined },
  // { countryCode: 'SZ', count: '869', name: undefined },
  // { countryCode: 'MP', count: '835', name: undefined },
  // { countryCode: 'DM', count: '764', name: undefined },
  // { countryCode: 'PW', count: '742', name: undefined },
  // { countryCode: 'SM', count: '732', name: undefined },
  // { countryCode: 'LI', count: '600', name: undefined },
  // { countryCode: 'VU', count: '574', name: undefined },
  // { countryCode: 'SX', count: '573', name: undefined },
  // { countryCode: 'TD', count: '533', name: undefined },
  // { countryCode: 'FM', count: '532', name: undefined },
  // { countryCode: 'YT', count: '489', name: undefined },
  // { countryCode: 'GQ', count: '482', name: undefined },
  // { countryCode: 'MH', count: '461', name: undefined },
  // { countryCode: 'BI', count: '452', name: undefined },
  // { countryCode: 'SB', count: '424', name: undefined },
  // { countryCode: 'CK', count: '395', name: undefined },
  // { countryCode: 'MF', count: '372', name: undefined },
  // { countryCode: 'LS', count: '356', name: undefined },
  // { countryCode: 'AQ', count: '317', name: undefined },
  // { countryCode: 'UM', count: '299', name: undefined },
  // { countryCode: 'PM', count: '283', name: undefined },
  // { countryCode: 'BQ', count: '270', name: undefined },
  // { countryCode: 'KM', count: '238', name: undefined },
  // { countryCode: 'SS', count: '190', name: undefined },
  // { countryCode: 'WF', count: '154', name: undefined },
  // { countryCode: 'BL', count: '142', name: undefined },
  // { countryCode: 'CF', count: '133', name: undefined },
  // { countryCode: 'GW', count: '123', name: undefined },
  // { countryCode: 'ER', count: '106', name: undefined },
  // { countryCode: 'CX', count: '101', name: undefined },
  // { countryCode: 'MS', count: '92', name: undefined },
  // { countryCode: 'ZZ', count: '91', name: undefined },
  // { countryCode: 'ST', count: '90', name: undefined },
  // { countryCode: 'IC', count: '88', name: undefined },
  // { countryCode: 'IO', count: '69', name: undefined },
  // { countryCode: 'FK', count: '66', name: undefined },
  // { countryCode: 'KI', count: '51', name: undefined },
  // { countryCode: 'ZG', count: '50', name: undefined },
  // { countryCode: 'NU', count: '35', name: undefined },
  // { countryCode: 'VA', count: '31', name: undefined },
  // { countryCode: 'TV', count: '30', name: undefined },
  // { countryCode: 'TK', count: '24', name: undefined },
  // { countryCode: 'EH', count: '21', name: undefined },
  // { countryCode: 'HM', count: '20', name: undefined },
  // { countryCode: 'SH', count: '19', name: undefined },
  // { countryCode: 'KP', count: '19', name: undefined },
  // { countryCode: 'AC', count: '18', name: undefined },
  // { countryCode: 'NR', count: '16', name: undefined },
  // { countryCode: 'NF', count: '16', name: undefined },
  // { countryCode: 'BV', count: '15', name: undefined },
  // { countryCode: 'XX', count: '14', name: undefined },
  // { countryCode: 'LG', count: '13', name: undefined },
  // { countryCode: 'EA', count: '12', name: undefined },
  // { countryCode: 'HE', count: '12', name: undefined },
  // { countryCode: 'TF', count: '11', name: undefined },
  // { countryCode: 'PN', count: '9', name: undefined },
  // { countryCode: 'SJ', count: '9', name: undefined },
  // { countryCode: 'FA', count: '8', name: undefined },
  // { countryCode: 'GS', count: '8', name: undefined },
  // { countryCode: 'IW', count: '7', name: undefined },
  // { countryCode: 'CC', count: '7', name: undefined },
  // { countryCode: 'KA', count: '6', name: undefined },
  // { countryCode: 'DG', count: '4', name: undefined },
  // { countryCode: 'SP', count: '4', name: undefined },
  // { countryCode: 'AA', count: '2', name: undefined },
  // { countryCode: 'FH', count: '2', name: undefined },
  // { countryCode: 'CS', count: '2', name: undefined },
  // { countryCode: 'CJ', count: '2', name: undefined },
  // { countryCode: 'DB', count: '1', name: undefined },
  // { countryCode: 'GZ', count: '1', name: undefined },
  // { countryCode: 'RF', count: '1', name: undefined },
  // { countryCode: 'EU', count: '1', name: undefined },
  // { countryCode: 'TP', count: '1', name: undefined },
  // { countryCode: 'JT', count: '1', name: undefined },
  // { countryCode: 'TU', count: '1', name: undefined },
  // { countryCode: 'JA', count: '1', name: undefined },
  // { countryCode: 'WD', count: '1', name: undefined },
  // { countryCode: 'LO', count: '1', name: undefined },

];

export default INFLUENCER_COUNTRIES;
