import React from 'react';
import PropTypes from 'prop-types';

import '../styles/numberCountBadge.less';

const NumberCountBadge = ({ icon, number, color }) => (
  <div className="Custom_badge_wrapper" style={{ background: color }}>
    <div className="Custom_badge_inner">
      <div className="Custom_badge_icon_wrapper" style={{ color }}>
        {icon}
      </div>
      <div className="Custom_badge_content title bold">
        {number}
      </div>

    </div>
  </div>
);

NumberCountBadge.propTypes = {
  icon: PropTypes.object,
  number: PropTypes.string,
  color: PropTypes.string,
};

NumberCountBadge.defaultProps = {
  icon: {},
  number: '',
  color: '',
};

export default NumberCountBadge;
