/* eslint-disable react/jsx-closing-tag-location */
import React from 'react';
import PropTypes from 'prop-types';
import { Elements, StripeProvider } from 'react-stripe-elements';
import BuyCreditsContent from '../../components/buyCreditsContent';
import { getStripeToken } from '../../services/constants';


const BuyCredits = ({ profile, getProfile }) => (
  <StripeProvider apiKey={getStripeToken()}>
    <div>
      <Elements>
        <BuyCreditsContent profile={profile} getProfile={getProfile} />
      </Elements>
    </div>
  </StripeProvider>
);

BuyCredits.propTypes = {
  profile: PropTypes.object,
  getProfile: PropTypes.func,
};

BuyCredits.defaultProps = {
  profile: {},
  getProfile: () => {},
};

export default BuyCredits;
